.pasmat-tile-size {
    width: 100px;
    height: 100px;
}

.pasmat-borderBottom {
    margin-bottom: 20px;
    background-color: #001e82;
    color: white;
    padding: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.survey-info {
    border: 1px solid lightgray !important;
    border-radius: 5px;
    padding: 10px;
  }

g.pointtext {
    display: none;
}