.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.metro .fa {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-header {
  padding-top: 10px;
  padding-bottom: 20px;
}

.nav-tabs .nav-item {
  cursor: pointer;
}

.img-hor-vert {
  -moz-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.body {
  background-image: url('../telstraAir2.jpg');
  min-height: 100vh;
  min-width: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.container .white {
  background-color: #fffffff2;
  -moz-box-shadow: 0 0 10px #ffffffd6;
  -webkit-box-shadow: 0 0 10px#ffffffd6;
  box-shadow: 0 0 10px #ffffffd6;
  border-radius: 10px;
  padding: 30px;
}
.container .whiteTable {
  background-color: #fffffff2;
  -moz-box-shadow: 0 0 10px #ffffffd6;
  -webkit-box-shadow: 0 0 10px#ffffffd6;
  box-shadow: 0 0 10px #ffffffd6;
  border-radius: 10px;
  padding: 20px;
}
.container .cjvClass {
  background-color: #fffffff2;
  -moz-box-shadow: 0 0 10px #ffffffd6;
  -webkit-box-shadow: 0 0 10px#ffffffd6;
  box-shadow: 0 0 10px #ffffffd6;
  border-radius: 10px;
  padding-top: 20px;
}
.container .cjvLegend {
  background-color: #F3F4F5;
  -moz-box-shadow: 0 0 10px #ffffffd6;
  -webkit-box-shadow: 0 0 10px#ffffffd6;
  box-shadow: 0 0 10px #ffffffd6;
  padding-top: 10px;
  border: outset;


}
.hoverClass:hover{
  background-color: rgb(108, 158, 119);
  cursor: pointer;

}
.hoverClass1:hover{
  cursor: pointer;

}
.movingtext {
  animation-duration: 18s;
  animation-name: slideInFromRight;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  overflow: hidden;
  display: flexbox;
}

@keyframes slideInFromRight {
  from {
    margin-left: 100%;
    width: 120%;
    overflow: hidden;
  }

  to {
    margin-left: -50%;
    width: 100%;
  }
}
.container .create {
  background-color: #fffffff2;
  -moz-box-shadow: 0 0 10px #ffffffd6;
  -webkit-box-shadow: 0 0 10px#ffffffd6;
  box-shadow: 0 0 10px #ffffffd6;
  border-radius: 10px;
  padding: 30px;
}
.container .black {
  padding: 20px;
  border-radius: 10px;
  color: white;
  background-color: #1d1d1db0;
  -moz-box-shadow: 0 0 10px #1d1d1db0;
  -webkit-box-shadow: 0 0 10px#1d1d1db0;
  box-shadow: 0 0 10px #1d1d1db0;
}

.pagination {
  justify-content: center;
}

.pagination .page-item {
  margin: 0 !important;
  border: none !important;
}

.page-item.disabled .page-link {
  color: #6c757d !important;
  pointer-events: none !important;
  cursor: auto !important;
  background-color: #fff !important;
  border-color: #dee2e6 !important;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff !important;
  background-color: #fff !important;
  border: 1px solid #dee2e6 !important;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3 !important;
  text-decoration: none !important;
  background-color: #e9ecef !important;
  border-color: #dee2e6 !important;
}

dl,
ol,
ul {
  margin: 0 !important;
}

.list-group-item-heading {
  display: flex;
  justify-content: space-between;
}

.list-group-item.green.active {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}

.list-group-item.turquoise.active {
  border-color: #01a5a3;
}

.list-group {
  padding-bottom: 10px;
}

.list-group-item.red.active {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.box-gallery {
  z-index: -99999 !important;
}

.box-gallery .box-img {
  background-color: #fff !important;
}

.box-gallery .box-panel {
  position: fixed !important;
}

.vertical-center {
  min-height: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
}

.table th {
  border-top: none !important;
}

.td-icon.icon,
.td-icon-xs.icon,
.td-icon-sm.icon,
.td-icon-md.icon,
.td-icon-lg.icon,
.td-icon-xl.icon {
  color: white !important;
}

.swal2-icon {
  font-size: unset !important;
}

.nav-link.icon-link {
  cursor: pointer;
}


.lightgreen {
  background-color:#f0f3f7;
  padding: 5px;

}
.circle{
  text-align: center;
  vertical-align: middle;
  top:10%;
  left:30%;
  font-size: 50px;
  border-radius: 50%;
  width: 90px;
  height: 90px;
}