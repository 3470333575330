.chart{
    margin: 2% 1% 1%;
    height: 100%;
    background-color: #fff;
    font-family: "Open Sans","Segoe UI",Tahoma,sans-serif;
    font-size: 14px;
    border: 1px solid #e6e6e6 !important;
    border-radius: 10px;
}

.mb-n1 {
    margin-top: -0.4rem !important;
  }

.filter{
    margin-right: 10px;
}