
  
 #usageBilling {
    text-align: center;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    border: 3px solid #ddd;
    width: 100%;
  }
  
  #usageBilling td, #usageBilling th {
    border: 1px solid #ddd;
    
  }
  
  #usageBilling tr:nth-child(even){background-color: #f2f2f2;}
  
  #usageBilling tr:hover {background-color: #ddd;}
  
  #usageBilling th {
    
    padding-bottom: 12px;
    text-align: center;
    background-color: #4CAF50;
    color: white;
  }

.tableRow {
   
    padding-bottom: 12px;
    text-align: center;
    background-color: #60a917;
    color: black;
    border: 1px solid black !important;

}  

.ssp-usageBilling {
    border-bottom: 2px solid black !important;
  }

.ssp-issuedKeys {
    background-color: #ffffffff;
    box-shadow: 0 0 10px #ffffffd6;
    border-radius: 10px;
    
}

.ssp-usagebillingTH {
   border-left: 2px solid black !important;
}

.ssp-borderBotton {
  margin-bottom: 20px;
}

.ssp-programStreamTeamFilter {
  
  
  
  background-color: lightblue;
}

.ssp-columnBorder {
  border-color: black;
  
  border-radius: 10px;
  border-width: 2px;
  background-color: #ff00002e;
}
.card1 {
  border-color: black;
  
  border-radius: 10px;
  border-width: 2px;
  background-color: #1663662e;
}
.card2 {
  border-color: black;
  
  border-radius: 10px;
  border-width: 2px;
  background-color: #282e682e;
}
.card3 {
  border-color: black;
  
  border-radius: 10px;
  border-width: 2px;
  background-color: #205f2f2e;
}

.nav-tabs {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  /* border-bottom-color: rgb(40, 74, 107); */
  background-color: #d8dde224;
}
