a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  overflow-x: hidden;
  /* background-color: #fff */
  font-size: 1rem;
  line-height: 1.5;
  font-style: normal;
  font-weight: 400;
  color: #212121;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

base,
basefont,
datalist,
head,
meta,
noembed,
param,
script,
style,
template,
title {
  display: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: sans-serif;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

[hidden] {
  display: none !important;
}

template {
  display: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

[type="submit"]::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

[type="submit"],
[type="reset"],
button,
html [type="button"] {
  -webkit-appearance: button;
}

a,
area,
button,
input,
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

svg:not(:root) {
  overflow: hidden;
}

img {
  vertical-align: middle;
  border-style: none;
}

[hidden] {
  display: none;
}

.container,
.container-fluid,
.container2,
aside,
footer,
header,
section {
  display: block;
  position: relative;
  margin: 0 auto;
}

.container-fluid::after,
.container2::after,
.container::after,
aside::after,
footer::after,
header::after,
section::after {
  display: block;
  clear: both;
  content: "";
}

.container,
.container2 {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}

.container-fluid {
  width: 100%;
  max-width: none;
  padding-right: 12px;
  padding-left: 12px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }

  .container2 {
    max-width: 90%;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container2 {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container2 {
    max-width: 1140px;
  }
}

@media (min-width: 1452px) {
  .container,
  .container2 {
    max-width: 1360px;
  }
}

.container-fs {
  max-width: 0 !important;
}

.container-sm {
  max-width: 576px !important;
}

.container-md {
  max-width: 768px !important;
}

.container-lg {
  max-width: 992px !important;
}

.container-xl {
  max-width: 1200px !important;
}

.container-xxl {
  max-width: 1452px !important;
}

.button.primary,
.command-button.primary,
.image-button.primary,
.info-button.primary,
.input .button.primary,
.notify.primary,
.select .button.primary,
.shortcut.primary,
.spinner .button.primary,
.split-button .split.primary,
.tag-input .button.primary,
.tally.primary,
.toast.primary,
.tool-button.primary,
code.primary,
tbody td.primary,
tbody tr.primary {
  outline-color: #75b5fd;
  background-color: #0366d6;
  color: #fff;
}

.button.primary.outline,
.command-button.primary.outline,
.image-button.primary.outline,
.info-button.primary.outline,
.input .button.primary.outline,
.notify.primary.outline,
.select .button.primary.outline,
.shortcut.primary.outline,
.spinner .button.primary.outline,
.split-button .split.primary.outline,
.tag-input .button.primary.outline,
.tally.primary.outline,
.toast.primary.outline,
.tool-button.primary.outline,
code.primary.outline,
tbody td.primary.outline,
tbody tr.primary.outline {
  color: #0366d6;
  border-color: #0366d6;
}

.button.primary.outline.dropdown-toggle::before,
.command-button.primary.outline.dropdown-toggle::before,
.image-button.primary.outline.dropdown-toggle::before,
.info-button.primary.outline.dropdown-toggle::before,
.input .button.primary.outline.dropdown-toggle::before,
.notify.primary.outline.dropdown-toggle::before,
.select .button.primary.outline.dropdown-toggle::before,
.shortcut.primary.outline.dropdown-toggle::before,
.spinner .button.primary.outline.dropdown-toggle::before,
.split-button .split.primary.outline.dropdown-toggle::before,
.tag-input .button.primary.outline.dropdown-toggle::before,
.tally.primary.outline.dropdown-toggle::before,
.toast.primary.outline.dropdown-toggle::before,
.tool-button.primary.outline.dropdown-toggle::before,
code.primary.outline.dropdown-toggle::before,
tbody td.primary.outline.dropdown-toggle::before,
tbody tr.primary.outline.dropdown-toggle::before {
  border-color: #0366d6;
}

.button.primary.outline:hover,
.command-button.primary.outline:hover,
.image-button.primary.outline:hover,
.info-button.primary.outline:hover,
.input .button.primary.outline:hover,
.notify.primary.outline:hover,
.select .button.primary.outline:hover,
.shortcut.primary.outline:hover,
.spinner .button.primary.outline:hover,
.split-button .split.primary.outline:hover,
.tag-input .button.primary.outline:hover,
.tally.primary.outline:hover,
.toast.primary.outline:hover,
.tool-button.primary.outline:hover,
code.primary.outline:hover,
tbody td.primary.outline:hover,
tbody tr.primary.outline:hover {
  color: #fff;
}

.button.primary.dropdown-toggle::before,
.command-button.primary.dropdown-toggle::before,
.image-button.primary.dropdown-toggle::before,
.info-button.primary.dropdown-toggle::before,
.input .button.primary.dropdown-toggle::before,
.notify.primary.dropdown-toggle::before,
.select .button.primary.dropdown-toggle::before,
.shortcut.primary.dropdown-toggle::before,
.spinner .button.primary.dropdown-toggle::before,
.split-button .split.primary.dropdown-toggle::before,
.tag-input .button.primary.dropdown-toggle::before,
.tally.primary.dropdown-toggle::before,
.toast.primary.dropdown-toggle::before,
.tool-button.primary.dropdown-toggle::before,
code.primary.dropdown-toggle::before,
tbody td.primary.dropdown-toggle::before,
tbody tr.primary.dropdown-toggle::before {
  border-color: #fff;
}

.button.primary:hover,
.command-button.primary:hover,
.image-button.primary:hover,
.info-button.primary:hover,
.input .button.primary:hover,
.notify.primary:hover,
.select .button.primary:hover,
.shortcut.primary:hover,
.spinner .button.primary:hover,
.split-button .split.primary:hover,
.tag-input .button.primary:hover,
.tally.primary:hover,
.toast.primary:hover,
.tool-button.primary:hover,
code.primary:hover,
tbody td.primary:hover,
tbody tr.primary:hover {
  color: #fff;
  background-color: #024ea4;
  border-color: #023671;
}

.button.primary:hover.dropdown-toggle::before,
.command-button.primary:hover.dropdown-toggle::before,
.image-button.primary:hover.dropdown-toggle::before,
.info-button.primary:hover.dropdown-toggle::before,
.input .button.primary:hover.dropdown-toggle::before,
.notify.primary:hover.dropdown-toggle::before,
.select .button.primary:hover.dropdown-toggle::before,
.shortcut.primary:hover.dropdown-toggle::before,
.spinner .button.primary:hover.dropdown-toggle::before,
.split-button .split.primary:hover.dropdown-toggle::before,
.tag-input .button.primary:hover.dropdown-toggle::before,
.tally.primary:hover.dropdown-toggle::before,
.toast.primary:hover.dropdown-toggle::before,
.tool-button.primary:hover.dropdown-toggle::before,
code.primary:hover.dropdown-toggle::before,
tbody td.primary:hover.dropdown-toggle::before,
tbody tr.primary:hover.dropdown-toggle::before {
  border-color: #fff;
}

.button.primary.same-color:hover,
.command-button.primary.same-color:hover,
.image-button.primary.same-color:hover,
.info-button.primary.same-color:hover,
.input .button.primary.same-color:hover,
.notify.primary.same-color:hover,
.select .button.primary.same-color:hover,
.shortcut.primary.same-color:hover,
.spinner .button.primary.same-color:hover,
.split-button .split.primary.same-color:hover,
.tag-input .button.primary.same-color:hover,
.tally.primary.same-color:hover,
.toast.primary.same-color:hover,
.tool-button.primary.same-color:hover,
code.primary.same-color:hover,
tbody td.primary.same-color:hover,
tbody tr.primary.same-color:hover {
  background-color: #0366d6;
  border-color: #0366d6;
}

.button.primary.focus,
.button.primary:active,
.button.primary:focus,
.command-button.primary.focus,
.command-button.primary:active,
.command-button.primary:focus,
.image-button.primary.focus,
.image-button.primary:active,
.image-button.primary:focus,
.info-button.primary.focus,
.info-button.primary:active,
.info-button.primary:focus,
.input .button.primary.focus,
.input .button.primary:active,
.input .button.primary:focus,
.notify.primary.focus,
.notify.primary:active,
.notify.primary:focus,
.select .button.primary.focus,
.select .button.primary:active,
.select .button.primary:focus,
.shortcut.primary.focus,
.shortcut.primary:active,
.shortcut.primary:focus,
.spinner .button.primary.focus,
.spinner .button.primary:active,
.spinner .button.primary:focus,
.split-button .split.primary.focus,
.split-button .split.primary:active,
.split-button .split.primary:focus,
.tag-input .button.primary.focus,
.tag-input .button.primary:active,
.tag-input .button.primary:focus,
.tally.primary.focus,
.tally.primary:active,
.tally.primary:focus,
.toast.primary.focus,
.toast.primary:active,
.toast.primary:focus,
.tool-button.primary.focus,
.tool-button.primary:active,
.tool-button.primary:focus,
code.primary.focus,
code.primary:active,
code.primary:focus,
tbody td.primary.focus,
tbody td.primary:active,
tbody td.primary:focus,
tbody tr.primary.focus,
tbody tr.primary:active,
tbody tr.primary:focus {
  box-shadow: 0 0 0 3px rgba(3, 102, 214, 0.45);
}

.button.secondary,
.command-button.secondary,
.image-button.secondary,
.info-button.secondary,
.input .button.secondary,
.notify.secondary,
.select .button.secondary,
.shortcut.secondary,
.spinner .button.secondary,
.split-button .split.secondary,
.tag-input .button.secondary,
.tally.secondary,
.toast.secondary,
.tool-button.secondary,
code.secondary,
tbody td.secondary,
tbody tr.secondary {
  outline-color: #b7c6cd;
  background-color: #607d8b;
  color: #fff;
}

.button.secondary.outline,
.command-button.secondary.outline,
.image-button.secondary.outline,
.info-button.secondary.outline,
.input .button.secondary.outline,
.notify.secondary.outline,
.select .button.secondary.outline,
.shortcut.secondary.outline,
.spinner .button.secondary.outline,
.split-button .split.secondary.outline,
.tag-input .button.secondary.outline,
.tally.secondary.outline,
.toast.secondary.outline,
.tool-button.secondary.outline,
code.secondary.outline,
tbody td.secondary.outline,
tbody tr.secondary.outline {
  color: #607d8b;
  border-color: #607d8b;
}

.button.secondary.outline.dropdown-toggle::before,
.command-button.secondary.outline.dropdown-toggle::before,
.image-button.secondary.outline.dropdown-toggle::before,
.info-button.secondary.outline.dropdown-toggle::before,
.input .button.secondary.outline.dropdown-toggle::before,
.notify.secondary.outline.dropdown-toggle::before,
.select .button.secondary.outline.dropdown-toggle::before,
.shortcut.secondary.outline.dropdown-toggle::before,
.spinner .button.secondary.outline.dropdown-toggle::before,
.split-button .split.secondary.outline.dropdown-toggle::before,
.tag-input .button.secondary.outline.dropdown-toggle::before,
.tally.secondary.outline.dropdown-toggle::before,
.toast.secondary.outline.dropdown-toggle::before,
.tool-button.secondary.outline.dropdown-toggle::before,
code.secondary.outline.dropdown-toggle::before,
tbody td.secondary.outline.dropdown-toggle::before,
tbody tr.secondary.outline.dropdown-toggle::before {
  border-color: #607d8b;
}

.button.secondary.outline:hover,
.command-button.secondary.outline:hover,
.image-button.secondary.outline:hover,
.info-button.secondary.outline:hover,
.input .button.secondary.outline:hover,
.notify.secondary.outline:hover,
.select .button.secondary.outline:hover,
.shortcut.secondary.outline:hover,
.spinner .button.secondary.outline:hover,
.split-button .split.secondary.outline:hover,
.tag-input .button.secondary.outline:hover,
.tally.secondary.outline:hover,
.toast.secondary.outline:hover,
.tool-button.secondary.outline:hover,
code.secondary.outline:hover,
tbody td.secondary.outline:hover,
tbody tr.secondary.outline:hover {
  color: #fff;
}

.button.secondary.dropdown-toggle::before,
.command-button.secondary.dropdown-toggle::before,
.image-button.secondary.dropdown-toggle::before,
.info-button.secondary.dropdown-toggle::before,
.input .button.secondary.dropdown-toggle::before,
.notify.secondary.dropdown-toggle::before,
.select .button.secondary.dropdown-toggle::before,
.shortcut.secondary.dropdown-toggle::before,
.spinner .button.secondary.dropdown-toggle::before,
.split-button .split.secondary.dropdown-toggle::before,
.tag-input .button.secondary.dropdown-toggle::before,
.tally.secondary.dropdown-toggle::before,
.toast.secondary.dropdown-toggle::before,
.tool-button.secondary.dropdown-toggle::before,
code.secondary.dropdown-toggle::before,
tbody td.secondary.dropdown-toggle::before,
tbody tr.secondary.dropdown-toggle::before {
  border-color: #fff;
}

.button.secondary:hover,
.command-button.secondary:hover,
.image-button.secondary:hover,
.info-button.secondary:hover,
.input .button.secondary:hover,
.notify.secondary:hover,
.select .button.secondary:hover,
.shortcut.secondary:hover,
.spinner .button.secondary:hover,
.split-button .split.secondary:hover,
.tag-input .button.secondary:hover,
.tally.secondary:hover,
.toast.secondary:hover,
.tool-button.secondary:hover,
code.secondary:hover,
tbody td.secondary:hover,
tbody tr.secondary:hover {
  color: #fff;
  background-color: #4b626d;
  border-color: #36474f;
}

.button.secondary:hover.dropdown-toggle::before,
.command-button.secondary:hover.dropdown-toggle::before,
.image-button.secondary:hover.dropdown-toggle::before,
.info-button.secondary:hover.dropdown-toggle::before,
.input .button.secondary:hover.dropdown-toggle::before,
.notify.secondary:hover.dropdown-toggle::before,
.select .button.secondary:hover.dropdown-toggle::before,
.shortcut.secondary:hover.dropdown-toggle::before,
.spinner .button.secondary:hover.dropdown-toggle::before,
.split-button .split.secondary:hover.dropdown-toggle::before,
.tag-input .button.secondary:hover.dropdown-toggle::before,
.tally.secondary:hover.dropdown-toggle::before,
.toast.secondary:hover.dropdown-toggle::before,
.tool-button.secondary:hover.dropdown-toggle::before,
code.secondary:hover.dropdown-toggle::before,
tbody td.secondary:hover.dropdown-toggle::before,
tbody tr.secondary:hover.dropdown-toggle::before {
  border-color: #fff;
}

.button.secondary.same-color:hover,
.command-button.secondary.same-color:hover,
.image-button.secondary.same-color:hover,
.info-button.secondary.same-color:hover,
.input .button.secondary.same-color:hover,
.notify.secondary.same-color:hover,
.select .button.secondary.same-color:hover,
.shortcut.secondary.same-color:hover,
.spinner .button.secondary.same-color:hover,
.split-button .split.secondary.same-color:hover,
.tag-input .button.secondary.same-color:hover,
.tally.secondary.same-color:hover,
.toast.secondary.same-color:hover,
.tool-button.secondary.same-color:hover,
code.secondary.same-color:hover,
tbody td.secondary.same-color:hover,
tbody tr.secondary.same-color:hover {
  background-color: #607d8b;
  border-color: #607d8b;
}

.button.secondary.focus,
.button.secondary:active,
.button.secondary:focus,
.command-button.secondary.focus,
.command-button.secondary:active,
.command-button.secondary:focus,
.image-button.secondary.focus,
.image-button.secondary:active,
.image-button.secondary:focus,
.info-button.secondary.focus,
.info-button.secondary:active,
.info-button.secondary:focus,
.input .button.secondary.focus,
.input .button.secondary:active,
.input .button.secondary:focus,
.notify.secondary.focus,
.notify.secondary:active,
.notify.secondary:focus,
.select .button.secondary.focus,
.select .button.secondary:active,
.select .button.secondary:focus,
.shortcut.secondary.focus,
.shortcut.secondary:active,
.shortcut.secondary:focus,
.spinner .button.secondary.focus,
.spinner .button.secondary:active,
.spinner .button.secondary:focus,
.split-button .split.secondary.focus,
.split-button .split.secondary:active,
.split-button .split.secondary:focus,
.tag-input .button.secondary.focus,
.tag-input .button.secondary:active,
.tag-input .button.secondary:focus,
.tally.secondary.focus,
.tally.secondary:active,
.tally.secondary:focus,
.toast.secondary.focus,
.toast.secondary:active,
.toast.secondary:focus,
.tool-button.secondary.focus,
.tool-button.secondary:active,
.tool-button.secondary:focus,
code.secondary.focus,
code.secondary:active,
code.secondary:focus,
tbody td.secondary.focus,
tbody td.secondary:active,
tbody td.secondary:focus,
tbody tr.secondary.focus,
tbody tr.secondary:active,
tbody tr.secondary:focus {
  box-shadow: 0 0 0 3px rgba(96, 125, 139, 0.45);
}

.button.success,
.command-button.success,
.image-button.success,
.info-button.success,
.input .button.success,
.notify.success,
.select .button.success,
.shortcut.success,
.spinner .button.success,
.split-button .split.success,
.tag-input .button.success,
.tally.success,
.toast.success,
.tool-button.success,
code.success,
tbody td.success,
tbody tr.success {
  outline-color: #adeb6e;
  background-color: #60a917;
  color: #fff;
}

.button.success.outline,
.command-button.success.outline,
.image-button.success.outline,
.info-button.success.outline,
.input .button.success.outline,
.notify.success.outline,
.select .button.success.outline,
.shortcut.success.outline,
.spinner .button.success.outline,
.split-button .split.success.outline,
.tag-input .button.success.outline,
.tally.success.outline,
.toast.success.outline,
.tool-button.success.outline,
code.success.outline,
tbody td.success.outline,
tbody tr.success.outline {
  color: #60a917;
  border-color: #60a917;
}

.button.success.outline.dropdown-toggle::before,
.command-button.success.outline.dropdown-toggle::before,
.image-button.success.outline.dropdown-toggle::before,
.info-button.success.outline.dropdown-toggle::before,
.input .button.success.outline.dropdown-toggle::before,
.notify.success.outline.dropdown-toggle::before,
.select .button.success.outline.dropdown-toggle::before,
.shortcut.success.outline.dropdown-toggle::before,
.spinner .button.success.outline.dropdown-toggle::before,
.split-button .split.success.outline.dropdown-toggle::before,
.tag-input .button.success.outline.dropdown-toggle::before,
.tally.success.outline.dropdown-toggle::before,
.toast.success.outline.dropdown-toggle::before,
.tool-button.success.outline.dropdown-toggle::before,
code.success.outline.dropdown-toggle::before,
tbody td.success.outline.dropdown-toggle::before,
tbody tr.success.outline.dropdown-toggle::before {
  border-color: #60a917;
}

.button.success.outline:hover,
.command-button.success.outline:hover,
.image-button.success.outline:hover,
.info-button.success.outline:hover,
.input .button.success.outline:hover,
.notify.success.outline:hover,
.select .button.success.outline:hover,
.shortcut.success.outline:hover,
.spinner .button.success.outline:hover,
.split-button .split.success.outline:hover,
.tag-input .button.success.outline:hover,
.tally.success.outline:hover,
.toast.success.outline:hover,
.tool-button.success.outline:hover,
code.success.outline:hover,
tbody td.success.outline:hover,
tbody tr.success.outline:hover {
  color: #fff;
}

.button.success.dropdown-toggle::before,
.command-button.success.dropdown-toggle::before,
.image-button.success.dropdown-toggle::before,
.info-button.success.dropdown-toggle::before,
.input .button.success.dropdown-toggle::before,
.notify.success.dropdown-toggle::before,
.select .button.success.dropdown-toggle::before,
.shortcut.success.dropdown-toggle::before,
.spinner .button.success.dropdown-toggle::before,
.split-button .split.success.dropdown-toggle::before,
.tag-input .button.success.dropdown-toggle::before,
.tally.success.dropdown-toggle::before,
.toast.success.dropdown-toggle::before,
.tool-button.success.dropdown-toggle::before,
code.success.dropdown-toggle::before,
tbody td.success.dropdown-toggle::before,
tbody tr.success.dropdown-toggle::before {
  border-color: #fff;
}

.button.success:hover,
.command-button.success:hover,
.image-button.success:hover,
.info-button.success:hover,
.input .button.success:hover,
.notify.success:hover,
.select .button.success:hover,
.shortcut.success:hover,
.spinner .button.success:hover,
.split-button .split.success:hover,
.tag-input .button.success:hover,
.tally.success:hover,
.toast.success:hover,
.tool-button.success:hover,
code.success:hover,
tbody td.success:hover,
tbody tr.success:hover {
  color: #fff;
  background-color: #477c11;
  border-color: #2d4f0b;
}

.button.success:hover.dropdown-toggle::before,
.command-button.success:hover.dropdown-toggle::before,
.image-button.success:hover.dropdown-toggle::before,
.info-button.success:hover.dropdown-toggle::before,
.input .button.success:hover.dropdown-toggle::before,
.notify.success:hover.dropdown-toggle::before,
.select .button.success:hover.dropdown-toggle::before,
.shortcut.success:hover.dropdown-toggle::before,
.spinner .button.success:hover.dropdown-toggle::before,
.split-button .split.success:hover.dropdown-toggle::before,
.tag-input .button.success:hover.dropdown-toggle::before,
.tally.success:hover.dropdown-toggle::before,
.toast.success:hover.dropdown-toggle::before,
.tool-button.success:hover.dropdown-toggle::before,
code.success:hover.dropdown-toggle::before,
tbody td.success:hover.dropdown-toggle::before,
tbody tr.success:hover.dropdown-toggle::before {
  border-color: #fff;
}

.button.success.same-color:hover,
.command-button.success.same-color:hover,
.image-button.success.same-color:hover,
.info-button.success.same-color:hover,
.input .button.success.same-color:hover,
.notify.success.same-color:hover,
.select .button.success.same-color:hover,
.shortcut.success.same-color:hover,
.spinner .button.success.same-color:hover,
.split-button .split.success.same-color:hover,
.tag-input .button.success.same-color:hover,
.tally.success.same-color:hover,
.toast.success.same-color:hover,
.tool-button.success.same-color:hover,
code.success.same-color:hover,
tbody td.success.same-color:hover,
tbody tr.success.same-color:hover {
  background-color: #60a917;
  border-color: #60a917;
}

.button.success.focus,
.button.success:active,
.button.success:focus,
.command-button.success.focus,
.command-button.success:active,
.command-button.success:focus,
.image-button.success.focus,
.image-button.success:active,
.image-button.success:focus,
.info-button.success.focus,
.info-button.success:active,
.info-button.success:focus,
.input .button.success.focus,
.input .button.success:active,
.input .button.success:focus,
.notify.success.focus,
.notify.success:active,
.notify.success:focus,
.select .button.success.focus,
.select .button.success:active,
.select .button.success:focus,
.shortcut.success.focus,
.shortcut.success:active,
.shortcut.success:focus,
.spinner .button.success.focus,
.spinner .button.success:active,
.spinner .button.success:focus,
.split-button .split.success.focus,
.split-button .split.success:active,
.split-button .split.success:focus,
.tag-input .button.success.focus,
.tag-input .button.success:active,
.tag-input .button.success:focus,
.tally.success.focus,
.tally.success:active,
.tally.success:focus,
.toast.success.focus,
.toast.success:active,
.toast.success:focus,
.tool-button.success.focus,
.tool-button.success:active,
.tool-button.success:focus,
code.success.focus,
code.success:active,
code.success:focus,
tbody td.success.focus,
tbody td.success:active,
tbody td.success:focus,
tbody tr.success.focus,
tbody tr.success:active,
tbody tr.success:focus {
  box-shadow: 0 0 0 3px rgba(96, 169, 23, 0.45);
}

.button.alert,
.command-button.alert,
.image-button.alert,
.info-button.alert,
.input .button.alert,
.notify.alert,
.select .button.alert,
.shortcut.alert,
.spinner .button.alert,
.split-button .split.alert,
.tag-input .button.alert,
.tally.alert,
.toast.alert,
.tool-button.alert,
code.alert,
tbody td.alert,
tbody tr.alert {
  outline-color: #ecaba7;
  background-color: #ce352c;
  color: #fff;
}

.button.alert.outline,
.command-button.alert.outline,
.image-button.alert.outline,
.info-button.alert.outline,
.input .button.alert.outline,
.notify.alert.outline,
.select .button.alert.outline,
.shortcut.alert.outline,
.spinner .button.alert.outline,
.split-button .split.alert.outline,
.tag-input .button.alert.outline,
.tally.alert.outline,
.toast.alert.outline,
.tool-button.alert.outline,
code.alert.outline,
tbody td.alert.outline,
tbody tr.alert.outline {
  color: #ce352c;
  border-color: #ce352c;
}

.button.alert.outline.dropdown-toggle::before,
.command-button.alert.outline.dropdown-toggle::before,
.image-button.alert.outline.dropdown-toggle::before,
.info-button.alert.outline.dropdown-toggle::before,
.input .button.alert.outline.dropdown-toggle::before,
.notify.alert.outline.dropdown-toggle::before,
.select .button.alert.outline.dropdown-toggle::before,
.shortcut.alert.outline.dropdown-toggle::before,
.spinner .button.alert.outline.dropdown-toggle::before,
.split-button .split.alert.outline.dropdown-toggle::before,
.tag-input .button.alert.outline.dropdown-toggle::before,
.tally.alert.outline.dropdown-toggle::before,
.toast.alert.outline.dropdown-toggle::before,
.tool-button.alert.outline.dropdown-toggle::before,
code.alert.outline.dropdown-toggle::before,
tbody td.alert.outline.dropdown-toggle::before,
tbody tr.alert.outline.dropdown-toggle::before {
  border-color: #ce352c;
}

.button.alert.outline:hover,
.command-button.alert.outline:hover,
.image-button.alert.outline:hover,
.info-button.alert.outline:hover,
.input .button.alert.outline:hover,
.notify.alert.outline:hover,
.select .button.alert.outline:hover,
.shortcut.alert.outline:hover,
.spinner .button.alert.outline:hover,
.split-button .split.alert.outline:hover,
.tag-input .button.alert.outline:hover,
.tally.alert.outline:hover,
.toast.alert.outline:hover,
.tool-button.alert.outline:hover,
code.alert.outline:hover,
tbody td.alert.outline:hover,
tbody tr.alert.outline:hover {
  color: #fff;
}

.button.alert.dropdown-toggle::before,
.command-button.alert.dropdown-toggle::before,
.image-button.alert.dropdown-toggle::before,
.info-button.alert.dropdown-toggle::before,
.input .button.alert.dropdown-toggle::before,
.notify.alert.dropdown-toggle::before,
.select .button.alert.dropdown-toggle::before,
.shortcut.alert.dropdown-toggle::before,
.spinner .button.alert.dropdown-toggle::before,
.split-button .split.alert.dropdown-toggle::before,
.tag-input .button.alert.dropdown-toggle::before,
.tally.alert.dropdown-toggle::before,
.toast.alert.dropdown-toggle::before,
.tool-button.alert.dropdown-toggle::before,
code.alert.dropdown-toggle::before,
tbody td.alert.dropdown-toggle::before,
tbody tr.alert.dropdown-toggle::before {
  border-color: #fff;
}

.button.alert:hover,
.command-button.alert:hover,
.image-button.alert:hover,
.info-button.alert:hover,
.input .button.alert:hover,
.notify.alert:hover,
.select .button.alert:hover,
.shortcut.alert:hover,
.spinner .button.alert:hover,
.split-button .split.alert:hover,
.tag-input .button.alert:hover,
.tally.alert:hover,
.toast.alert:hover,
.tool-button.alert:hover,
code.alert:hover,
tbody td.alert:hover,
tbody tr.alert:hover {
  color: #fff;
  background-color: #a42a23;
  border-color: #7a1f1a;
}

.button.alert:hover.dropdown-toggle::before,
.command-button.alert:hover.dropdown-toggle::before,
.image-button.alert:hover.dropdown-toggle::before,
.info-button.alert:hover.dropdown-toggle::before,
.input .button.alert:hover.dropdown-toggle::before,
.notify.alert:hover.dropdown-toggle::before,
.select .button.alert:hover.dropdown-toggle::before,
.shortcut.alert:hover.dropdown-toggle::before,
.spinner .button.alert:hover.dropdown-toggle::before,
.split-button .split.alert:hover.dropdown-toggle::before,
.tag-input .button.alert:hover.dropdown-toggle::before,
.tally.alert:hover.dropdown-toggle::before,
.toast.alert:hover.dropdown-toggle::before,
.tool-button.alert:hover.dropdown-toggle::before,
code.alert:hover.dropdown-toggle::before,
tbody td.alert:hover.dropdown-toggle::before,
tbody tr.alert:hover.dropdown-toggle::before {
  border-color: #fff;
}

.button.alert.same-color:hover,
.command-button.alert.same-color:hover,
.image-button.alert.same-color:hover,
.info-button.alert.same-color:hover,
.input .button.alert.same-color:hover,
.notify.alert.same-color:hover,
.select .button.alert.same-color:hover,
.shortcut.alert.same-color:hover,
.spinner .button.alert.same-color:hover,
.split-button .split.alert.same-color:hover,
.tag-input .button.alert.same-color:hover,
.tally.alert.same-color:hover,
.toast.alert.same-color:hover,
.tool-button.alert.same-color:hover,
code.alert.same-color:hover,
tbody td.alert.same-color:hover,
tbody tr.alert.same-color:hover {
  background-color: #ce352c;
  border-color: #ce352c;
}

.button.alert.focus,
.button.alert:active,
.button.alert:focus,
.command-button.alert.focus,
.command-button.alert:active,
.command-button.alert:focus,
.image-button.alert.focus,
.image-button.alert:active,
.image-button.alert:focus,
.info-button.alert.focus,
.info-button.alert:active,
.info-button.alert:focus,
.input .button.alert.focus,
.input .button.alert:active,
.input .button.alert:focus,
.notify.alert.focus,
.notify.alert:active,
.notify.alert:focus,
.select .button.alert.focus,
.select .button.alert:active,
.select .button.alert:focus,
.shortcut.alert.focus,
.shortcut.alert:active,
.shortcut.alert:focus,
.spinner .button.alert.focus,
.spinner .button.alert:active,
.spinner .button.alert:focus,
.split-button .split.alert.focus,
.split-button .split.alert:active,
.split-button .split.alert:focus,
.tag-input .button.alert.focus,
.tag-input .button.alert:active,
.tag-input .button.alert:focus,
.tally.alert.focus,
.tally.alert:active,
.tally.alert:focus,
.toast.alert.focus,
.toast.alert:active,
.toast.alert:focus,
.tool-button.alert.focus,
.tool-button.alert:active,
.tool-button.alert:focus,
code.alert.focus,
code.alert:active,
code.alert:focus,
tbody td.alert.focus,
tbody td.alert:active,
tbody td.alert:focus,
tbody tr.alert.focus,
tbody tr.alert:active,
tbody tr.alert:focus {
  box-shadow: 0 0 0 3px rgba(206, 53, 44, 0.45);
}

.button.warning,
.command-button.warning,
.image-button.warning,
.info-button.warning,
.input .button.warning,
.notify.warning,
.select .button.warning,
.shortcut.warning,
.spinner .button.warning,
.split-button .split.warning,
.tag-input .button.warning,
.tally.warning,
.toast.warning,
.tool-button.warning,
code.warning,
tbody td.warning,
tbody tr.warning {
  outline-color: #ffede1;
  background-color: #ff9447;
  color: #fff;
}

.button.warning.outline,
.command-button.warning.outline,
.image-button.warning.outline,
.info-button.warning.outline,
.input .button.warning.outline,
.notify.warning.outline,
.select .button.warning.outline,
.shortcut.warning.outline,
.spinner .button.warning.outline,
.split-button .split.warning.outline,
.tag-input .button.warning.outline,
.tally.warning.outline,
.toast.warning.outline,
.tool-button.warning.outline,
code.warning.outline,
tbody td.warning.outline,
tbody tr.warning.outline {
  color: #ff9447;
  border-color: #ff9447;
}

.button.warning.outline.dropdown-toggle::before,
.command-button.warning.outline.dropdown-toggle::before,
.image-button.warning.outline.dropdown-toggle::before,
.info-button.warning.outline.dropdown-toggle::before,
.input .button.warning.outline.dropdown-toggle::before,
.notify.warning.outline.dropdown-toggle::before,
.select .button.warning.outline.dropdown-toggle::before,
.shortcut.warning.outline.dropdown-toggle::before,
.spinner .button.warning.outline.dropdown-toggle::before,
.split-button .split.warning.outline.dropdown-toggle::before,
.tag-input .button.warning.outline.dropdown-toggle::before,
.tally.warning.outline.dropdown-toggle::before,
.toast.warning.outline.dropdown-toggle::before,
.tool-button.warning.outline.dropdown-toggle::before,
code.warning.outline.dropdown-toggle::before,
tbody td.warning.outline.dropdown-toggle::before,
tbody tr.warning.outline.dropdown-toggle::before {
  border-color: #ff9447;
}

.button.warning.outline:hover,
.command-button.warning.outline:hover,
.image-button.warning.outline:hover,
.info-button.warning.outline:hover,
.input .button.warning.outline:hover,
.notify.warning.outline:hover,
.select .button.warning.outline:hover,
.shortcut.warning.outline:hover,
.spinner .button.warning.outline:hover,
.split-button .split.warning.outline:hover,
.tag-input .button.warning.outline:hover,
.tally.warning.outline:hover,
.toast.warning.outline:hover,
.tool-button.warning.outline:hover,
code.warning.outline:hover,
tbody td.warning.outline:hover,
tbody tr.warning.outline:hover {
  color: #fff;
}

.button.warning.dropdown-toggle::before,
.command-button.warning.dropdown-toggle::before,
.image-button.warning.dropdown-toggle::before,
.info-button.warning.dropdown-toggle::before,
.input .button.warning.dropdown-toggle::before,
.notify.warning.dropdown-toggle::before,
.select .button.warning.dropdown-toggle::before,
.shortcut.warning.dropdown-toggle::before,
.spinner .button.warning.dropdown-toggle::before,
.split-button .split.warning.dropdown-toggle::before,
.tag-input .button.warning.dropdown-toggle::before,
.tally.warning.dropdown-toggle::before,
.toast.warning.dropdown-toggle::before,
.tool-button.warning.dropdown-toggle::before,
code.warning.dropdown-toggle::before,
tbody td.warning.dropdown-toggle::before,
tbody tr.warning.dropdown-toggle::before {
  border-color: #fff;
}

.button.warning:hover,
.command-button.warning:hover,
.image-button.warning:hover,
.info-button.warning:hover,
.input .button.warning:hover,
.notify.warning:hover,
.select .button.warning:hover,
.shortcut.warning:hover,
.spinner .button.warning:hover,
.split-button .split.warning:hover,
.tag-input .button.warning:hover,
.tally.warning:hover,
.toast.warning:hover,
.tool-button.warning:hover,
code.warning:hover,
tbody td.warning:hover,
tbody tr.warning:hover {
  color: #fff;
  background-color: #ff7615;
  border-color: #e05d00;
}

.button.warning:hover.dropdown-toggle::before,
.command-button.warning:hover.dropdown-toggle::before,
.image-button.warning:hover.dropdown-toggle::before,
.info-button.warning:hover.dropdown-toggle::before,
.input .button.warning:hover.dropdown-toggle::before,
.notify.warning:hover.dropdown-toggle::before,
.select .button.warning:hover.dropdown-toggle::before,
.shortcut.warning:hover.dropdown-toggle::before,
.spinner .button.warning:hover.dropdown-toggle::before,
.split-button .split.warning:hover.dropdown-toggle::before,
.tag-input .button.warning:hover.dropdown-toggle::before,
.tally.warning:hover.dropdown-toggle::before,
.toast.warning:hover.dropdown-toggle::before,
.tool-button.warning:hover.dropdown-toggle::before,
code.warning:hover.dropdown-toggle::before,
tbody td.warning:hover.dropdown-toggle::before,
tbody tr.warning:hover.dropdown-toggle::before {
  border-color: #fff;
}

.button.warning.same-color:hover,
.command-button.warning.same-color:hover,
.image-button.warning.same-color:hover,
.info-button.warning.same-color:hover,
.input .button.warning.same-color:hover,
.notify.warning.same-color:hover,
.select .button.warning.same-color:hover,
.shortcut.warning.same-color:hover,
.spinner .button.warning.same-color:hover,
.split-button .split.warning.same-color:hover,
.tag-input .button.warning.same-color:hover,
.tally.warning.same-color:hover,
.toast.warning.same-color:hover,
.tool-button.warning.same-color:hover,
code.warning.same-color:hover,
tbody td.warning.same-color:hover,
tbody tr.warning.same-color:hover {
  background-color: #ff9447;
  border-color: #ff9447;
}

.button.warning.focus,
.button.warning:active,
.button.warning:focus,
.command-button.warning.focus,
.command-button.warning:active,
.command-button.warning:focus,
.image-button.warning.focus,
.image-button.warning:active,
.image-button.warning:focus,
.info-button.warning.focus,
.info-button.warning:active,
.info-button.warning:focus,
.input .button.warning.focus,
.input .button.warning:active,
.input .button.warning:focus,
.notify.warning.focus,
.notify.warning:active,
.notify.warning:focus,
.select .button.warning.focus,
.select .button.warning:active,
.select .button.warning:focus,
.shortcut.warning.focus,
.shortcut.warning:active,
.shortcut.warning:focus,
.spinner .button.warning.focus,
.spinner .button.warning:active,
.spinner .button.warning:focus,
.split-button .split.warning.focus,
.split-button .split.warning:active,
.split-button .split.warning:focus,
.tag-input .button.warning.focus,
.tag-input .button.warning:active,
.tag-input .button.warning:focus,
.tally.warning.focus,
.tally.warning:active,
.tally.warning:focus,
.toast.warning.focus,
.toast.warning:active,
.toast.warning:focus,
.tool-button.warning.focus,
.tool-button.warning:active,
.tool-button.warning:focus,
code.warning.focus,
code.warning:active,
code.warning:focus,
tbody td.warning.focus,
tbody td.warning:active,
tbody td.warning:focus,
tbody tr.warning.focus,
tbody tr.warning:active,
tbody tr.warning:focus {
  box-shadow: 0 0 0 3px rgba(255, 148, 71, 0.45);
}

.button.yellow,
.command-button.yellow,
.image-button.yellow,
.info-button.yellow,
.input .button.yellow,
.notify.yellow,
.select .button.yellow,
.shortcut.yellow,
.spinner .button.yellow,
.split-button .split.yellow,
.tag-input .button.yellow,
.tally.yellow,
.toast.yellow,
.tool-button.yellow,
code.yellow,
tbody td.yellow,
tbody tr.yellow {
  outline-color: #fff;
  background-color: #ffe484;
  color: #fff;
}

.button.yellow.outline,
.command-button.yellow.outline,
.image-button.yellow.outline,
.info-button.yellow.outline,
.input .button.yellow.outline,
.notify.yellow.outline,
.select .button.yellow.outline,
.shortcut.yellow.outline,
.spinner .button.yellow.outline,
.split-button .split.yellow.outline,
.tag-input .button.yellow.outline,
.tally.yellow.outline,
.toast.yellow.outline,
.tool-button.yellow.outline,
code.yellow.outline,
tbody td.yellow.outline,
tbody tr.yellow.outline {
  color: #ffe484;
  border-color: #ffe484;
}

.button.yellow.outline.dropdown-toggle::before,
.command-button.yellow.outline.dropdown-toggle::before,
.image-button.yellow.outline.dropdown-toggle::before,
.info-button.yellow.outline.dropdown-toggle::before,
.input .button.yellow.outline.dropdown-toggle::before,
.notify.yellow.outline.dropdown-toggle::before,
.select .button.yellow.outline.dropdown-toggle::before,
.shortcut.yellow.outline.dropdown-toggle::before,
.spinner .button.yellow.outline.dropdown-toggle::before,
.split-button .split.yellow.outline.dropdown-toggle::before,
.tag-input .button.yellow.outline.dropdown-toggle::before,
.tally.yellow.outline.dropdown-toggle::before,
.toast.yellow.outline.dropdown-toggle::before,
.tool-button.yellow.outline.dropdown-toggle::before,
code.yellow.outline.dropdown-toggle::before,
tbody td.yellow.outline.dropdown-toggle::before,
tbody tr.yellow.outline.dropdown-toggle::before {
  border-color: #ffe484;
}

.button.yellow.outline:hover,
.command-button.yellow.outline:hover,
.image-button.yellow.outline:hover,
.info-button.yellow.outline:hover,
.input .button.yellow.outline:hover,
.notify.yellow.outline:hover,
.select .button.yellow.outline:hover,
.shortcut.yellow.outline:hover,
.spinner .button.yellow.outline:hover,
.split-button .split.yellow.outline:hover,
.tag-input .button.yellow.outline:hover,
.tally.yellow.outline:hover,
.toast.yellow.outline:hover,
.tool-button.yellow.outline:hover,
code.yellow.outline:hover,
tbody td.yellow.outline:hover,
tbody tr.yellow.outline:hover {
  color: #fff;
}

.button.yellow.dropdown-toggle::before,
.command-button.yellow.dropdown-toggle::before,
.image-button.yellow.dropdown-toggle::before,
.info-button.yellow.dropdown-toggle::before,
.input .button.yellow.dropdown-toggle::before,
.notify.yellow.dropdown-toggle::before,
.select .button.yellow.dropdown-toggle::before,
.shortcut.yellow.dropdown-toggle::before,
.spinner .button.yellow.dropdown-toggle::before,
.split-button .split.yellow.dropdown-toggle::before,
.tag-input .button.yellow.dropdown-toggle::before,
.tally.yellow.dropdown-toggle::before,
.toast.yellow.dropdown-toggle::before,
.tool-button.yellow.dropdown-toggle::before,
code.yellow.dropdown-toggle::before,
tbody td.yellow.dropdown-toggle::before,
tbody tr.yellow.dropdown-toggle::before {
  border-color: #fff;
}

.button.yellow:hover,
.command-button.yellow:hover,
.image-button.yellow:hover,
.info-button.yellow:hover,
.input .button.yellow:hover,
.notify.yellow:hover,
.select .button.yellow:hover,
.shortcut.yellow:hover,
.spinner .button.yellow:hover,
.split-button .split.yellow:hover,
.tag-input .button.yellow:hover,
.tally.yellow:hover,
.toast.yellow:hover,
.tool-button.yellow:hover,
code.yellow:hover,
tbody td.yellow:hover,
tbody tr.yellow:hover {
  color: #fff;
  background-color: #ffd951;
  border-color: #ffce1e;
}

.button.yellow:hover.dropdown-toggle::before,
.command-button.yellow:hover.dropdown-toggle::before,
.image-button.yellow:hover.dropdown-toggle::before,
.info-button.yellow:hover.dropdown-toggle::before,
.input .button.yellow:hover.dropdown-toggle::before,
.notify.yellow:hover.dropdown-toggle::before,
.select .button.yellow:hover.dropdown-toggle::before,
.shortcut.yellow:hover.dropdown-toggle::before,
.spinner .button.yellow:hover.dropdown-toggle::before,
.split-button .split.yellow:hover.dropdown-toggle::before,
.tag-input .button.yellow:hover.dropdown-toggle::before,
.tally.yellow:hover.dropdown-toggle::before,
.toast.yellow:hover.dropdown-toggle::before,
.tool-button.yellow:hover.dropdown-toggle::before,
code.yellow:hover.dropdown-toggle::before,
tbody td.yellow:hover.dropdown-toggle::before,
tbody tr.yellow:hover.dropdown-toggle::before {
  border-color: #fff;
}

.button.yellow.same-color:hover,
.command-button.yellow.same-color:hover,
.image-button.yellow.same-color:hover,
.info-button.yellow.same-color:hover,
.input .button.yellow.same-color:hover,
.notify.yellow.same-color:hover,
.select .button.yellow.same-color:hover,
.shortcut.yellow.same-color:hover,
.spinner .button.yellow.same-color:hover,
.split-button .split.yellow.same-color:hover,
.tag-input .button.yellow.same-color:hover,
.tally.yellow.same-color:hover,
.toast.yellow.same-color:hover,
.tool-button.yellow.same-color:hover,
code.yellow.same-color:hover,
tbody td.yellow.same-color:hover,
tbody tr.yellow.same-color:hover {
  background-color: #ffe484;
  border-color: #ffe484;
}

.button.yellow.focus,
.button.yellow:active,
.button.yellow:focus,
.command-button.yellow.focus,
.command-button.yellow:active,
.command-button.yellow:focus,
.image-button.yellow.focus,
.image-button.yellow:active,
.image-button.yellow:focus,
.info-button.yellow.focus,
.info-button.yellow:active,
.info-button.yellow:focus,
.input .button.yellow.focus,
.input .button.yellow:active,
.input .button.yellow:focus,
.notify.yellow.focus,
.notify.yellow:active,
.notify.yellow:focus,
.select .button.yellow.focus,
.select .button.yellow:active,
.select .button.yellow:focus,
.shortcut.yellow.focus,
.shortcut.yellow:active,
.shortcut.yellow:focus,
.spinner .button.yellow.focus,
.spinner .button.yellow:active,
.spinner .button.yellow:focus,
.split-button .split.yellow.focus,
.split-button .split.yellow:active,
.split-button .split.yellow:focus,
.tag-input .button.yellow.focus,
.tag-input .button.yellow:active,
.tag-input .button.yellow:focus,
.tally.yellow.focus,
.tally.yellow:active,
.tally.yellow:focus,
.toast.yellow.focus,
.toast.yellow:active,
.toast.yellow:focus,
.tool-button.yellow.focus,
.tool-button.yellow:active,
.tool-button.yellow:focus,
code.yellow.focus,
code.yellow:active,
code.yellow:focus,
tbody td.yellow.focus,
tbody td.yellow:active,
tbody td.yellow:focus,
tbody tr.yellow.focus,
tbody tr.yellow:active,
tbody tr.yellow:focus {
  box-shadow: 0 0 0 3px rgba(255, 228, 132, 0.45);
}

.button.info,
.command-button.info,
.image-button.info,
.info-button.info,
.input .button.info,
.notify.info,
.select .button.info,
.shortcut.info,
.spinner .button.info,
.split-button .split.info,
.tag-input .button.info,
.tally.info,
.toast.info,
.tool-button.info,
code.info,
tbody td.info,
tbody tr.info {
  outline-color: #e6f5fc;
  background-color: #5ebdec;
  color: #fff;
}

.button.info.outline,
.command-button.info.outline,
.image-button.info.outline,
.info-button.info.outline,
.input .button.info.outline,
.notify.info.outline,
.select .button.info.outline,
.shortcut.info.outline,
.spinner .button.info.outline,
.split-button .split.info.outline,
.tag-input .button.info.outline,
.tally.info.outline,
.toast.info.outline,
.tool-button.info.outline,
code.info.outline,
tbody td.info.outline,
tbody tr.info.outline {
  color: #5ebdec;
  border-color: #5ebdec;
}

.button.info.outline.dropdown-toggle::before,
.command-button.info.outline.dropdown-toggle::before,
.image-button.info.outline.dropdown-toggle::before,
.info-button.info.outline.dropdown-toggle::before,
.input .button.info.outline.dropdown-toggle::before,
.notify.info.outline.dropdown-toggle::before,
.select .button.info.outline.dropdown-toggle::before,
.shortcut.info.outline.dropdown-toggle::before,
.spinner .button.info.outline.dropdown-toggle::before,
.split-button .split.info.outline.dropdown-toggle::before,
.tag-input .button.info.outline.dropdown-toggle::before,
.tally.info.outline.dropdown-toggle::before,
.toast.info.outline.dropdown-toggle::before,
.tool-button.info.outline.dropdown-toggle::before,
code.info.outline.dropdown-toggle::before,
tbody td.info.outline.dropdown-toggle::before,
tbody tr.info.outline.dropdown-toggle::before {
  border-color: #5ebdec;
}

.button.info.outline:hover,
.command-button.info.outline:hover,
.image-button.info.outline:hover,
.info-button.info.outline:hover,
.input .button.info.outline:hover,
.notify.info.outline:hover,
.select .button.info.outline:hover,
.shortcut.info.outline:hover,
.spinner .button.info.outline:hover,
.split-button .split.info.outline:hover,
.tag-input .button.info.outline:hover,
.tally.info.outline:hover,
.toast.info.outline:hover,
.tool-button.info.outline:hover,
code.info.outline:hover,
tbody td.info.outline:hover,
tbody tr.info.outline:hover {
  color: #fff;
}

.button.info.dropdown-toggle::before,
.command-button.info.dropdown-toggle::before,
.image-button.info.dropdown-toggle::before,
.info-button.info.dropdown-toggle::before,
.input .button.info.dropdown-toggle::before,
.notify.info.dropdown-toggle::before,
.select .button.info.dropdown-toggle::before,
.shortcut.info.dropdown-toggle::before,
.spinner .button.info.dropdown-toggle::before,
.split-button .split.info.dropdown-toggle::before,
.tag-input .button.info.dropdown-toggle::before,
.tally.info.dropdown-toggle::before,
.toast.info.dropdown-toggle::before,
.tool-button.info.dropdown-toggle::before,
code.info.dropdown-toggle::before,
tbody td.info.dropdown-toggle::before,
tbody tr.info.dropdown-toggle::before {
  border-color: #fff;
}

.button.info:hover,
.command-button.info:hover,
.image-button.info:hover,
.info-button.info:hover,
.input .button.info:hover,
.notify.info:hover,
.select .button.info:hover,
.shortcut.info:hover,
.spinner .button.info:hover,
.split-button .split.info:hover,
.tag-input .button.info:hover,
.tally.info:hover,
.toast.info:hover,
.tool-button.info:hover,
code.info:hover,
tbody td.info:hover,
tbody tr.info:hover {
  color: #fff;
  background-color: #30abe6;
  border-color: #1891cb;
}

.button.info:hover.dropdown-toggle::before,
.command-button.info:hover.dropdown-toggle::before,
.image-button.info:hover.dropdown-toggle::before,
.info-button.info:hover.dropdown-toggle::before,
.input .button.info:hover.dropdown-toggle::before,
.notify.info:hover.dropdown-toggle::before,
.select .button.info:hover.dropdown-toggle::before,
.shortcut.info:hover.dropdown-toggle::before,
.spinner .button.info:hover.dropdown-toggle::before,
.split-button .split.info:hover.dropdown-toggle::before,
.tag-input .button.info:hover.dropdown-toggle::before,
.tally.info:hover.dropdown-toggle::before,
.toast.info:hover.dropdown-toggle::before,
.tool-button.info:hover.dropdown-toggle::before,
code.info:hover.dropdown-toggle::before,
tbody td.info:hover.dropdown-toggle::before,
tbody tr.info:hover.dropdown-toggle::before {
  border-color: #fff;
}

.button.info.same-color:hover,
.command-button.info.same-color:hover,
.image-button.info.same-color:hover,
.info-button.info.same-color:hover,
.input .button.info.same-color:hover,
.notify.info.same-color:hover,
.select .button.info.same-color:hover,
.shortcut.info.same-color:hover,
.spinner .button.info.same-color:hover,
.split-button .split.info.same-color:hover,
.tag-input .button.info.same-color:hover,
.tally.info.same-color:hover,
.toast.info.same-color:hover,
.tool-button.info.same-color:hover,
code.info.same-color:hover,
tbody td.info.same-color:hover,
tbody tr.info.same-color:hover {
  background-color: #5ebdec;
  border-color: #5ebdec;
}

.button.info.focus,
.button.info:active,
.button.info:focus,
.command-button.info.focus,
.command-button.info:active,
.command-button.info:focus,
.image-button.info.focus,
.image-button.info:active,
.image-button.info:focus,
.info-button.info.focus,
.info-button.info:active,
.info-button.info:focus,
.input .button.info.focus,
.input .button.info:active,
.input .button.info:focus,
.notify.info.focus,
.notify.info:active,
.notify.info:focus,
.select .button.info.focus,
.select .button.info:active,
.select .button.info:focus,
.shortcut.info.focus,
.shortcut.info:active,
.shortcut.info:focus,
.spinner .button.info.focus,
.spinner .button.info:active,
.spinner .button.info:focus,
.split-button .split.info.focus,
.split-button .split.info:active,
.split-button .split.info:focus,
.tag-input .button.info.focus,
.tag-input .button.info:active,
.tag-input .button.info:focus,
.tally.info.focus,
.tally.info:active,
.tally.info:focus,
.toast.info.focus,
.toast.info:active,
.toast.info:focus,
.tool-button.info.focus,
.tool-button.info:active,
.tool-button.info:focus,
code.info.focus,
code.info:active,
code.info:focus,
tbody td.info.focus,
tbody td.info:active,
tbody td.info:focus,
tbody tr.info.focus,
tbody tr.info:active,
tbody tr.info:focus {
  box-shadow: 0 0 0 3px rgba(94, 189, 236, 0.45);
}

.button.dark,
.command-button.dark,
.image-button.dark,
.info-button.dark,
.input .button.dark,
.notify.dark,
.select .button.dark,
.shortcut.dark,
.spinner .button.dark,
.split-button .split.dark,
.tag-input .button.dark,
.tally.dark,
.toast.dark,
.tool-button.dark,
code.dark,
tbody td.dark,
tbody tr.dark {
  outline-color: #9d9d9d;
  background-color: #505050;
  color: #fff;
}

.button.dark.outline,
.command-button.dark.outline,
.image-button.dark.outline,
.info-button.dark.outline,
.input .button.dark.outline,
.notify.dark.outline,
.select .button.dark.outline,
.shortcut.dark.outline,
.spinner .button.dark.outline,
.split-button .split.dark.outline,
.tag-input .button.dark.outline,
.tally.dark.outline,
.toast.dark.outline,
.tool-button.dark.outline,
code.dark.outline,
tbody td.dark.outline,
tbody tr.dark.outline {
  color: #505050;
  border-color: #505050;
}

.button.dark.outline.dropdown-toggle::before,
.command-button.dark.outline.dropdown-toggle::before,
.image-button.dark.outline.dropdown-toggle::before,
.info-button.dark.outline.dropdown-toggle::before,
.input .button.dark.outline.dropdown-toggle::before,
.notify.dark.outline.dropdown-toggle::before,
.select .button.dark.outline.dropdown-toggle::before,
.shortcut.dark.outline.dropdown-toggle::before,
.spinner .button.dark.outline.dropdown-toggle::before,
.split-button .split.dark.outline.dropdown-toggle::before,
.tag-input .button.dark.outline.dropdown-toggle::before,
.tally.dark.outline.dropdown-toggle::before,
.toast.dark.outline.dropdown-toggle::before,
.tool-button.dark.outline.dropdown-toggle::before,
code.dark.outline.dropdown-toggle::before,
tbody td.dark.outline.dropdown-toggle::before,
tbody tr.dark.outline.dropdown-toggle::before {
  border-color: #505050;
}

.button.dark.outline:hover,
.command-button.dark.outline:hover,
.image-button.dark.outline:hover,
.info-button.dark.outline:hover,
.input .button.dark.outline:hover,
.notify.dark.outline:hover,
.select .button.dark.outline:hover,
.shortcut.dark.outline:hover,
.spinner .button.dark.outline:hover,
.split-button .split.dark.outline:hover,
.tag-input .button.dark.outline:hover,
.tally.dark.outline:hover,
.toast.dark.outline:hover,
.tool-button.dark.outline:hover,
code.dark.outline:hover,
tbody td.dark.outline:hover,
tbody tr.dark.outline:hover {
  color: #fff;
}

.button.dark.dropdown-toggle::before,
.command-button.dark.dropdown-toggle::before,
.image-button.dark.dropdown-toggle::before,
.info-button.dark.dropdown-toggle::before,
.input .button.dark.dropdown-toggle::before,
.notify.dark.dropdown-toggle::before,
.select .button.dark.dropdown-toggle::before,
.shortcut.dark.dropdown-toggle::before,
.spinner .button.dark.dropdown-toggle::before,
.split-button .split.dark.dropdown-toggle::before,
.tag-input .button.dark.dropdown-toggle::before,
.tally.dark.dropdown-toggle::before,
.toast.dark.dropdown-toggle::before,
.tool-button.dark.dropdown-toggle::before,
code.dark.dropdown-toggle::before,
tbody td.dark.dropdown-toggle::before,
tbody tr.dark.dropdown-toggle::before {
  border-color: #fff;
}

.button.dark:hover,
.command-button.dark:hover,
.image-button.dark:hover,
.info-button.dark:hover,
.input .button.dark:hover,
.notify.dark:hover,
.select .button.dark:hover,
.shortcut.dark:hover,
.spinner .button.dark:hover,
.split-button .split.dark:hover,
.tag-input .button.dark:hover,
.tally.dark:hover,
.toast.dark:hover,
.tool-button.dark:hover,
code.dark:hover,
tbody td.dark:hover,
tbody tr.dark:hover {
  color: #fff;
  background-color: #373737;
  border-color: #1d1d1d;
}

.button.dark:hover.dropdown-toggle::before,
.command-button.dark:hover.dropdown-toggle::before,
.image-button.dark:hover.dropdown-toggle::before,
.info-button.dark:hover.dropdown-toggle::before,
.input .button.dark:hover.dropdown-toggle::before,
.notify.dark:hover.dropdown-toggle::before,
.select .button.dark:hover.dropdown-toggle::before,
.shortcut.dark:hover.dropdown-toggle::before,
.spinner .button.dark:hover.dropdown-toggle::before,
.split-button .split.dark:hover.dropdown-toggle::before,
.tag-input .button.dark:hover.dropdown-toggle::before,
.tally.dark:hover.dropdown-toggle::before,
.toast.dark:hover.dropdown-toggle::before,
.tool-button.dark:hover.dropdown-toggle::before,
code.dark:hover.dropdown-toggle::before,
tbody td.dark:hover.dropdown-toggle::before,
tbody tr.dark:hover.dropdown-toggle::before {
  border-color: #fff;
}

.button.dark.same-color:hover,
.command-button.dark.same-color:hover,
.image-button.dark.same-color:hover,
.info-button.dark.same-color:hover,
.input .button.dark.same-color:hover,
.notify.dark.same-color:hover,
.select .button.dark.same-color:hover,
.shortcut.dark.same-color:hover,
.spinner .button.dark.same-color:hover,
.split-button .split.dark.same-color:hover,
.tag-input .button.dark.same-color:hover,
.tally.dark.same-color:hover,
.toast.dark.same-color:hover,
.tool-button.dark.same-color:hover,
code.dark.same-color:hover,
tbody td.dark.same-color:hover,
tbody tr.dark.same-color:hover {
  background-color: #505050;
  border-color: #505050;
}

.button.dark.focus,
.button.dark:active,
.button.dark:focus,
.command-button.dark.focus,
.command-button.dark:active,
.command-button.dark:focus,
.image-button.dark.focus,
.image-button.dark:active,
.image-button.dark:focus,
.info-button.dark.focus,
.info-button.dark:active,
.info-button.dark:focus,
.input .button.dark.focus,
.input .button.dark:active,
.input .button.dark:focus,
.notify.dark.focus,
.notify.dark:active,
.notify.dark:focus,
.select .button.dark.focus,
.select .button.dark:active,
.select .button.dark:focus,
.shortcut.dark.focus,
.shortcut.dark:active,
.shortcut.dark:focus,
.spinner .button.dark.focus,
.spinner .button.dark:active,
.spinner .button.dark:focus,
.split-button .split.dark.focus,
.split-button .split.dark:active,
.split-button .split.dark:focus,
.tag-input .button.dark.focus,
.tag-input .button.dark:active,
.tag-input .button.dark:focus,
.tally.dark.focus,
.tally.dark:active,
.tally.dark:focus,
.toast.dark.focus,
.toast.dark:active,
.toast.dark:focus,
.tool-button.dark.focus,
.tool-button.dark:active,
.tool-button.dark:focus,
code.dark.focus,
code.dark:active,
code.dark:focus,
tbody td.dark.focus,
tbody td.dark:active,
tbody td.dark:focus,
tbody tr.dark.focus,
tbody tr.dark:active,
tbody tr.dark:focus {
  box-shadow: 0 0 0 3px rgba(80, 80, 80, 0.45);
}

.button.light,
.command-button.light,
.image-button.light,
.info-button.light,
.input .button.light,
.notify.light,
.select .button.light,
.shortcut.light,
.spinner .button.light,
.split-button .split.light,
.tag-input .button.light,
.tally.light,
.toast.light,
.tool-button.light,
code.light,
tbody td.light,
tbody tr.light {
  outline-color: #fff;
  background-color: #f8f8f8;
  color: #fff;
}

.button.light.outline,
.command-button.light.outline,
.image-button.light.outline,
.info-button.light.outline,
.input .button.light.outline,
.notify.light.outline,
.select .button.light.outline,
.shortcut.light.outline,
.spinner .button.light.outline,
.split-button .split.light.outline,
.tag-input .button.light.outline,
.tally.light.outline,
.toast.light.outline,
.tool-button.light.outline,
code.light.outline,
tbody td.light.outline,
tbody tr.light.outline {
  color: #f8f8f8;
  border-color: #f8f8f8;
}

.button.light.outline.dropdown-toggle::before,
.command-button.light.outline.dropdown-toggle::before,
.image-button.light.outline.dropdown-toggle::before,
.info-button.light.outline.dropdown-toggle::before,
.input .button.light.outline.dropdown-toggle::before,
.notify.light.outline.dropdown-toggle::before,
.select .button.light.outline.dropdown-toggle::before,
.shortcut.light.outline.dropdown-toggle::before,
.spinner .button.light.outline.dropdown-toggle::before,
.split-button .split.light.outline.dropdown-toggle::before,
.tag-input .button.light.outline.dropdown-toggle::before,
.tally.light.outline.dropdown-toggle::before,
.toast.light.outline.dropdown-toggle::before,
.tool-button.light.outline.dropdown-toggle::before,
code.light.outline.dropdown-toggle::before,
tbody td.light.outline.dropdown-toggle::before,
tbody tr.light.outline.dropdown-toggle::before {
  border-color: #f8f8f8;
}

.button.light.outline:hover,
.command-button.light.outline:hover,
.image-button.light.outline:hover,
.info-button.light.outline:hover,
.input .button.light.outline:hover,
.notify.light.outline:hover,
.select .button.light.outline:hover,
.shortcut.light.outline:hover,
.spinner .button.light.outline:hover,
.split-button .split.light.outline:hover,
.tag-input .button.light.outline:hover,
.tally.light.outline:hover,
.toast.light.outline:hover,
.tool-button.light.outline:hover,
code.light.outline:hover,
tbody td.light.outline:hover,
tbody tr.light.outline:hover {
  color: #fff;
}

.button.light.dropdown-toggle::before,
.command-button.light.dropdown-toggle::before,
.image-button.light.dropdown-toggle::before,
.info-button.light.dropdown-toggle::before,
.input .button.light.dropdown-toggle::before,
.notify.light.dropdown-toggle::before,
.select .button.light.dropdown-toggle::before,
.shortcut.light.dropdown-toggle::before,
.spinner .button.light.dropdown-toggle::before,
.split-button .split.light.dropdown-toggle::before,
.tag-input .button.light.dropdown-toggle::before,
.tally.light.dropdown-toggle::before,
.toast.light.dropdown-toggle::before,
.tool-button.light.dropdown-toggle::before,
code.light.dropdown-toggle::before,
tbody td.light.dropdown-toggle::before,
tbody tr.light.dropdown-toggle::before {
  border-color: #fff;
}

.button.light:hover,
.command-button.light:hover,
.image-button.light:hover,
.info-button.light:hover,
.input .button.light:hover,
.notify.light:hover,
.select .button.light:hover,
.shortcut.light:hover,
.spinner .button.light:hover,
.split-button .split.light:hover,
.tag-input .button.light:hover,
.tally.light:hover,
.toast.light:hover,
.tool-button.light:hover,
code.light:hover,
tbody td.light:hover,
tbody tr.light:hover {
  color: #fff;
  background-color: #dfdfdf;
  border-color: #c5c5c5;
}

.button.light:hover.dropdown-toggle::before,
.command-button.light:hover.dropdown-toggle::before,
.image-button.light:hover.dropdown-toggle::before,
.info-button.light:hover.dropdown-toggle::before,
.input .button.light:hover.dropdown-toggle::before,
.notify.light:hover.dropdown-toggle::before,
.select .button.light:hover.dropdown-toggle::before,
.shortcut.light:hover.dropdown-toggle::before,
.spinner .button.light:hover.dropdown-toggle::before,
.split-button .split.light:hover.dropdown-toggle::before,
.tag-input .button.light:hover.dropdown-toggle::before,
.tally.light:hover.dropdown-toggle::before,
.toast.light:hover.dropdown-toggle::before,
.tool-button.light:hover.dropdown-toggle::before,
code.light:hover.dropdown-toggle::before,
tbody td.light:hover.dropdown-toggle::before,
tbody tr.light:hover.dropdown-toggle::before {
  border-color: #fff;
}

.button.light.same-color:hover,
.command-button.light.same-color:hover,
.image-button.light.same-color:hover,
.info-button.light.same-color:hover,
.input .button.light.same-color:hover,
.notify.light.same-color:hover,
.select .button.light.same-color:hover,
.shortcut.light.same-color:hover,
.spinner .button.light.same-color:hover,
.split-button .split.light.same-color:hover,
.tag-input .button.light.same-color:hover,
.tally.light.same-color:hover,
.toast.light.same-color:hover,
.tool-button.light.same-color:hover,
code.light.same-color:hover,
tbody td.light.same-color:hover,
tbody tr.light.same-color:hover {
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.button.light.focus,
.button.light:active,
.button.light:focus,
.command-button.light.focus,
.command-button.light:active,
.command-button.light:focus,
.image-button.light.focus,
.image-button.light:active,
.image-button.light:focus,
.info-button.light.focus,
.info-button.light:active,
.info-button.light:focus,
.input .button.light.focus,
.input .button.light:active,
.input .button.light:focus,
.notify.light.focus,
.notify.light:active,
.notify.light:focus,
.select .button.light.focus,
.select .button.light:active,
.select .button.light:focus,
.shortcut.light.focus,
.shortcut.light:active,
.shortcut.light:focus,
.spinner .button.light.focus,
.spinner .button.light:active,
.spinner .button.light:focus,
.split-button .split.light.focus,
.split-button .split.light:active,
.split-button .split.light:focus,
.tag-input .button.light.focus,
.tag-input .button.light:active,
.tag-input .button.light:focus,
.tally.light.focus,
.tally.light:active,
.tally.light:focus,
.toast.light.focus,
.toast.light:active,
.toast.light:focus,
.tool-button.light.focus,
.tool-button.light:active,
.tool-button.light:focus,
code.light.focus,
code.light:active,
code.light:focus,
tbody td.light.focus,
tbody td.light:active,
tbody td.light:focus,
tbody tr.light.focus,
tbody tr.light:active,
tbody tr.light:focus {
  box-shadow: 0 0 0 3px rgba(248, 248, 248, 0.45);
}

.button.light,
.button.yellow,
.command-button.light,
.command-button.yellow,
.image-button.light,
.image-button.yellow,
.info-button.light,
.info-button.yellow,
.input .button.light,
.input .button.yellow,
.notify.light,
.notify.yellow,
.select .button.light,
.select .button.yellow,
.shortcut.light,
.shortcut.yellow,
.spinner .button.light,
.spinner .button.yellow,
.split-button .split.light,
.split-button .split.yellow,
.tag-input .button.light,
.tag-input .button.yellow,
.tally.light,
.tally.yellow,
.toast.light,
.toast.yellow,
.tool-button.light,
.tool-button.yellow,
code.light,
code.yellow,
tbody td.light,
tbody td.yellow,
tbody tr.light,
tbody tr.yellow {
  color: #1d1d1d;
}

.button.light.outline:hover,
.button.light:hover,
.button.yellow.outline:hover,
.button.yellow:hover,
.command-button.light.outline:hover,
.command-button.light:hover,
.command-button.yellow.outline:hover,
.command-button.yellow:hover,
.image-button.light.outline:hover,
.image-button.light:hover,
.image-button.yellow.outline:hover,
.image-button.yellow:hover,
.info-button.light.outline:hover,
.info-button.light:hover,
.info-button.yellow.outline:hover,
.info-button.yellow:hover,
.input .button.light.outline:hover,
.input .button.light:hover,
.input .button.yellow.outline:hover,
.input .button.yellow:hover,
.notify.light.outline:hover,
.notify.light:hover,
.notify.yellow.outline:hover,
.notify.yellow:hover,
.select .button.light.outline:hover,
.select .button.light:hover,
.select .button.yellow.outline:hover,
.select .button.yellow:hover,
.shortcut.light.outline:hover,
.shortcut.light:hover,
.shortcut.yellow.outline:hover,
.shortcut.yellow:hover,
.spinner .button.light.outline:hover,
.spinner .button.light:hover,
.spinner .button.yellow.outline:hover,
.spinner .button.yellow:hover,
.split-button .split.light.outline:hover,
.split-button .split.light:hover,
.split-button .split.yellow.outline:hover,
.split-button .split.yellow:hover,
.tag-input .button.light.outline:hover,
.tag-input .button.light:hover,
.tag-input .button.yellow.outline:hover,
.tag-input .button.yellow:hover,
.tally.light.outline:hover,
.tally.light:hover,
.tally.yellow.outline:hover,
.tally.yellow:hover,
.toast.light.outline:hover,
.toast.light:hover,
.toast.yellow.outline:hover,
.toast.yellow:hover,
.tool-button.light.outline:hover,
.tool-button.light:hover,
.tool-button.yellow.outline:hover,
.tool-button.yellow:hover,
code.light.outline:hover,
code.light:hover,
code.yellow.outline:hover,
code.yellow:hover,
tbody td.light.outline:hover,
tbody td.light:hover,
tbody td.yellow.outline:hover,
tbody td.yellow:hover,
tbody tr.light.outline:hover,
tbody tr.light:hover,
tbody tr.yellow.outline:hover,
tbody tr.yellow:hover {
  color: #1d1d1d;
}

.action-button.primary {
  outline-color: #75b5fd;
  background-color: #0366d6;
  color: #fff;
}

.action-button.secondary {
  outline-color: #b7c6cd;
  background-color: #607d8b;
  color: #fff;
}

.action-button.success {
  outline-color: #adeb6e;
  background-color: #60a917;
  color: #fff;
}

.action-button.alert {
  outline-color: #ecaba7;
  background-color: #ce352c;
  color: #fff;
}

.action-button.warning {
  outline-color: #ffede1;
  background-color: #ff9447;
  color: #fff;
}

.action-button.yellow {
  outline-color: #fff;
  background-color: #ffe484;
  color: #fff;
}

.action-button.info {
  outline-color: #e6f5fc;
  background-color: #5ebdec;
  color: #fff;
}

.action-button.dark {
  outline-color: #9d9d9d;
  background-color: #505050;
  color: #fff;
}

.action-button.light {
  outline-color: #fff;
  background-color: #f8f8f8;
  color: #fff;
}

.action-button.light,
.action-button.yellow {
  color: #1d1d1d;
}

.action-button.light.outline:hover,
.action-button.light:hover,
.action-button.yellow.outline:hover,
.action-button.yellow:hover {
  color: #1d1d1d;
}

.pagination.primary .page-item.active,
.pagination.primary .page-item.service,
.pagination.primary .page-item:hover {
  background-color: #0366d6;
  border-color: #0366d6;
  color: #fff;
}

.pagination.primary .page-item.active:hover,
.pagination.primary .page-item.service:hover,
.pagination.primary .page-item:hover:hover {
  background-color: #024ea4;
}

.pagination.secondary .page-item.active,
.pagination.secondary .page-item.service,
.pagination.secondary .page-item:hover {
  background-color: #607d8b;
  border-color: #607d8b;
  color: #fff;
}

.pagination.secondary .page-item.active:hover,
.pagination.secondary .page-item.service:hover,
.pagination.secondary .page-item:hover:hover {
  background-color: #4b626d;
}

.pagination.success .page-item.active,
.pagination.success .page-item.service,
.pagination.success .page-item:hover {
  background-color: #60a917;
  border-color: #60a917;
  color: #fff;
}

.pagination.success .page-item.active:hover,
.pagination.success .page-item.service:hover,
.pagination.success .page-item:hover:hover {
  background-color: #477c11;
}

.pagination.alert .page-item.active,
.pagination.alert .page-item.service,
.pagination.alert .page-item:hover {
  background-color: #ce352c;
  border-color: #ce352c;
  color: #fff;
}

.pagination.alert .page-item.active:hover,
.pagination.alert .page-item.service:hover,
.pagination.alert .page-item:hover:hover {
  background-color: #a42a23;
}

.pagination.warning .page-item.active,
.pagination.warning .page-item.service,
.pagination.warning .page-item:hover {
  background-color: #ff9447;
  border-color: #ff9447;
  color: #fff;
}

.pagination.warning .page-item.active:hover,
.pagination.warning .page-item.service:hover,
.pagination.warning .page-item:hover:hover {
  background-color: #ff7615;
}

.pagination.yellow .page-item.active,
.pagination.yellow .page-item.service,
.pagination.yellow .page-item:hover {
  background-color: #ffe484;
  border-color: #ffe484;
  color: #fff;
}

.pagination.yellow .page-item.active:hover,
.pagination.yellow .page-item.service:hover,
.pagination.yellow .page-item:hover:hover {
  background-color: #ffd951;
}

.pagination.info .page-item.active,
.pagination.info .page-item.service,
.pagination.info .page-item:hover {
  background-color: #5ebdec;
  border-color: #5ebdec;
  color: #fff;
}

.pagination.info .page-item.active:hover,
.pagination.info .page-item.service:hover,
.pagination.info .page-item:hover:hover {
  background-color: #30abe6;
}

.pagination.dark .page-item.active,
.pagination.dark .page-item.service,
.pagination.dark .page-item:hover {
  background-color: #505050;
  border-color: #505050;
  color: #fff;
}

.pagination.dark .page-item.active:hover,
.pagination.dark .page-item.service:hover,
.pagination.dark .page-item:hover:hover {
  background-color: #373737;
}

.pagination.light .page-item.active,
.pagination.light .page-item.service,
.pagination.light .page-item:hover {
  background-color: #f8f8f8;
  border-color: #f8f8f8;
  color: #fff;
}

.pagination.light .page-item.active:hover,
.pagination.light .page-item.service:hover,
.pagination.light .page-item:hover:hover {
  background-color: #dfdfdf;
}

.pagination.light .page-item.active,
.pagination.light .page-item.service,
.pagination.light .page-item:hover,
.pagination.yellow .page-item.active,
.pagination.yellow .page-item.service,
.pagination.yellow .page-item:hover {
  color: #1d1d1d;
}

.remark.primary {
  border-left: 4px solid #0366d6;
}

.remark.secondary {
  border-left: 4px solid #607d8b;
}

.remark.success {
  border-left: 4px solid #60a917;
}

.remark.alert {
  border-left: 4px solid #ce352c;
}

.remark.warning {
  border-left: 4px solid #ff9447;
}

.remark.yellow {
  border-left: 4px solid #ffe484;
}

.remark.info {
  border-left: 4px solid #5ebdec;
}

.remark.dark {
  border-left: 4px solid #505050;
}

.remark.light {
  border-left: 4px solid #f8f8f8;
}

.file.primary,
.input.primary,
.keypad.primary,
.select.primary,
.spinner.primary,
.tag-input.primary,
.textarea.primary,
input[type="datetime-local"].primary,
input[type="email"].primary,
input[type="file"].primary,
input[type="month"].primary,
input[type="number"].primary,
input[type="password"].primary,
input[type="search"].primary,
input[type="tel"].primary,
input[type="text"].primary,
input[type="time"].primary,
input[type="url"].primary,
input[type="week"].primary,
select.primary,
textarea.primary {
  border-color: #0366d6;
}

.file.primary.focused,
.file.primary:focus,
.input.primary.focused,
.input.primary:focus,
.keypad.primary.focused,
.keypad.primary:focus,
.select.primary.focused,
.select.primary:focus,
.spinner.primary.focused,
.spinner.primary:focus,
.tag-input.primary.focused,
.tag-input.primary:focus,
.textarea.primary.focused,
.textarea.primary:focus,
input[type="datetime-local"].primary.focused,
input[type="datetime-local"].primary:focus,
input[type="email"].primary.focused,
input[type="email"].primary:focus,
input[type="file"].primary.focused,
input[type="file"].primary:focus,
input[type="month"].primary.focused,
input[type="month"].primary:focus,
input[type="number"].primary.focused,
input[type="number"].primary:focus,
input[type="password"].primary.focused,
input[type="password"].primary:focus,
input[type="search"].primary.focused,
input[type="search"].primary:focus,
input[type="tel"].primary.focused,
input[type="tel"].primary:focus,
input[type="text"].primary.focused,
input[type="text"].primary:focus,
input[type="time"].primary.focused,
input[type="time"].primary:focus,
input[type="url"].primary.focused,
input[type="url"].primary:focus,
input[type="week"].primary.focused,
input[type="week"].primary:focus,
select.primary.focused,
select.primary:focus,
textarea.primary.focused,
textarea.primary:focus {
  box-shadow: 0 0 0 3px rgba(3, 102, 214, 0.45);
}

.file.secondary,
.input.secondary,
.keypad.secondary,
.select.secondary,
.spinner.secondary,
.tag-input.secondary,
.textarea.secondary,
input[type="datetime-local"].secondary,
input[type="email"].secondary,
input[type="file"].secondary,
input[type="month"].secondary,
input[type="number"].secondary,
input[type="password"].secondary,
input[type="search"].secondary,
input[type="tel"].secondary,
input[type="text"].secondary,
input[type="time"].secondary,
input[type="url"].secondary,
input[type="week"].secondary,
select.secondary,
textarea.secondary {
  border-color: #607d8b;
}

.file.secondary.focused,
.file.secondary:focus,
.input.secondary.focused,
.input.secondary:focus,
.keypad.secondary.focused,
.keypad.secondary:focus,
.select.secondary.focused,
.select.secondary:focus,
.spinner.secondary.focused,
.spinner.secondary:focus,
.tag-input.secondary.focused,
.tag-input.secondary:focus,
.textarea.secondary.focused,
.textarea.secondary:focus,
input[type="datetime-local"].secondary.focused,
input[type="datetime-local"].secondary:focus,
input[type="email"].secondary.focused,
input[type="email"].secondary:focus,
input[type="file"].secondary.focused,
input[type="file"].secondary:focus,
input[type="month"].secondary.focused,
input[type="month"].secondary:focus,
input[type="number"].secondary.focused,
input[type="number"].secondary:focus,
input[type="password"].secondary.focused,
input[type="password"].secondary:focus,
input[type="search"].secondary.focused,
input[type="search"].secondary:focus,
input[type="tel"].secondary.focused,
input[type="tel"].secondary:focus,
input[type="text"].secondary.focused,
input[type="text"].secondary:focus,
input[type="time"].secondary.focused,
input[type="time"].secondary:focus,
input[type="url"].secondary.focused,
input[type="url"].secondary:focus,
input[type="week"].secondary.focused,
input[type="week"].secondary:focus,
select.secondary.focused,
select.secondary:focus,
textarea.secondary.focused,
textarea.secondary:focus {
  box-shadow: 0 0 0 3px rgba(96, 125, 139, 0.45);
}

.file.success,
.input.success,
.keypad.success,
.select.success,
.spinner.success,
.tag-input.success,
.textarea.success,
input[type="datetime-local"].success,
input[type="email"].success,
input[type="file"].success,
input[type="month"].success,
input[type="number"].success,
input[type="password"].success,
input[type="search"].success,
input[type="tel"].success,
input[type="text"].success,
input[type="time"].success,
input[type="url"].success,
input[type="week"].success,
select.success,
textarea.success {
  border-color: #60a917;
}

.file.success.focused,
.file.success:focus,
.input.success.focused,
.input.success:focus,
.keypad.success.focused,
.keypad.success:focus,
.select.success.focused,
.select.success:focus,
.spinner.success.focused,
.spinner.success:focus,
.tag-input.success.focused,
.tag-input.success:focus,
.textarea.success.focused,
.textarea.success:focus,
input[type="datetime-local"].success.focused,
input[type="datetime-local"].success:focus,
input[type="email"].success.focused,
input[type="email"].success:focus,
input[type="file"].success.focused,
input[type="file"].success:focus,
input[type="month"].success.focused,
input[type="month"].success:focus,
input[type="number"].success.focused,
input[type="number"].success:focus,
input[type="password"].success.focused,
input[type="password"].success:focus,
input[type="search"].success.focused,
input[type="search"].success:focus,
input[type="tel"].success.focused,
input[type="tel"].success:focus,
input[type="text"].success.focused,
input[type="text"].success:focus,
input[type="time"].success.focused,
input[type="time"].success:focus,
input[type="url"].success.focused,
input[type="url"].success:focus,
input[type="week"].success.focused,
input[type="week"].success:focus,
select.success.focused,
select.success:focus,
textarea.success.focused,
textarea.success:focus {
  box-shadow: 0 0 0 3px rgba(96, 169, 23, 0.45);
}

.file.alert,
.input.alert,
.keypad.alert,
.select.alert,
.spinner.alert,
.tag-input.alert,
.textarea.alert,
input[type="datetime-local"].alert,
input[type="email"].alert,
input[type="file"].alert,
input[type="month"].alert,
input[type="number"].alert,
input[type="password"].alert,
input[type="search"].alert,
input[type="tel"].alert,
input[type="text"].alert,
input[type="time"].alert,
input[type="url"].alert,
input[type="week"].alert,
select.alert,
textarea.alert {
  border-color: #ce352c;
}

.file.alert.focused,
.file.alert:focus,
.input.alert.focused,
.input.alert:focus,
.keypad.alert.focused,
.keypad.alert:focus,
.select.alert.focused,
.select.alert:focus,
.spinner.alert.focused,
.spinner.alert:focus,
.tag-input.alert.focused,
.tag-input.alert:focus,
.textarea.alert.focused,
.textarea.alert:focus,
input[type="datetime-local"].alert.focused,
input[type="datetime-local"].alert:focus,
input[type="email"].alert.focused,
input[type="email"].alert:focus,
input[type="file"].alert.focused,
input[type="file"].alert:focus,
input[type="month"].alert.focused,
input[type="month"].alert:focus,
input[type="number"].alert.focused,
input[type="number"].alert:focus,
input[type="password"].alert.focused,
input[type="password"].alert:focus,
input[type="search"].alert.focused,
input[type="search"].alert:focus,
input[type="tel"].alert.focused,
input[type="tel"].alert:focus,
input[type="text"].alert.focused,
input[type="text"].alert:focus,
input[type="time"].alert.focused,
input[type="time"].alert:focus,
input[type="url"].alert.focused,
input[type="url"].alert:focus,
input[type="week"].alert.focused,
input[type="week"].alert:focus,
select.alert.focused,
select.alert:focus,
textarea.alert.focused,
textarea.alert:focus {
  box-shadow: 0 0 0 3px rgba(206, 53, 44, 0.45);
}

.file.warning,
.input.warning,
.keypad.warning,
.select.warning,
.spinner.warning,
.tag-input.warning,
.textarea.warning,
input[type="datetime-local"].warning,
input[type="email"].warning,
input[type="file"].warning,
input[type="month"].warning,
input[type="number"].warning,
input[type="password"].warning,
input[type="search"].warning,
input[type="tel"].warning,
input[type="text"].warning,
input[type="time"].warning,
input[type="url"].warning,
input[type="week"].warning,
select.warning,
textarea.warning {
  border-color: #ff9447;
}

.file.warning.focused,
.file.warning:focus,
.input.warning.focused,
.input.warning:focus,
.keypad.warning.focused,
.keypad.warning:focus,
.select.warning.focused,
.select.warning:focus,
.spinner.warning.focused,
.spinner.warning:focus,
.tag-input.warning.focused,
.tag-input.warning:focus,
.textarea.warning.focused,
.textarea.warning:focus,
input[type="datetime-local"].warning.focused,
input[type="datetime-local"].warning:focus,
input[type="email"].warning.focused,
input[type="email"].warning:focus,
input[type="file"].warning.focused,
input[type="file"].warning:focus,
input[type="month"].warning.focused,
input[type="month"].warning:focus,
input[type="number"].warning.focused,
input[type="number"].warning:focus,
input[type="password"].warning.focused,
input[type="password"].warning:focus,
input[type="search"].warning.focused,
input[type="search"].warning:focus,
input[type="tel"].warning.focused,
input[type="tel"].warning:focus,
input[type="text"].warning.focused,
input[type="text"].warning:focus,
input[type="time"].warning.focused,
input[type="time"].warning:focus,
input[type="url"].warning.focused,
input[type="url"].warning:focus,
input[type="week"].warning.focused,
input[type="week"].warning:focus,
select.warning.focused,
select.warning:focus,
textarea.warning.focused,
textarea.warning:focus {
  box-shadow: 0 0 0 3px rgba(255, 148, 71, 0.45);
}

.file.yellow,
.input.yellow,
.keypad.yellow,
.select.yellow,
.spinner.yellow,
.tag-input.yellow,
.textarea.yellow,
input[type="datetime-local"].yellow,
input[type="email"].yellow,
input[type="file"].yellow,
input[type="month"].yellow,
input[type="number"].yellow,
input[type="password"].yellow,
input[type="search"].yellow,
input[type="tel"].yellow,
input[type="text"].yellow,
input[type="time"].yellow,
input[type="url"].yellow,
input[type="week"].yellow,
select.yellow,
textarea.yellow {
  border-color: #ffe484;
}

.file.yellow.focused,
.file.yellow:focus,
.input.yellow.focused,
.input.yellow:focus,
.keypad.yellow.focused,
.keypad.yellow:focus,
.select.yellow.focused,
.select.yellow:focus,
.spinner.yellow.focused,
.spinner.yellow:focus,
.tag-input.yellow.focused,
.tag-input.yellow:focus,
.textarea.yellow.focused,
.textarea.yellow:focus,
input[type="datetime-local"].yellow.focused,
input[type="datetime-local"].yellow:focus,
input[type="email"].yellow.focused,
input[type="email"].yellow:focus,
input[type="file"].yellow.focused,
input[type="file"].yellow:focus,
input[type="month"].yellow.focused,
input[type="month"].yellow:focus,
input[type="number"].yellow.focused,
input[type="number"].yellow:focus,
input[type="password"].yellow.focused,
input[type="password"].yellow:focus,
input[type="search"].yellow.focused,
input[type="search"].yellow:focus,
input[type="tel"].yellow.focused,
input[type="tel"].yellow:focus,
input[type="text"].yellow.focused,
input[type="text"].yellow:focus,
input[type="time"].yellow.focused,
input[type="time"].yellow:focus,
input[type="url"].yellow.focused,
input[type="url"].yellow:focus,
input[type="week"].yellow.focused,
input[type="week"].yellow:focus,
select.yellow.focused,
select.yellow:focus,
textarea.yellow.focused,
textarea.yellow:focus {
  box-shadow: 0 0 0 3px rgba(255, 228, 132, 0.45);
}

.file.info,
.input.info,
.keypad.info,
.select.info,
.spinner.info,
.tag-input.info,
.textarea.info,
input[type="datetime-local"].info,
input[type="email"].info,
input[type="file"].info,
input[type="month"].info,
input[type="number"].info,
input[type="password"].info,
input[type="search"].info,
input[type="tel"].info,
input[type="text"].info,
input[type="time"].info,
input[type="url"].info,
input[type="week"].info,
select.info,
textarea.info {
  border-color: #5ebdec;
}

.file.info.focused,
.file.info:focus,
.input.info.focused,
.input.info:focus,
.keypad.info.focused,
.keypad.info:focus,
.select.info.focused,
.select.info:focus,
.spinner.info.focused,
.spinner.info:focus,
.tag-input.info.focused,
.tag-input.info:focus,
.textarea.info.focused,
.textarea.info:focus,
input[type="datetime-local"].info.focused,
input[type="datetime-local"].info:focus,
input[type="email"].info.focused,
input[type="email"].info:focus,
input[type="file"].info.focused,
input[type="file"].info:focus,
input[type="month"].info.focused,
input[type="month"].info:focus,
input[type="number"].info.focused,
input[type="number"].info:focus,
input[type="password"].info.focused,
input[type="password"].info:focus,
input[type="search"].info.focused,
input[type="search"].info:focus,
input[type="tel"].info.focused,
input[type="tel"].info:focus,
input[type="text"].info.focused,
input[type="text"].info:focus,
input[type="time"].info.focused,
input[type="time"].info:focus,
input[type="url"].info.focused,
input[type="url"].info:focus,
input[type="week"].info.focused,
input[type="week"].info:focus,
select.info.focused,
select.info:focus,
textarea.info.focused,
textarea.info:focus {
  box-shadow: 0 0 0 3px rgba(94, 189, 236, 0.45);
}

.file.dark,
.input.dark,
.keypad.dark,
.select.dark,
.spinner.dark,
.tag-input.dark,
.textarea.dark,
input[type="datetime-local"].dark,
input[type="email"].dark,
input[type="file"].dark,
input[type="month"].dark,
input[type="number"].dark,
input[type="password"].dark,
input[type="search"].dark,
input[type="tel"].dark,
input[type="text"].dark,
input[type="time"].dark,
input[type="url"].dark,
input[type="week"].dark,
select.dark,
textarea.dark {
  border-color: #505050;
}

.file.dark.focused,
.file.dark:focus,
.input.dark.focused,
.input.dark:focus,
.keypad.dark.focused,
.keypad.dark:focus,
.select.dark.focused,
.select.dark:focus,
.spinner.dark.focused,
.spinner.dark:focus,
.tag-input.dark.focused,
.tag-input.dark:focus,
.textarea.dark.focused,
.textarea.dark:focus,
input[type="datetime-local"].dark.focused,
input[type="datetime-local"].dark:focus,
input[type="email"].dark.focused,
input[type="email"].dark:focus,
input[type="file"].dark.focused,
input[type="file"].dark:focus,
input[type="month"].dark.focused,
input[type="month"].dark:focus,
input[type="number"].dark.focused,
input[type="number"].dark:focus,
input[type="password"].dark.focused,
input[type="password"].dark:focus,
input[type="search"].dark.focused,
input[type="search"].dark:focus,
input[type="tel"].dark.focused,
input[type="tel"].dark:focus,
input[type="text"].dark.focused,
input[type="text"].dark:focus,
input[type="time"].dark.focused,
input[type="time"].dark:focus,
input[type="url"].dark.focused,
input[type="url"].dark:focus,
input[type="week"].dark.focused,
input[type="week"].dark:focus,
select.dark.focused,
select.dark:focus,
textarea.dark.focused,
textarea.dark:focus {
  box-shadow: 0 0 0 3px rgba(80, 80, 80, 0.45);
}

.file.light,
.input.light,
.keypad.light,
.select.light,
.spinner.light,
.tag-input.light,
.textarea.light,
input[type="datetime-local"].light,
input[type="email"].light,
input[type="file"].light,
input[type="month"].light,
input[type="number"].light,
input[type="password"].light,
input[type="search"].light,
input[type="tel"].light,
input[type="text"].light,
input[type="time"].light,
input[type="url"].light,
input[type="week"].light,
select.light,
textarea.light {
  border-color: #f8f8f8;
}

.file.light.focused,
.file.light:focus,
.input.light.focused,
.input.light:focus,
.keypad.light.focused,
.keypad.light:focus,
.select.light.focused,
.select.light:focus,
.spinner.light.focused,
.spinner.light:focus,
.tag-input.light.focused,
.tag-input.light:focus,
.textarea.light.focused,
.textarea.light:focus,
input[type="datetime-local"].light.focused,
input[type="datetime-local"].light:focus,
input[type="email"].light.focused,
input[type="email"].light:focus,
input[type="file"].light.focused,
input[type="file"].light:focus,
input[type="month"].light.focused,
input[type="month"].light:focus,
input[type="number"].light.focused,
input[type="number"].light:focus,
input[type="password"].light.focused,
input[type="password"].light:focus,
input[type="search"].light.focused,
input[type="search"].light:focus,
input[type="tel"].light.focused,
input[type="tel"].light:focus,
input[type="text"].light.focused,
input[type="text"].light:focus,
input[type="time"].light.focused,
input[type="time"].light:focus,
input[type="url"].light.focused,
input[type="url"].light:focus,
input[type="week"].light.focused,
input[type="week"].light:focus,
select.light.focused,
select.light:focus,
textarea.light.focused,
textarea.light:focus {
  box-shadow: 0 0 0 3px rgba(248, 248, 248, 0.45);
}

.accent-block.primary {
  background-color: #0366d6;
  color: #fff;
}

.accent-block.secondary {
  background-color: #607d8b;
  color: #fff;
}

.accent-block.success {
  background-color: #60a917;
  color: #fff;
}

.accent-block.alert {
  background-color: #ce352c;
  color: #fff;
}

.accent-block.warning {
  background-color: #ff9447;
  color: #fff;
}

.accent-block.yellow {
  background-color: #ffe484;
  color: #fff;
}

.accent-block.info {
  background-color: #5ebdec;
  color: #fff;
}

.accent-block.dark {
  background-color: #505050;
  color: #fff;
}

.accent-block.light {
  background-color: #f8f8f8;
  color: #fff;
}

.accent-block.light,
.accent-block.yellow {
  color: #1d1d1d;
}

.order-24 {
  order: 24;
}

.order-23 {
  order: 23;
}

.order-22 {
  order: 22;
}

.order-21 {
  order: 21;
}

.order-20 {
  order: 20;
}

.order-19 {
  order: 19;
}

.order-18 {
  order: 18;
}

.order-17 {
  order: 17;
}

.order-16 {
  order: 16;
}

.order-15 {
  order: 15;
}

.order-14 {
  order: 14;
}

.order-13 {
  order: 13;
}

.order-12 {
  order: 12;
}

.order-11 {
  order: 11;
}

.order-10 {
  order: 10;
}

.order-9 {
  order: 9;
}

.order-8 {
  order: 8;
}

.order-7 {
  order: 7;
}

.order-6 {
  order: 6;
}

.order-5 {
  order: 5;
}

.order-4 {
  order: 4;
}

.order-3 {
  order: 3;
}

.order-2 {
  order: 2;
}

.order-1 {
  order: 1;
}

.flex-equal-items > * {
  flex: 1;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-r {
  flex-wrap: wrap-reverse !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-r {
  flex-direction: column-reverse !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-r {
  flex-direction: row-reverse !important;
}

.flex-align-start {
  align-items: flex-start !important;
}

.flex-align-end {
  align-items: flex-end !important;
}

.flex-align-center {
  align-items: center !important;
}

.flex-align-stretch {
  align-items: stretch !important;
}

.flex-align-baseline {
  align-items: baseline !important;
}

.flex-self-start {
  align-self: flex-start !important;
}

.flex-self-end {
  align-self: flex-end !important;
}

.flex-self-center {
  align-self: center !important;
}

.flex-self-stretch {
  align-self: stretch !important;
}

.flex-self-baseline {
  align-self: baseline !important;
}

.flex-justify-start {
  justify-content: flex-start !important;
}

.flex-justify-end {
  justify-content: flex-end !important;
}

.flex-justify-center {
  justify-content: center !important;
}

.flex-justify-between {
  justify-content: space-between !important;
}

.flex-justify-around {
  justify-content: space-around !important;
}

.flex-content-start {
  align-content: flex-start !important;
}

.flex-content-end {
  align-content: flex-end !important;
}

.flex-content-center {
  align-content: center !important;
}

.flex-content-between {
  align-content: space-between !important;
}

.flex-content-around {
  align-content: space-around !important;
}

.flex-content-stretch {
  align-content: stretch !important;
}

.flex-no-shrink > * {
  flex-shrink: 0;
}

.flex-shrink > * {
  flex-shrink: 1;
}

.flex-no-grow > * {
  flex-grow: 0;
}

.flex-grow > * {
  flex-grow: 1;
}

.flex-no-shrink-self {
  flex-shrink: 0;
}

.flex-shrink-self {
  flex-shrink: 1;
}

.flex-no-grow-self {
  flex-grow: 0;
}

.flex-grow-self {
  flex-grow: 1;
}

.flex-right {
  margin-left: auto;
}

.flex-left {
  margin-right: auto;
}

.flex-top {
  margin-bottom: auto;
}

.flex-bottom {
  margin-top: auto;
}

@media screen and (min-width: 0) {
  .flex-nowrap-fs {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-fs {
    flex-wrap: wrap !important;
  }

  .flex-wrap-r-fs {
    flex-wrap: wrap-reverse !important;
  }

  .flex-column-fs {
    flex-direction: column !important;
  }

  .flex-column-r-fs {
    flex-direction: column-reverse !important;
  }

  .flex-row-fs {
    flex-direction: row !important;
  }

  .flex-row-r-fs {
    flex-direction: row-reverse !important;
  }

  .flex-align-start-fs {
    align-items: flex-start !important;
  }

  .flex-align-end-fs {
    align-items: flex-end !important;
  }

  .flex-align-center-fs {
    align-items: center !important;
  }

  .flex-align-stretch-fs {
    align-items: stretch !important;
  }

  .flex-align-baseline-fs {
    align-items: baseline !important;
  }

  .flex-self-start-fs {
    align-self: flex-start !important;
  }

  .flex-self-end-fs {
    align-self: flex-end !important;
  }

  .flex-self-center-fs {
    align-self: center !important;
  }

  .flex-self-stretch-fs {
    align-self: stretch !important;
  }

  .flex-self-baseline-fs {
    align-self: baseline !important;
  }

  .flex-justify-start-fs {
    justify-content: flex-start !important;
  }

  .flex-justify-end-fs {
    justify-content: flex-end !important;
  }

  .flex-justify-center-fs {
    justify-content: center !important;
  }

  .flex-justify-between-fs {
    justify-content: space-between !important;
  }

  .flex-justify-around-fs {
    justify-content: space-around !important;
  }

  .flex-content-start-fs {
    align-content: flex-start !important;
  }

  .flex-content-end-fs {
    align-content: flex-end !important;
  }

  .flex-content-center-fs {
    align-content: center !important;
  }

  .flex-content-between-fs {
    align-content: space-between !important;
  }

  .flex-content-around-fs {
    align-content: space-around !important;
  }

  .flex-content-stretch-fs {
    align-content: stretch !important;
  }

  .flex-no-shrink-fs > * {
    flex-shrink: 0;
  }

  .flex-shrink-fs > * {
    flex-shrink: 1;
  }

  .flex-no-grow-fs > * {
    flex-grow: 0;
  }

  .flex-grow-fs > * {
    flex-grow: 1;
  }

  .flex-no-shrink-self-fs {
    flex-shrink: 0;
  }

  .flex-shrink-self-fs {
    flex-shrink: 1;
  }

  .flex-no-grow-self-fs {
    flex-grow: 0;
  }

  .flex-grow-self-fs {
    flex-grow: 1;
  }

  .flex-right-fs {
    margin-left: auto;
  }

  .flex-left-fs {
    margin-right: auto;
  }

  .flex-top-fs {
    margin-bottom: auto;
  }

  .flex-bottom-fs {
    margin-top: auto;
  }

  .order-fs-24 {
    order: 24;
  }

  .order-fs-23 {
    order: 23;
  }

  .order-fs-22 {
    order: 22;
  }

  .order-fs-21 {
    order: 21;
  }

  .order-fs-20 {
    order: 20;
  }

  .order-fs-19 {
    order: 19;
  }

  .order-fs-18 {
    order: 18;
  }

  .order-fs-17 {
    order: 17;
  }

  .order-fs-16 {
    order: 16;
  }

  .order-fs-15 {
    order: 15;
  }

  .order-fs-14 {
    order: 14;
  }

  .order-fs-13 {
    order: 13;
  }

  .order-fs-12 {
    order: 12;
  }

  .order-fs-11 {
    order: 11;
  }

  .order-fs-10 {
    order: 10;
  }

  .order-fs-9 {
    order: 9;
  }

  .order-fs-8 {
    order: 8;
  }

  .order-fs-7 {
    order: 7;
  }

  .order-fs-6 {
    order: 6;
  }

  .order-fs-5 {
    order: 5;
  }

  .order-fs-4 {
    order: 4;
  }

  .order-fs-3 {
    order: 3;
  }

  .order-fs-2 {
    order: 2;
  }

  .order-fs-1 {
    order: 1;
  }
}

@media screen and (min-width: 576px) {
  .flex-nowrap-sm {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-sm {
    flex-wrap: wrap !important;
  }

  .flex-wrap-r-sm {
    flex-wrap: wrap-reverse !important;
  }

  .flex-column-sm {
    flex-direction: column !important;
  }

  .flex-column-r-sm {
    flex-direction: column-reverse !important;
  }

  .flex-row-sm {
    flex-direction: row !important;
  }

  .flex-row-r-sm {
    flex-direction: row-reverse !important;
  }

  .flex-align-start-sm {
    align-items: flex-start !important;
  }

  .flex-align-end-sm {
    align-items: flex-end !important;
  }

  .flex-align-center-sm {
    align-items: center !important;
  }

  .flex-align-stretch-sm {
    align-items: stretch !important;
  }

  .flex-align-baseline-sm {
    align-items: baseline !important;
  }

  .flex-self-start-sm {
    align-self: flex-start !important;
  }

  .flex-self-end-sm {
    align-self: flex-end !important;
  }

  .flex-self-center-sm {
    align-self: center !important;
  }

  .flex-self-stretch-sm {
    align-self: stretch !important;
  }

  .flex-self-baseline-sm {
    align-self: baseline !important;
  }

  .flex-justify-start-sm {
    justify-content: flex-start !important;
  }

  .flex-justify-end-sm {
    justify-content: flex-end !important;
  }

  .flex-justify-center-sm {
    justify-content: center !important;
  }

  .flex-justify-between-sm {
    justify-content: space-between !important;
  }

  .flex-justify-around-sm {
    justify-content: space-around !important;
  }

  .flex-content-start-sm {
    align-content: flex-start !important;
  }

  .flex-content-end-sm {
    align-content: flex-end !important;
  }

  .flex-content-center-sm {
    align-content: center !important;
  }

  .flex-content-between-sm {
    align-content: space-between !important;
  }

  .flex-content-around-sm {
    align-content: space-around !important;
  }

  .flex-content-stretch-sm {
    align-content: stretch !important;
  }

  .flex-no-shrink-sm > * {
    flex-shrink: 0;
  }

  .flex-shrink-sm > * {
    flex-shrink: 1;
  }

  .flex-no-grow-sm > * {
    flex-grow: 0;
  }

  .flex-grow-sm > * {
    flex-grow: 1;
  }

  .flex-no-shrink-self-sm {
    flex-shrink: 0;
  }

  .flex-shrink-self-sm {
    flex-shrink: 1;
  }

  .flex-no-grow-self-sm {
    flex-grow: 0;
  }

  .flex-grow-self-sm {
    flex-grow: 1;
  }

  .flex-right-sm {
    margin-left: auto;
  }

  .flex-left-sm {
    margin-right: auto;
  }

  .flex-top-sm {
    margin-bottom: auto;
  }

  .flex-bottom-sm {
    margin-top: auto;
  }

  .order-sm-24 {
    order: 24;
  }

  .order-sm-23 {
    order: 23;
  }

  .order-sm-22 {
    order: 22;
  }

  .order-sm-21 {
    order: 21;
  }

  .order-sm-20 {
    order: 20;
  }

  .order-sm-19 {
    order: 19;
  }

  .order-sm-18 {
    order: 18;
  }

  .order-sm-17 {
    order: 17;
  }

  .order-sm-16 {
    order: 16;
  }

  .order-sm-15 {
    order: 15;
  }

  .order-sm-14 {
    order: 14;
  }

  .order-sm-13 {
    order: 13;
  }

  .order-sm-12 {
    order: 12;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-1 {
    order: 1;
  }
}

@media screen and (min-width: 768px) {
  .flex-nowrap-md {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-md {
    flex-wrap: wrap !important;
  }

  .flex-wrap-r-md {
    flex-wrap: wrap-reverse !important;
  }

  .flex-column-md {
    flex-direction: column !important;
  }

  .flex-column-r-md {
    flex-direction: column-reverse !important;
  }

  .flex-row-md {
    flex-direction: row !important;
  }

  .flex-row-r-md {
    flex-direction: row-reverse !important;
  }

  .flex-align-start-md {
    align-items: flex-start !important;
  }

  .flex-align-end-md {
    align-items: flex-end !important;
  }

  .flex-align-center-md {
    align-items: center !important;
  }

  .flex-align-stretch-md {
    align-items: stretch !important;
  }

  .flex-align-baseline-md {
    align-items: baseline !important;
  }

  .flex-self-start-md {
    align-self: flex-start !important;
  }

  .flex-self-end-md {
    align-self: flex-end !important;
  }

  .flex-self-center-md {
    align-self: center !important;
  }

  .flex-self-stretch-md {
    align-self: stretch !important;
  }

  .flex-self-baseline-md {
    align-self: baseline !important;
  }

  .flex-justify-start-md {
    justify-content: flex-start !important;
  }

  .flex-justify-end-md {
    justify-content: flex-end !important;
  }

  .flex-justify-center-md {
    justify-content: center !important;
  }

  .flex-justify-between-md {
    justify-content: space-between !important;
  }

  .flex-justify-around-md {
    justify-content: space-around !important;
  }

  .flex-content-start-md {
    align-content: flex-start !important;
  }

  .flex-content-end-md {
    align-content: flex-end !important;
  }

  .flex-content-center-md {
    align-content: center !important;
  }

  .flex-content-between-md {
    align-content: space-between !important;
  }

  .flex-content-around-md {
    align-content: space-around !important;
  }

  .flex-content-stretch-md {
    align-content: stretch !important;
  }

  .flex-no-shrink-md > * {
    flex-shrink: 0;
  }

  .flex-shrink-md > * {
    flex-shrink: 1;
  }

  .flex-no-grow-md > * {
    flex-grow: 0;
  }

  .flex-grow-md > * {
    flex-grow: 1;
  }

  .flex-no-shrink-self-md {
    flex-shrink: 0;
  }

  .flex-shrink-self-md {
    flex-shrink: 1;
  }

  .flex-no-grow-self-md {
    flex-grow: 0;
  }

  .flex-grow-self-md {
    flex-grow: 1;
  }

  .flex-right-md {
    margin-left: auto;
  }

  .flex-left-md {
    margin-right: auto;
  }

  .flex-top-md {
    margin-bottom: auto;
  }

  .flex-bottom-md {
    margin-top: auto;
  }

  .order-md-24 {
    order: 24;
  }

  .order-md-23 {
    order: 23;
  }

  .order-md-22 {
    order: 22;
  }

  .order-md-21 {
    order: 21;
  }

  .order-md-20 {
    order: 20;
  }

  .order-md-19 {
    order: 19;
  }

  .order-md-18 {
    order: 18;
  }

  .order-md-17 {
    order: 17;
  }

  .order-md-16 {
    order: 16;
  }

  .order-md-15 {
    order: 15;
  }

  .order-md-14 {
    order: 14;
  }

  .order-md-13 {
    order: 13;
  }

  .order-md-12 {
    order: 12;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-1 {
    order: 1;
  }
}

@media screen and (min-width: 992px) {
  .flex-nowrap-lg {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-lg {
    flex-wrap: wrap !important;
  }

  .flex-wrap-r-lg {
    flex-wrap: wrap-reverse !important;
  }

  .flex-column-lg {
    flex-direction: column !important;
  }

  .flex-column-r-lg {
    flex-direction: column-reverse !important;
  }

  .flex-row-lg {
    flex-direction: row !important;
  }

  .flex-row-r-lg {
    flex-direction: row-reverse !important;
  }

  .flex-align-start-lg {
    align-items: flex-start !important;
  }

  .flex-align-end-lg {
    align-items: flex-end !important;
  }

  .flex-align-center-lg {
    align-items: center !important;
  }

  .flex-align-stretch-lg {
    align-items: stretch !important;
  }

  .flex-align-baseline-lg {
    align-items: baseline !important;
  }

  .flex-self-start-lg {
    align-self: flex-start !important;
  }

  .flex-self-end-lg {
    align-self: flex-end !important;
  }

  .flex-self-center-lg {
    align-self: center !important;
  }

  .flex-self-stretch-lg {
    align-self: stretch !important;
  }

  .flex-self-baseline-lg {
    align-self: baseline !important;
  }

  .flex-justify-start-lg {
    justify-content: flex-start !important;
  }

  .flex-justify-end-lg {
    justify-content: flex-end !important;
  }

  .flex-justify-center-lg {
    justify-content: center !important;
  }

  .flex-justify-between-lg {
    justify-content: space-between !important;
  }

  .flex-justify-around-lg {
    justify-content: space-around !important;
  }

  .flex-content-start-lg {
    align-content: flex-start !important;
  }

  .flex-content-end-lg {
    align-content: flex-end !important;
  }

  .flex-content-center-lg {
    align-content: center !important;
  }

  .flex-content-between-lg {
    align-content: space-between !important;
  }

  .flex-content-around-lg {
    align-content: space-around !important;
  }

  .flex-content-stretch-lg {
    align-content: stretch !important;
  }

  .flex-no-shrink-lg > * {
    flex-shrink: 0;
  }

  .flex-shrink-lg > * {
    flex-shrink: 1;
  }

  .flex-no-grow-lg > * {
    flex-grow: 0;
  }

  .flex-grow-lg > * {
    flex-grow: 1;
  }

  .flex-no-shrink-self-lg {
    flex-shrink: 0;
  }

  .flex-shrink-self-lg {
    flex-shrink: 1;
  }

  .flex-no-grow-self-lg {
    flex-grow: 0;
  }

  .flex-grow-self-lg {
    flex-grow: 1;
  }

  .flex-right-lg {
    margin-left: auto;
  }

  .flex-left-lg {
    margin-right: auto;
  }

  .flex-top-lg {
    margin-bottom: auto;
  }

  .flex-bottom-lg {
    margin-top: auto;
  }

  .order-lg-24 {
    order: 24;
  }

  .order-lg-23 {
    order: 23;
  }

  .order-lg-22 {
    order: 22;
  }

  .order-lg-21 {
    order: 21;
  }

  .order-lg-20 {
    order: 20;
  }

  .order-lg-19 {
    order: 19;
  }

  .order-lg-18 {
    order: 18;
  }

  .order-lg-17 {
    order: 17;
  }

  .order-lg-16 {
    order: 16;
  }

  .order-lg-15 {
    order: 15;
  }

  .order-lg-14 {
    order: 14;
  }

  .order-lg-13 {
    order: 13;
  }

  .order-lg-12 {
    order: 12;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-1 {
    order: 1;
  }
}

@media screen and (min-width: 1200px) {
  .flex-nowrap-xl {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-xl {
    flex-wrap: wrap !important;
  }

  .flex-wrap-r-xl {
    flex-wrap: wrap-reverse !important;
  }

  .flex-column-xl {
    flex-direction: column !important;
  }

  .flex-column-r-xl {
    flex-direction: column-reverse !important;
  }

  .flex-row-xl {
    flex-direction: row !important;
  }

  .flex-row-r-xl {
    flex-direction: row-reverse !important;
  }

  .flex-align-start-xl {
    align-items: flex-start !important;
  }

  .flex-align-end-xl {
    align-items: flex-end !important;
  }

  .flex-align-center-xl {
    align-items: center !important;
  }

  .flex-align-stretch-xl {
    align-items: stretch !important;
  }

  .flex-align-baseline-xl {
    align-items: baseline !important;
  }

  .flex-self-start-xl {
    align-self: flex-start !important;
  }

  .flex-self-end-xl {
    align-self: flex-end !important;
  }

  .flex-self-center-xl {
    align-self: center !important;
  }

  .flex-self-stretch-xl {
    align-self: stretch !important;
  }

  .flex-self-baseline-xl {
    align-self: baseline !important;
  }

  .flex-justify-start-xl {
    justify-content: flex-start !important;
  }

  .flex-justify-end-xl {
    justify-content: flex-end !important;
  }

  .flex-justify-center-xl {
    justify-content: center !important;
  }

  .flex-justify-between-xl {
    justify-content: space-between !important;
  }

  .flex-justify-around-xl {
    justify-content: space-around !important;
  }

  .flex-content-start-xl {
    align-content: flex-start !important;
  }

  .flex-content-end-xl {
    align-content: flex-end !important;
  }

  .flex-content-center-xl {
    align-content: center !important;
  }

  .flex-content-between-xl {
    align-content: space-between !important;
  }

  .flex-content-around-xl {
    align-content: space-around !important;
  }

  .flex-content-stretch-xl {
    align-content: stretch !important;
  }

  .flex-no-shrink-xl > * {
    flex-shrink: 0;
  }

  .flex-shrink-xl > * {
    flex-shrink: 1;
  }

  .flex-no-grow-xl > * {
    flex-grow: 0;
  }

  .flex-grow-xl > * {
    flex-grow: 1;
  }

  .flex-no-shrink-self-xl {
    flex-shrink: 0;
  }

  .flex-shrink-self-xl {
    flex-shrink: 1;
  }

  .flex-no-grow-self-xl {
    flex-grow: 0;
  }

  .flex-grow-self-xl {
    flex-grow: 1;
  }

  .flex-right-xl {
    margin-left: auto;
  }

  .flex-left-xl {
    margin-right: auto;
  }

  .flex-top-xl {
    margin-bottom: auto;
  }

  .flex-bottom-xl {
    margin-top: auto;
  }

  .order-xl-24 {
    order: 24;
  }

  .order-xl-23 {
    order: 23;
  }

  .order-xl-22 {
    order: 22;
  }

  .order-xl-21 {
    order: 21;
  }

  .order-xl-20 {
    order: 20;
  }

  .order-xl-19 {
    order: 19;
  }

  .order-xl-18 {
    order: 18;
  }

  .order-xl-17 {
    order: 17;
  }

  .order-xl-16 {
    order: 16;
  }

  .order-xl-15 {
    order: 15;
  }

  .order-xl-14 {
    order: 14;
  }

  .order-xl-13 {
    order: 13;
  }

  .order-xl-12 {
    order: 12;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-1 {
    order: 1;
  }
}

@media screen and (min-width: 1452px) {
  .flex-nowrap-xxl {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-xxl {
    flex-wrap: wrap !important;
  }

  .flex-wrap-r-xxl {
    flex-wrap: wrap-reverse !important;
  }

  .flex-column-xxl {
    flex-direction: column !important;
  }

  .flex-column-r-xxl {
    flex-direction: column-reverse !important;
  }

  .flex-row-xxl {
    flex-direction: row !important;
  }

  .flex-row-r-xxl {
    flex-direction: row-reverse !important;
  }

  .flex-align-start-xxl {
    align-items: flex-start !important;
  }

  .flex-align-end-xxl {
    align-items: flex-end !important;
  }

  .flex-align-center-xxl {
    align-items: center !important;
  }

  .flex-align-stretch-xxl {
    align-items: stretch !important;
  }

  .flex-align-baseline-xxl {
    align-items: baseline !important;
  }

  .flex-self-start-xxl {
    align-self: flex-start !important;
  }

  .flex-self-end-xxl {
    align-self: flex-end !important;
  }

  .flex-self-center-xxl {
    align-self: center !important;
  }

  .flex-self-stretch-xxl {
    align-self: stretch !important;
  }

  .flex-self-baseline-xxl {
    align-self: baseline !important;
  }

  .flex-justify-start-xxl {
    justify-content: flex-start !important;
  }

  .flex-justify-end-xxl {
    justify-content: flex-end !important;
  }

  .flex-justify-center-xxl {
    justify-content: center !important;
  }

  .flex-justify-between-xxl {
    justify-content: space-between !important;
  }

  .flex-justify-around-xxl {
    justify-content: space-around !important;
  }

  .flex-content-start-xxl {
    align-content: flex-start !important;
  }

  .flex-content-end-xxl {
    align-content: flex-end !important;
  }

  .flex-content-center-xxl {
    align-content: center !important;
  }

  .flex-content-between-xxl {
    align-content: space-between !important;
  }

  .flex-content-around-xxl {
    align-content: space-around !important;
  }

  .flex-content-stretch-xxl {
    align-content: stretch !important;
  }

  .flex-no-shrink-xxl > * {
    flex-shrink: 0;
  }

  .flex-shrink-xxl > * {
    flex-shrink: 1;
  }

  .flex-no-grow-xxl > * {
    flex-grow: 0;
  }

  .flex-grow-xxl > * {
    flex-grow: 1;
  }

  .flex-no-shrink-self-xxl {
    flex-shrink: 0;
  }

  .flex-shrink-self-xxl {
    flex-shrink: 1;
  }

  .flex-no-grow-self-xxl {
    flex-grow: 0;
  }

  .flex-grow-self-xxl {
    flex-grow: 1;
  }

  .flex-right-xxl {
    margin-left: auto;
  }

  .flex-left-xxl {
    margin-right: auto;
  }

  .flex-top-xxl {
    margin-bottom: auto;
  }

  .flex-bottom-xxl {
    margin-top: auto;
  }

  .order-xxl-24 {
    order: 24;
  }

  .order-xxl-23 {
    order: 23;
  }

  .order-xxl-22 {
    order: 22;
  }

  .order-xxl-21 {
    order: 21;
  }

  .order-xxl-20 {
    order: 20;
  }

  .order-xxl-19 {
    order: 19;
  }

  .order-xxl-18 {
    order: 18;
  }

  .order-xxl-17 {
    order: 17;
  }

  .order-xxl-16 {
    order: 16;
  }

  .order-xxl-15 {
    order: 15;
  }

  .order-xxl-14 {
    order: 14;
  }

  .order-xxl-13 {
    order: 13;
  }

  .order-xxl-12 {
    order: 12;
  }

  .order-xxl-11 {
    order: 11;
  }

  .order-xxl-10 {
    order: 10;
  }

  .order-xxl-9 {
    order: 9;
  }

  .order-xxl-8 {
    order: 8;
  }

  .order-xxl-7 {
    order: 7;
  }

  .order-xxl-6 {
    order: 6;
  }

  .order-xxl-5 {
    order: 5;
  }

  .order-xxl-4 {
    order: 4;
  }

  .order-xxl-3 {
    order: 3;
  }

  .order-xxl-2 {
    order: 2;
  }

  .order-xxl-1 {
    order: 1;
  }
}

.grid {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}

.cell {
  flex: 1 0 0;
  max-width: 100%;
  padding: 0 12px;
}

.cell-1,
.cell-10,
.cell-11,
.cell-12,
.cell-2,
.cell-3,
.cell-4,
.cell-5,
.cell-6,
.cell-7,
.cell-8,
.cell-9,
.cell-ld,
.cell-ld-1,
.cell-ld-10,
.cell-ld-11,
.cell-ld-12,
.cell-ld-2,
.cell-ld-3,
.cell-ld-4,
.cell-ld-5,
.cell-ld-6,
.cell-ld-7,
.cell-ld-8,
.cell-ld-9,
.cell-ld-auto,
.cell-ld-full,
.cell-ld-half,
.cell-ld-one-third,
.cell-ld-quarter,
.cell-ld-two-third,
.cell-lg,
.cell-lg-1,
.cell-lg-10,
.cell-lg-11,
.cell-lg-12,
.cell-lg-2,
.cell-lg-3,
.cell-lg-4,
.cell-lg-5,
.cell-lg-6,
.cell-lg-7,
.cell-lg-8,
.cell-lg-9,
.cell-lg-auto,
.cell-lg-full,
.cell-lg-half,
.cell-lg-one-third,
.cell-lg-quarter,
.cell-lg-two-third,
.cell-md,
.cell-md-1,
.cell-md-10,
.cell-md-11,
.cell-md-12,
.cell-md-2,
.cell-md-3,
.cell-md-4,
.cell-md-5,
.cell-md-6,
.cell-md-7,
.cell-md-8,
.cell-md-9,
.cell-md-auto,
.cell-md-full,
.cell-md-half,
.cell-md-one-third,
.cell-md-quarter,
.cell-md-two-third,
.cell-sm,
.cell-sm-1,
.cell-sm-10,
.cell-sm-11,
.cell-sm-12,
.cell-sm-2,
.cell-sm-3,
.cell-sm-4,
.cell-sm-5,
.cell-sm-6,
.cell-sm-7,
.cell-sm-8,
.cell-sm-9,
.cell-sm-auto,
.cell-sm-full,
.cell-sm-half,
.cell-sm-one-third,
.cell-sm-quarter,
.cell-sm-two-third,
.cell-sx,
.cell-sx-1,
.cell-sx-10,
.cell-sx-11,
.cell-sx-12,
.cell-sx-2,
.cell-sx-3,
.cell-sx-4,
.cell-sx-5,
.cell-sx-6,
.cell-sx-7,
.cell-sx-8,
.cell-sx-9,
.cell-sx-auto,
.cell-sx-full,
.cell-sx-half,
.cell-sx-one-third,
.cell-sx-quarter,
.cell-sx-two-third,
.cell-xl,
.cell-xl-1,
.cell-xl-10,
.cell-xl-11,
.cell-xl-12,
.cell-xl-2,
.cell-xl-3,
.cell-xl-4,
.cell-xl-5,
.cell-xl-6,
.cell-xl-7,
.cell-xl-8,
.cell-xl-9,
.cell-xl-auto,
.cell-xl-full,
.cell-xl-half,
.cell-xl-one-third,
.cell-xl-quarter,
.cell-xl-two-third,
.cell-xxl,
.cell-xxl-1,
.cell-xxl-10,
.cell-xxl-11,
.cell-xxl-12,
.cell-xxl-2,
.cell-xxl-3,
.cell-xxl-4,
.cell-xxl-5,
.cell-xxl-6,
.cell-xxl-7,
.cell-xxl-8,
.cell-xxl-9,
.cell-xxl-auto,
.cell-xxl-full,
.cell-xxl-half,
.cell-xxl-one-third,
.cell-xxl-quarter,
.cell-xxl-two-third {
  position: relative;
  display: block;
  padding: 0 12px;
  width: 100%;
  min-height: 1px;
}

.cell-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.cell-2,
.colspan-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.cell-3,
.colspan-3 {
  flex: 0 0 25.000005%;
  max-width: 25.000005%;
}

.cell-4,
.colspan-4 {
  flex: 0 0 33.33334%;
  max-width: 33.33334%;
}

.cell-5,
.colspan-5 {
  flex: 0 0 41.666675%;
  max-width: 41.666675%;
}

.cell-6,
.colspan-6 {
  flex: 0 0 50.00001%;
  max-width: 50.00001%;
}

.cell-7,
.colspan-7 {
  flex: 0 0 58.333345%;
  max-width: 58.333345%;
}

.cell-8,
.colspan-8 {
  flex: 0 0 66.66668%;
  max-width: 66.66668%;
}

.cell-9,
.colspan-9 {
  flex: 0 0 75.000015%;
  max-width: 75.000015%;
}

.cell-10,
.colspan-10 {
  flex: 0 0 83.33335%;
  max-width: 83.33335%;
}

.cell-11,
.colspan-11 {
  flex: 0 0 91.666685%;
  max-width: 91.666685%;
}

.cell-12,
.colspan-12 {
  flex: 0 0 100.00002%;
  max-width: 100.00002%;
}

.offset-12 {
  margin-left: 100.00002%;
}

.offset-11 {
  margin-left: 91.666685%;
}

.offset-10 {
  margin-left: 83.33335%;
}

.offset-9 {
  margin-left: 75.000015%;
}

.offset-8 {
  margin-left: 66.66668%;
}

.offset-7 {
  margin-left: 58.333345%;
}

.offset-6 {
  margin-left: 50.00001%;
}

.offset-5 {
  margin-left: 41.666675%;
}

.offset-4 {
  margin-left: 33.33334%;
}

.offset-3 {
  margin-left: 25.000005%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-1 {
  margin-left: 8.333335%;
}

.stub {
  flex: none;
  display: block;
  padding: 0 12px;
}

.grid.no-gap .row {
  margin-left: 0;
  margin-right: 0;
}

.grid.no-gap .row .cell,
.grid.no-gap .row .stub {
  padding: 0;
}

.grid.no-gap .row + .row {
  margin-top: 0;
}

.row.no-gap {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}

.row.no-gap .cell,
.row.no-gap .stub {
  padding: 0;
}

@media screen and (min-width: 0) {
  .cell-fs-full {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .cell-fs-half {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .cell-fs-one-third {
    flex: 0 0 33.3333333%;
    max-width: 33.3333333%;
  }

  .cell-fs-two-third {
    flex: 0 0 66.6666666%;
    max-width: 66.6666666%;
  }

  .cell-fs-quarter {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .cell-fs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .cell-fs-auto {
    flex: 0 0 auto;
    max-width: none;
  }

  .cell-fs-1,
  .colspan-fs-1 {
    flex: 0 0 8.333335%;
    max-width: 8.333335%;
  }

  .cell-fs-2,
  .colspan-fs-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .cell-fs-3,
  .colspan-fs-3 {
    flex: 0 0 25.000005%;
    max-width: 25.000005%;
  }

  .cell-fs-4,
  .colspan-fs-4 {
    flex: 0 0 33.33334%;
    max-width: 33.33334%;
  }

  .cell-fs-5,
  .colspan-fs-5 {
    flex: 0 0 41.666675%;
    max-width: 41.666675%;
  }

  .cell-fs-6,
  .colspan-fs-6 {
    flex: 0 0 50.00001%;
    max-width: 50.00001%;
  }

  .cell-fs-7,
  .colspan-fs-7 {
    flex: 0 0 58.333345%;
    max-width: 58.333345%;
  }

  .cell-fs-8,
  .colspan-fs-8 {
    flex: 0 0 66.66668%;
    max-width: 66.66668%;
  }

  .cell-fs-9,
  .colspan-fs-9 {
    flex: 0 0 75.000015%;
    max-width: 75.000015%;
  }

  .cell-fs-10,
  .colspan-fs-10 {
    flex: 0 0 83.33335%;
    max-width: 83.33335%;
  }

  .cell-fs-11,
  .colspan-fs-11 {
    flex: 0 0 91.666685%;
    max-width: 91.666685%;
  }

  .cell-fs-12,
  .colspan-fs-12 {
    flex: 0 0 100.00002%;
    max-width: 100.00002%;
  }

  .offset-fs-12 {
    margin-left: 100.00002%;
  }

  .offset-fs-11 {
    margin-left: 91.666685%;
  }

  .offset-fs-10 {
    margin-left: 83.33335%;
  }

  .offset-fs-9 {
    margin-left: 75.000015%;
  }

  .offset-fs-8 {
    margin-left: 66.66668%;
  }

  .offset-fs-7 {
    margin-left: 58.333345%;
  }

  .offset-fs-6 {
    margin-left: 50.00001%;
  }

  .offset-fs-5 {
    margin-left: 41.666675%;
  }

  .offset-fs-4 {
    margin-left: 33.33334%;
  }

  .offset-fs-3 {
    margin-left: 25.000005%;
  }

  .offset-fs-2 {
    margin-left: 16.66667%;
  }

  .offset-fs-1 {
    margin-left: 8.333335%;
  }
}

@media screen and (min-width: 576px) {
  .cell-sm-full {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .cell-sm-half {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .cell-sm-one-third {
    flex: 0 0 33.3333333%;
    max-width: 33.3333333%;
  }

  .cell-sm-two-third {
    flex: 0 0 66.6666666%;
    max-width: 66.6666666%;
  }

  .cell-sm-quarter {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .cell-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .cell-sm-auto {
    flex: 0 0 auto;
    max-width: none;
  }

  .cell-sm-1,
  .colspan-sm-1 {
    flex: 0 0 8.333335%;
    max-width: 8.333335%;
  }

  .cell-sm-2,
  .colspan-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .cell-sm-3,
  .colspan-sm-3 {
    flex: 0 0 25.000005%;
    max-width: 25.000005%;
  }

  .cell-sm-4,
  .colspan-sm-4 {
    flex: 0 0 33.33334%;
    max-width: 33.33334%;
  }

  .cell-sm-5,
  .colspan-sm-5 {
    flex: 0 0 41.666675%;
    max-width: 41.666675%;
  }

  .cell-sm-6,
  .colspan-sm-6 {
    flex: 0 0 50.00001%;
    max-width: 50.00001%;
  }

  .cell-sm-7,
  .colspan-sm-7 {
    flex: 0 0 58.333345%;
    max-width: 58.333345%;
  }

  .cell-sm-8,
  .colspan-sm-8 {
    flex: 0 0 66.66668%;
    max-width: 66.66668%;
  }

  .cell-sm-9,
  .colspan-sm-9 {
    flex: 0 0 75.000015%;
    max-width: 75.000015%;
  }

  .cell-sm-10,
  .colspan-sm-10 {
    flex: 0 0 83.33335%;
    max-width: 83.33335%;
  }

  .cell-sm-11,
  .colspan-sm-11 {
    flex: 0 0 91.666685%;
    max-width: 91.666685%;
  }

  .cell-sm-12,
  .colspan-sm-12 {
    flex: 0 0 100.00002%;
    max-width: 100.00002%;
  }

  .offset-sm-12 {
    margin-left: 100.00002%;
  }

  .offset-sm-11 {
    margin-left: 91.666685%;
  }

  .offset-sm-10 {
    margin-left: 83.33335%;
  }

  .offset-sm-9 {
    margin-left: 75.000015%;
  }

  .offset-sm-8 {
    margin-left: 66.66668%;
  }

  .offset-sm-7 {
    margin-left: 58.333345%;
  }

  .offset-sm-6 {
    margin-left: 50.00001%;
  }

  .offset-sm-5 {
    margin-left: 41.666675%;
  }

  .offset-sm-4 {
    margin-left: 33.33334%;
  }

  .offset-sm-3 {
    margin-left: 25.000005%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-1 {
    margin-left: 8.333335%;
  }
}

@media screen and (min-width: 768px) {
  .cell-md-full {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .cell-md-half {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .cell-md-one-third {
    flex: 0 0 33.3333333%;
    max-width: 33.3333333%;
  }

  .cell-md-two-third {
    flex: 0 0 66.6666666%;
    max-width: 66.6666666%;
  }

  .cell-md-quarter {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .cell-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .cell-md-auto {
    flex: 0 0 auto;
    max-width: none;
  }

  .cell-md-1,
  .colspan-md-1 {
    flex: 0 0 8.333335%;
    max-width: 8.333335%;
  }

  .cell-md-2,
  .colspan-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .cell-md-3,
  .colspan-md-3 {
    flex: 0 0 25.000005%;
    max-width: 25.000005%;
  }

  .cell-md-4,
  .colspan-md-4 {
    flex: 0 0 33.33334%;
    max-width: 33.33334%;
  }

  .cell-md-5,
  .colspan-md-5 {
    flex: 0 0 41.666675%;
    max-width: 41.666675%;
  }

  .cell-md-6,
  .colspan-md-6 {
    flex: 0 0 50.00001%;
    max-width: 50.00001%;
  }

  .cell-md-7,
  .colspan-md-7 {
    flex: 0 0 58.333345%;
    max-width: 58.333345%;
  }

  .cell-md-8,
  .colspan-md-8 {
    flex: 0 0 66.66668%;
    max-width: 66.66668%;
  }

  .cell-md-9,
  .colspan-md-9 {
    flex: 0 0 75.000015%;
    max-width: 75.000015%;
  }

  .cell-md-10,
  .colspan-md-10 {
    flex: 0 0 83.33335%;
    max-width: 83.33335%;
  }

  .cell-md-11,
  .colspan-md-11 {
    flex: 0 0 91.666685%;
    max-width: 91.666685%;
  }

  .cell-md-12,
  .colspan-md-12 {
    flex: 0 0 100.00002%;
    max-width: 100.00002%;
  }

  .offset-md-12 {
    margin-left: 100.00002%;
  }

  .offset-md-11 {
    margin-left: 91.666685%;
  }

  .offset-md-10 {
    margin-left: 83.33335%;
  }

  .offset-md-9 {
    margin-left: 75.000015%;
  }

  .offset-md-8 {
    margin-left: 66.66668%;
  }

  .offset-md-7 {
    margin-left: 58.333345%;
  }

  .offset-md-6 {
    margin-left: 50.00001%;
  }

  .offset-md-5 {
    margin-left: 41.666675%;
  }

  .offset-md-4 {
    margin-left: 33.33334%;
  }

  .offset-md-3 {
    margin-left: 25.000005%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-1 {
    margin-left: 8.333335%;
  }
}

@media screen and (min-width: 992px) {
  .cell-lg-full {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .cell-lg-half {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .cell-lg-one-third {
    flex: 0 0 33.3333333%;
    max-width: 33.3333333%;
  }

  .cell-lg-two-third {
    flex: 0 0 66.6666666%;
    max-width: 66.6666666%;
  }

  .cell-lg-quarter {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .cell-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .cell-lg-auto {
    flex: 0 0 auto;
    max-width: none;
  }

  .cell-lg-1,
  .colspan-lg-1 {
    flex: 0 0 8.333335%;
    max-width: 8.333335%;
  }

  .cell-lg-2,
  .colspan-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .cell-lg-3,
  .colspan-lg-3 {
    flex: 0 0 25.000005%;
    max-width: 25.000005%;
  }

  .cell-lg-4,
  .colspan-lg-4 {
    flex: 0 0 33.33334%;
    max-width: 33.33334%;
  }

  .cell-lg-5,
  .colspan-lg-5 {
    flex: 0 0 41.666675%;
    max-width: 41.666675%;
  }

  .cell-lg-6,
  .colspan-lg-6 {
    flex: 0 0 50.00001%;
    max-width: 50.00001%;
  }

  .cell-lg-7,
  .colspan-lg-7 {
    flex: 0 0 58.333345%;
    max-width: 58.333345%;
  }

  .cell-lg-8,
  .colspan-lg-8 {
    flex: 0 0 66.66668%;
    max-width: 66.66668%;
  }

  .cell-lg-9,
  .colspan-lg-9 {
    flex: 0 0 75.000015%;
    max-width: 75.000015%;
  }

  .cell-lg-10,
  .colspan-lg-10 {
    flex: 0 0 83.33335%;
    max-width: 83.33335%;
  }

  .cell-lg-11,
  .colspan-lg-11 {
    flex: 0 0 91.666685%;
    max-width: 91.666685%;
  }

  .cell-lg-12,
  .colspan-lg-12 {
    flex: 0 0 100.00002%;
    max-width: 100.00002%;
  }

  .offset-lg-12 {
    margin-left: 100.00002%;
  }

  .offset-lg-11 {
    margin-left: 91.666685%;
  }

  .offset-lg-10 {
    margin-left: 83.33335%;
  }

  .offset-lg-9 {
    margin-left: 75.000015%;
  }

  .offset-lg-8 {
    margin-left: 66.66668%;
  }

  .offset-lg-7 {
    margin-left: 58.333345%;
  }

  .offset-lg-6 {
    margin-left: 50.00001%;
  }

  .offset-lg-5 {
    margin-left: 41.666675%;
  }

  .offset-lg-4 {
    margin-left: 33.33334%;
  }

  .offset-lg-3 {
    margin-left: 25.000005%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-1 {
    margin-left: 8.333335%;
  }
}

@media screen and (min-width: 1200px) {
  .cell-xl-full {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .cell-xl-half {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .cell-xl-one-third {
    flex: 0 0 33.3333333%;
    max-width: 33.3333333%;
  }

  .cell-xl-two-third {
    flex: 0 0 66.6666666%;
    max-width: 66.6666666%;
  }

  .cell-xl-quarter {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .cell-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .cell-xl-auto {
    flex: 0 0 auto;
    max-width: none;
  }

  .cell-xl-1,
  .colspan-xl-1 {
    flex: 0 0 8.333335%;
    max-width: 8.333335%;
  }

  .cell-xl-2,
  .colspan-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .cell-xl-3,
  .colspan-xl-3 {
    flex: 0 0 25.000005%;
    max-width: 25.000005%;
  }

  .cell-xl-4,
  .colspan-xl-4 {
    flex: 0 0 33.33334%;
    max-width: 33.33334%;
  }

  .cell-xl-5,
  .colspan-xl-5 {
    flex: 0 0 41.666675%;
    max-width: 41.666675%;
  }

  .cell-xl-6,
  .colspan-xl-6 {
    flex: 0 0 50.00001%;
    max-width: 50.00001%;
  }

  .cell-xl-7,
  .colspan-xl-7 {
    flex: 0 0 58.333345%;
    max-width: 58.333345%;
  }

  .cell-xl-8,
  .colspan-xl-8 {
    flex: 0 0 66.66668%;
    max-width: 66.66668%;
  }

  .cell-xl-9,
  .colspan-xl-9 {
    flex: 0 0 75.000015%;
    max-width: 75.000015%;
  }

  .cell-xl-10,
  .colspan-xl-10 {
    flex: 0 0 83.33335%;
    max-width: 83.33335%;
  }

  .cell-xl-11,
  .colspan-xl-11 {
    flex: 0 0 91.666685%;
    max-width: 91.666685%;
  }

  .cell-xl-12,
  .colspan-xl-12 {
    flex: 0 0 100.00002%;
    max-width: 100.00002%;
  }

  .offset-xl-12 {
    margin-left: 100.00002%;
  }

  .offset-xl-11 {
    margin-left: 91.666685%;
  }

  .offset-xl-10 {
    margin-left: 83.33335%;
  }

  .offset-xl-9 {
    margin-left: 75.000015%;
  }

  .offset-xl-8 {
    margin-left: 66.66668%;
  }

  .offset-xl-7 {
    margin-left: 58.333345%;
  }

  .offset-xl-6 {
    margin-left: 50.00001%;
  }

  .offset-xl-5 {
    margin-left: 41.666675%;
  }

  .offset-xl-4 {
    margin-left: 33.33334%;
  }

  .offset-xl-3 {
    margin-left: 25.000005%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-1 {
    margin-left: 8.333335%;
  }
}

@media screen and (min-width: 1452px) {
  .cell-xxl-full {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .cell-xxl-half {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .cell-xxl-one-third {
    flex: 0 0 33.3333333%;
    max-width: 33.3333333%;
  }

  .cell-xxl-two-third {
    flex: 0 0 66.6666666%;
    max-width: 66.6666666%;
  }

  .cell-xxl-quarter {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .cell-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .cell-xxl-auto {
    flex: 0 0 auto;
    max-width: none;
  }

  .cell-xxl-1,
  .colspan-xxl-1 {
    flex: 0 0 8.333335%;
    max-width: 8.333335%;
  }

  .cell-xxl-2,
  .colspan-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .cell-xxl-3,
  .colspan-xxl-3 {
    flex: 0 0 25.000005%;
    max-width: 25.000005%;
  }

  .cell-xxl-4,
  .colspan-xxl-4 {
    flex: 0 0 33.33334%;
    max-width: 33.33334%;
  }

  .cell-xxl-5,
  .colspan-xxl-5 {
    flex: 0 0 41.666675%;
    max-width: 41.666675%;
  }

  .cell-xxl-6,
  .colspan-xxl-6 {
    flex: 0 0 50.00001%;
    max-width: 50.00001%;
  }

  .cell-xxl-7,
  .colspan-xxl-7 {
    flex: 0 0 58.333345%;
    max-width: 58.333345%;
  }

  .cell-xxl-8,
  .colspan-xxl-8 {
    flex: 0 0 66.66668%;
    max-width: 66.66668%;
  }

  .cell-xxl-9,
  .colspan-xxl-9 {
    flex: 0 0 75.000015%;
    max-width: 75.000015%;
  }

  .cell-xxl-10,
  .colspan-xxl-10 {
    flex: 0 0 83.33335%;
    max-width: 83.33335%;
  }

  .cell-xxl-11,
  .colspan-xxl-11 {
    flex: 0 0 91.666685%;
    max-width: 91.666685%;
  }

  .cell-xxl-12,
  .colspan-xxl-12 {
    flex: 0 0 100.00002%;
    max-width: 100.00002%;
  }

  .offset-xxl-12 {
    margin-left: 100.00002%;
  }

  .offset-xxl-11 {
    margin-left: 91.666685%;
  }

  .offset-xxl-10 {
    margin-left: 83.33335%;
  }

  .offset-xxl-9 {
    margin-left: 75.000015%;
  }

  .offset-xxl-8 {
    margin-left: 66.66668%;
  }

  .offset-xxl-7 {
    margin-left: 58.333345%;
  }

  .offset-xxl-6 {
    margin-left: 50.00001%;
  }

  .offset-xxl-5 {
    margin-left: 41.666675%;
  }

  .offset-xxl-4 {
    margin-left: 33.33334%;
  }

  .offset-xxl-3 {
    margin-left: 25.000005%;
  }

  .offset-xxl-2 {
    margin-left: 16.66667%;
  }

  .offset-xxl-1 {
    margin-left: 8.333335%;
  }
}

.fixed-bottom,
.fixed-top {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
}

.fixed-top {
  top: 0;
  bottom: auto;
}

.fixed-bottom {
  top: auto;
  bottom: 0;
}

.pos-relative {
  position: relative !important;
}

.pos-absolute {
  position: absolute !important;
}

.pos-static {
  position: static !important;
}

.pos-fixed {
  position: fixed !important;
}

.put-n {
  right: auto !important;
  bottom: auto !important;
  left: 50% !important;
  top: 0 !important;
  transform: translateX(-50%) translateY(-100%);
}

.put-nw {
  right: auto !important;
  bottom: auto !important;
  left: 0 !important;
  top: 0 !important;
  transform: translateY(-100%);
}

.put-ne {
  left: auto !important;
  bottom: auto !important;
  top: 0 !important;
  right: 0 !important;
  transform: translateY(-100%);
}

.put-wn {
  bottom: auto !important;
  right: auto !important;
  top: 0 !important;
  left: 0 !important;
  transform: translateX(-100%);
}

.put-w {
  bottom: auto !important;
  right: auto !important;
  top: 50% !important;
  left: 0 !important;
  transform: translateX(-100%) translateY(-50%);
}

.put-ws {
  top: auto !important;
  right: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  transform: translateX(-100%);
}

.put-en {
  bottom: auto !important;
  left: auto !important;
  top: 0 !important;
  right: 0 !important;
  transform: translateX(100%);
}

.put-e {
  bottom: auto !important;
  left: auto !important;
  top: 50% !important;
  right: 0 !important;
  transform: translateX(100%) translateY(-50%);
}

.put-es {
  top: auto !important;
  left: auto !important;
  bottom: 0 !important;
  right: 0 !important;
  transform: translateX(100%);
}

.put-s {
  bottom: auto !important;
  right: auto !important;
  top: 100% !important;
  left: 0 !important;
}

.put-sw {
  bottom: auto !important;
  right: auto !important;
  top: 100% !important;
  left: 50% !important;
  transform: translateX(-50%);
}

.put-se {
  bottom: auto !important;
  left: auto !important;
  top: 100% !important;
  right: 0 !important;
}

.put-left {
  left: 0 !important;
  right: auto !important;
}

.put-right {
  left: auto !important;
  right: 0 !important;
}

.pos-top-left {
  top: 0 !important;
  left: 0 !important;
  right: auto !important;
  bottom: auto !important;
}

.pos-top-center {
  top: 0 !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  transform: translateX(-50%);
}

.pos-top-right {
  right: 0 !important;
  top: 0 !important;
  left: auto !important;
  bottom: auto !important;
}

.pos-bottom-left {
  top: auto !important;
  right: auto !important;
  left: 0 !important;
  bottom: 0 !important;
}

.pos-bottom-center {
  top: auto !important;
  left: 50% !important;
  bottom: 0 !important;
  right: auto !important;
  transform: translateX(-50%);
}

.pos-bottom-right {
  bottom: 0 !important;
  right: 0 !important;
  top: auto !important;
  left: auto !important;
}

.pos-left-center {
  left: 0 !important;
  top: 50% !important;
  bottom: auto !important;
  right: auto !important;
  transform: translateY(-50%);
}

.pos-right-center {
  right: 0;
  top: 50%;
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
}

.pos-center {
  top: 50% !important;
  left: 50% !important;
  bottom: auto !important;
  right: auto !important;
  transform: translateX(-50%) translateY(-50%);
}

.z-absolute {
  z-index: 500 !important;
}

.z-dropdown {
  z-index: 1000 !important;
}

.z-sticky {
  z-index: 1020 !important;
}

.z-fixed {
  z-index: 1030 !important;
}

.z-modal-backdrop {
  z-index: 1040 !important;
}

.z-modal {
  z-index: 1050 !important;
}

.z-popover {
  z-index: 1060 !important;
}

.z-tooltip {
  z-index: 1070 !important;
}

.z-top {
  z-index: 1080 !important;
}

.z-notify {
  z-index: 1085 !important;
}

.z-charms {
  z-index: 1090 !important;
}

.z-overlay {
  z-index: 2000 !important;
}

.z-fullscreen {
  z-index: 2147483647 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.z-4 {
  z-index: 4 !important;
}

.z-5 {
  z-index: 5 !important;
}

.z-6 {
  z-index: 6 !important;
}

.z-7 {
  z-index: 7 !important;
}

.z-8 {
  z-index: 8 !important;
}

.z-9 {
  z-index: 9 !important;
}

.z-10 {
  z-index: 10 !important;
}

@media screen and (min-width: 0) {
  .pos-relative-fs {
    position: relative !important;
  }

  .pos-absolute-fs {
    position: absolute !important;
  }

  .pos-static-fs {
    position: static !important;
  }

  .pos-fixed-fs {
    position: fixed !important;
  }

  .put-left-fs {
    left: 0 !important;
    right: auto !important;
  }

  .put-right-fs {
    left: auto !important;
    right: 0 !important;
  }

  .put-n-fs {
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    top: 0 !important;
    transform: translateX(-50%) translateY(-100%);
  }

  .put-nw-fs {
    right: auto !important;
    bottom: auto !important;
    left: 0 !important;
    top: 0 !important;
    transform: translateY(-100%);
  }

  .put-ne-fs {
    left: auto !important;
    bottom: auto !important;
    top: 0 !important;
    right: 0 !important;
    transform: translateY(-100%);
  }

  .put-wn-fs {
    bottom: auto !important;
    right: auto !important;
    top: 0 !important;
    left: 0 !important;
    transform: translateX(-100%);
  }

  .put-w-fs {
    bottom: auto !important;
    right: auto !important;
    top: 50% !important;
    left: 0 !important;
    transform: translateX(-100%) translateY(-50%);
  }

  .put-ws-fs {
    top: auto !important;
    right: auto !important;
    bottom: 0 !important;
    left: 0 !important;
    transform: translateX(-100%);
  }

  .put-en-fs {
    bottom: auto !important;
    left: auto !important;
    top: 0 !important;
    right: 0 !important;
    transform: translateX(100%);
  }

  .put-e-fs {
    bottom: auto !important;
    left: auto !important;
    top: 50% !important;
    right: 0 !important;
    transform: translateX(100%) translateY(-50%);
  }

  .put-es-fs {
    top: auto !important;
    left: auto !important;
    bottom: 0 !important;
    right: 0 !important;
    transform: translateX(100%);
  }

  .put-s-fs {
    bottom: auto !important;
    right: auto !important;
    top: 100% !important;
    left: 0 !important;
  }

  .put-sw-fs {
    bottom: auto !important;
    right: auto !important;
    top: 100% !important;
    left: 50% !important;
    transform: translateX(-50%);
  }

  .put-se-fs {
    bottom: auto !important;
    left: auto !important;
    top: 100% !important;
    right: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pos-relative-sm {
    position: relative !important;
  }

  .pos-absolute-sm {
    position: absolute !important;
  }

  .pos-static-sm {
    position: static !important;
  }

  .pos-fixed-sm {
    position: fixed !important;
  }

  .put-left-sm {
    left: 0 !important;
    right: auto !important;
  }

  .put-right-sm {
    left: auto !important;
    right: 0 !important;
  }

  .put-n-sm {
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    top: 0 !important;
    transform: translateX(-50%) translateY(-100%);
  }

  .put-nw-sm {
    right: auto !important;
    bottom: auto !important;
    left: 0 !important;
    top: 0 !important;
    transform: translateY(-100%);
  }

  .put-ne-sm {
    left: auto !important;
    bottom: auto !important;
    top: 0 !important;
    right: 0 !important;
    transform: translateY(-100%);
  }

  .put-wn-sm {
    bottom: auto !important;
    right: auto !important;
    top: 0 !important;
    left: 0 !important;
    transform: translateX(-100%);
  }

  .put-w-sm {
    bottom: auto !important;
    right: auto !important;
    top: 50% !important;
    left: 0 !important;
    transform: translateX(-100%) translateY(-50%);
  }

  .put-ws-sm {
    top: auto !important;
    right: auto !important;
    bottom: 0 !important;
    left: 0 !important;
    transform: translateX(-100%);
  }

  .put-en-sm {
    bottom: auto !important;
    left: auto !important;
    top: 0 !important;
    right: 0 !important;
    transform: translateX(100%);
  }

  .put-e-sm {
    bottom: auto !important;
    left: auto !important;
    top: 50% !important;
    right: 0 !important;
    transform: translateX(100%) translateY(-50%);
  }

  .put-es-sm {
    top: auto !important;
    left: auto !important;
    bottom: 0 !important;
    right: 0 !important;
    transform: translateX(100%);
  }

  .put-s-sm {
    bottom: auto !important;
    right: auto !important;
    top: 100% !important;
    left: 0 !important;
  }

  .put-sw-sm {
    bottom: auto !important;
    right: auto !important;
    top: 100% !important;
    left: 50% !important;
    transform: translateX(-50%);
  }

  .put-se-sm {
    bottom: auto !important;
    left: auto !important;
    top: 100% !important;
    right: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pos-relative-md {
    position: relative !important;
  }

  .pos-absolute-md {
    position: absolute !important;
  }

  .pos-static-md {
    position: static !important;
  }

  .pos-fixed-md {
    position: fixed !important;
  }

  .put-left-md {
    left: 0 !important;
    right: auto !important;
  }

  .put-right-md {
    left: auto !important;
    right: 0 !important;
  }

  .put-n-md {
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    top: 0 !important;
    transform: translateX(-50%) translateY(-100%);
  }

  .put-nw-md {
    right: auto !important;
    bottom: auto !important;
    left: 0 !important;
    top: 0 !important;
    transform: translateY(-100%);
  }

  .put-ne-md {
    left: auto !important;
    bottom: auto !important;
    top: 0 !important;
    right: 0 !important;
    transform: translateY(-100%);
  }

  .put-wn-md {
    bottom: auto !important;
    right: auto !important;
    top: 0 !important;
    left: 0 !important;
    transform: translateX(-100%);
  }

  .put-w-md {
    bottom: auto !important;
    right: auto !important;
    top: 50% !important;
    left: 0 !important;
    transform: translateX(-100%) translateY(-50%);
  }

  .put-ws-md {
    top: auto !important;
    right: auto !important;
    bottom: 0 !important;
    left: 0 !important;
    transform: translateX(-100%);
  }

  .put-en-md {
    bottom: auto !important;
    left: auto !important;
    top: 0 !important;
    right: 0 !important;
    transform: translateX(100%);
  }

  .put-e-md {
    bottom: auto !important;
    left: auto !important;
    top: 50% !important;
    right: 0 !important;
    transform: translateX(100%) translateY(-50%);
  }

  .put-es-md {
    top: auto !important;
    left: auto !important;
    bottom: 0 !important;
    right: 0 !important;
    transform: translateX(100%);
  }

  .put-s-md {
    bottom: auto !important;
    right: auto !important;
    top: 100% !important;
    left: 0 !important;
  }

  .put-sw-md {
    bottom: auto !important;
    right: auto !important;
    top: 100% !important;
    left: 50% !important;
    transform: translateX(-50%);
  }

  .put-se-md {
    bottom: auto !important;
    left: auto !important;
    top: 100% !important;
    right: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pos-relative-lg {
    position: relative !important;
  }

  .pos-absolute-lg {
    position: absolute !important;
  }

  .pos-static-lg {
    position: static !important;
  }

  .pos-fixed-lg {
    position: fixed !important;
  }

  .put-left-lg {
    left: 0 !important;
    right: auto !important;
  }

  .put-right-lg {
    left: auto !important;
    right: 0 !important;
  }

  .put-n-lg {
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    top: 0 !important;
    transform: translateX(-50%) translateY(-100%);
  }

  .put-nw-lg {
    right: auto !important;
    bottom: auto !important;
    left: 0 !important;
    top: 0 !important;
    transform: translateY(-100%);
  }

  .put-ne-lg {
    left: auto !important;
    bottom: auto !important;
    top: 0 !important;
    right: 0 !important;
    transform: translateY(-100%);
  }

  .put-wn-lg {
    bottom: auto !important;
    right: auto !important;
    top: 0 !important;
    left: 0 !important;
    transform: translateX(-100%);
  }

  .put-w-lg {
    bottom: auto !important;
    right: auto !important;
    top: 50% !important;
    left: 0 !important;
    transform: translateX(-100%) translateY(-50%);
  }

  .put-ws-lg {
    top: auto !important;
    right: auto !important;
    bottom: 0 !important;
    left: 0 !important;
    transform: translateX(-100%);
  }

  .put-en-lg {
    bottom: auto !important;
    left: auto !important;
    top: 0 !important;
    right: 0 !important;
    transform: translateX(100%);
  }

  .put-e-lg {
    bottom: auto !important;
    left: auto !important;
    top: 50% !important;
    right: 0 !important;
    transform: translateX(100%) translateY(-50%);
  }

  .put-es-lg {
    top: auto !important;
    left: auto !important;
    bottom: 0 !important;
    right: 0 !important;
    transform: translateX(100%);
  }

  .put-s-lg {
    bottom: auto !important;
    right: auto !important;
    top: 100% !important;
    left: 0 !important;
  }

  .put-sw-lg {
    bottom: auto !important;
    right: auto !important;
    top: 100% !important;
    left: 50% !important;
    transform: translateX(-50%);
  }

  .put-se-lg {
    bottom: auto !important;
    left: auto !important;
    top: 100% !important;
    right: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pos-relative-xl {
    position: relative !important;
  }

  .pos-absolute-xl {
    position: absolute !important;
  }

  .pos-static-xl {
    position: static !important;
  }

  .pos-fixed-xl {
    position: fixed !important;
  }

  .put-left-xl {
    left: 0 !important;
    right: auto !important;
  }

  .put-right-xl {
    left: auto !important;
    right: 0 !important;
  }

  .put-n-xl {
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    top: 0 !important;
    transform: translateX(-50%) translateY(-100%);
  }

  .put-nw-xl {
    right: auto !important;
    bottom: auto !important;
    left: 0 !important;
    top: 0 !important;
    transform: translateY(-100%);
  }

  .put-ne-xl {
    left: auto !important;
    bottom: auto !important;
    top: 0 !important;
    right: 0 !important;
    transform: translateY(-100%);
  }

  .put-wn-xl {
    bottom: auto !important;
    right: auto !important;
    top: 0 !important;
    left: 0 !important;
    transform: translateX(-100%);
  }

  .put-w-xl {
    bottom: auto !important;
    right: auto !important;
    top: 50% !important;
    left: 0 !important;
    transform: translateX(-100%) translateY(-50%);
  }

  .put-ws-xl {
    top: auto !important;
    right: auto !important;
    bottom: 0 !important;
    left: 0 !important;
    transform: translateX(-100%);
  }

  .put-en-xl {
    bottom: auto !important;
    left: auto !important;
    top: 0 !important;
    right: 0 !important;
    transform: translateX(100%);
  }

  .put-e-xl {
    bottom: auto !important;
    left: auto !important;
    top: 50% !important;
    right: 0 !important;
    transform: translateX(100%) translateY(-50%);
  }

  .put-es-xl {
    top: auto !important;
    left: auto !important;
    bottom: 0 !important;
    right: 0 !important;
    transform: translateX(100%);
  }

  .put-s-xl {
    bottom: auto !important;
    right: auto !important;
    top: 100% !important;
    left: 0 !important;
  }

  .put-sw-xl {
    bottom: auto !important;
    right: auto !important;
    top: 100% !important;
    left: 50% !important;
    transform: translateX(-50%);
  }

  .put-se-xl {
    bottom: auto !important;
    left: auto !important;
    top: 100% !important;
    right: 0 !important;
  }
}

@media screen and (min-width: 1452px) {
  .pos-relative-xxl {
    position: relative !important;
  }

  .pos-absolute-xxl {
    position: absolute !important;
  }

  .pos-static-xxl {
    position: static !important;
  }

  .pos-fixed-xxl {
    position: fixed !important;
  }

  .put-left-xxl {
    left: 0 !important;
    right: auto !important;
  }

  .put-right-xxl {
    left: auto !important;
    right: 0 !important;
  }

  .put-n-xxl {
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    top: 0 !important;
    transform: translateX(-50%) translateY(-100%);
  }

  .put-nw-xxl {
    right: auto !important;
    bottom: auto !important;
    left: 0 !important;
    top: 0 !important;
    transform: translateY(-100%);
  }

  .put-ne-xxl {
    left: auto !important;
    bottom: auto !important;
    top: 0 !important;
    right: 0 !important;
    transform: translateY(-100%);
  }

  .put-wn-xxl {
    bottom: auto !important;
    right: auto !important;
    top: 0 !important;
    left: 0 !important;
    transform: translateX(-100%);
  }

  .put-w-xxl {
    bottom: auto !important;
    right: auto !important;
    top: 50% !important;
    left: 0 !important;
    transform: translateX(-100%) translateY(-50%);
  }

  .put-ws-xxl {
    top: auto !important;
    right: auto !important;
    bottom: 0 !important;
    left: 0 !important;
    transform: translateX(-100%);
  }

  .put-en-xxl {
    bottom: auto !important;
    left: auto !important;
    top: 0 !important;
    right: 0 !important;
    transform: translateX(100%);
  }

  .put-e-xxl {
    bottom: auto !important;
    left: auto !important;
    top: 50% !important;
    right: 0 !important;
    transform: translateX(100%) translateY(-50%);
  }

  .put-es-xxl {
    top: auto !important;
    left: auto !important;
    bottom: 0 !important;
    right: 0 !important;
    transform: translateX(100%);
  }

  .put-s-xxl {
    bottom: auto !important;
    right: auto !important;
    top: 100% !important;
    left: 0 !important;
  }

  .put-sw-xxl {
    bottom: auto !important;
    right: auto !important;
    top: 100% !important;
    left: 50% !important;
    transform: translateX(-50%);
  }

  .put-se-xxl {
    bottom: auto !important;
    left: auto !important;
    top: 100% !important;
    right: 0 !important;
  }
}

.op-default {
  background-color: rgba(27, 161, 226, 0.7) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-hover:hover {
  background-color: rgba(29, 29, 29, 0.7) !important;
}

.bg-hover2:hover {
  background-color: rgba(29, 29, 29, 0.5) !important;
}

.bg-hover-light:hover {
  background-color: rgba(255, 255, 255, 0.01) !important;
}

.bg-hover-light1:hover {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.bg-hover-light2:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.bg-hover-light3:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.bg-facebook {
  background-color: #4267b2;
}

.bg-twitter {
  background-color: #1da1f2;
}

.bg-github {
  background-color: #24292e;
}

.bg-bootstrap {
  background-color: #563d7c;
}

.bg-gitlab {
  background-color: #e65328;
}

.bg-amazon {
  background-color: #232f3e;
}

.bd-default {
  border-color: #dfdfdf !important;
}

.bd-transparent {
  border-color: transparent !important;
}

.fg-black {
  color: #000 !important;
}

.bg-black {
  background-color: #000 !important;
}

.bd-black {
  border-color: #000 !important;
}

.ol-black {
  outline-color: #000 !important;
}

.fg-black-active:active {
  color: #000 !important;
}

.fg-black-hover:hover {
  color: #000 !important;
}

.fg-black-focus:focus {
  color: #000 !important;
}

.bg-black-active:active {
  background-color: #000 !important;
}

.bg-black-hover:hover {
  background-color: #000 !important;
}

.bg-black-focus:focus {
  background-color: #000 !important;
}

.bd-black-active:active {
  border-color: #000 !important;
}

.bd-black-hover:hover {
  border-color: #000 !important;
}

.bd-black-focus:focus {
  border-color: #000 !important;
}

.ol-black-active:active {
  outline-color: #000 !important;
}

.ol-black-hover:hover {
  outline-color: #000 !important;
}

.ol-black-focus:focus {
  outline-color: #000 !important;
}

.ribbed-black {
  background: #000
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-black::before {
  background: #000 !important;
}

.before-fg-black::before {
  color: #000 !important;
}

.after-bg-black::after {
  background: #000 !important;
}

.after-fg-black::after {
  color: #000 !important;
}

.op-black {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.tg-black::before {
  border-color: #000;
}

.fg-white {
  color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bd-white {
  border-color: #fff !important;
}

.ol-white {
  outline-color: #fff !important;
}

.fg-white-active:active {
  color: #fff !important;
}

.fg-white-hover:hover {
  color: #fff !important;
}

.fg-white-focus:focus {
  color: #fff !important;
}

.bg-white-active:active {
  background-color: #fff !important;
}

.bg-white-hover:hover {
  background-color: #fff !important;
}

.bg-white-focus:focus {
  background-color: #fff !important;
}

.bd-white-active:active {
  border-color: #fff !important;
}

.bd-white-hover:hover {
  border-color: #fff !important;
}

.bd-white-focus:focus {
  border-color: #fff !important;
}

.ol-white-active:active {
  outline-color: #fff !important;
}

.ol-white-hover:hover {
  outline-color: #fff !important;
}

.ol-white-focus:focus {
  outline-color: #fff !important;
}

.ribbed-white {
  background: #fff
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-white::before {
  background: #fff !important;
}

.before-fg-white::before {
  color: #fff !important;
}

.after-bg-white::after {
  background: #fff !important;
}

.after-fg-white::after {
  color: #fff !important;
}

.op-white {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.tg-white::before {
  border-color: #fff;
}

.fg-dark {
  color: #1d1d1d !important;
}

.bg-dark {
  background-color: #1d1d1d !important;
}

.bd-dark {
  border-color: #1d1d1d !important;
}

.ol-dark {
  outline-color: #1d1d1d !important;
}

.fg-dark-active:active {
  color: #1d1d1d !important;
}

.fg-dark-hover:hover {
  color: #1d1d1d !important;
}

.fg-dark-focus:focus {
  color: #1d1d1d !important;
}

.bg-dark-active:active {
  background-color: #1d1d1d !important;
}

.bg-dark-hover:hover {
  background-color: #1d1d1d !important;
}

.bg-dark-focus:focus {
  background-color: #1d1d1d !important;
}

.bd-dark-active:active {
  border-color: #1d1d1d !important;
}

.bd-dark-hover:hover {
  border-color: #1d1d1d !important;
}

.bd-dark-focus:focus {
  border-color: #1d1d1d !important;
}

.ol-dark-active:active {
  outline-color: #1d1d1d !important;
}

.ol-dark-hover:hover {
  outline-color: #1d1d1d !important;
}

.ol-dark-focus:focus {
  outline-color: #1d1d1d !important;
}

.ribbed-dark {
  background: #1d1d1d
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-dark::before {
  background: #1d1d1d !important;
}

.before-fg-dark::before {
  color: #1d1d1d !important;
}

.after-bg-dark::after {
  background: #1d1d1d !important;
}

.after-fg-dark::after {
  color: #1d1d1d !important;
}

.op-dark {
  background-color: rgba(29, 29, 29, 0.7) !important;
}

.tg-dark::before {
  border-color: #1d1d1d;
}

.fg-light {
  color: #f8f8f8 !important;
}

.bg-light {
  background-color: #f8f8f8 !important;
}

.bd-light {
  border-color: #f8f8f8 !important;
}

.ol-light {
  outline-color: #f8f8f8 !important;
}

.fg-light-active:active {
  color: #f8f8f8 !important;
}

.fg-light-hover:hover {
  color: #f8f8f8 !important;
}

.fg-light-focus:focus {
  color: #f8f8f8 !important;
}

.bg-light-active:active {
  background-color: #f8f8f8 !important;
}

.bg-light-hover:hover {
  background-color: #f8f8f8 !important;
}

.bg-light-focus:focus {
  background-color: #f8f8f8 !important;
}

.bd-light-active:active {
  border-color: #f8f8f8 !important;
}

.bd-light-hover:hover {
  border-color: #f8f8f8 !important;
}

.bd-light-focus:focus {
  border-color: #f8f8f8 !important;
}

.ol-light-active:active {
  outline-color: #f8f8f8 !important;
}

.ol-light-hover:hover {
  outline-color: #f8f8f8 !important;
}

.ol-light-focus:focus {
  outline-color: #f8f8f8 !important;
}

.ribbed-light {
  background: #f8f8f8
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-light::before {
  background: #f8f8f8 !important;
}

.before-fg-light::before {
  color: #f8f8f8 !important;
}

.after-bg-light::after {
  background: #f8f8f8 !important;
}

.after-fg-light::after {
  color: #f8f8f8 !important;
}

.op-light {
  background-color: rgba(248, 248, 248, 0.7) !important;
}

.tg-light::before {
  border-color: #f8f8f8;
}

.fg-grayBlue {
  color: #607d8b !important;
}

.bg-grayBlue {
  background-color: #607d8b !important;
}

.bd-grayBlue {
  border-color: #607d8b !important;
}

.ol-grayBlue {
  outline-color: #607d8b !important;
}

.fg-grayBlue-active:active {
  color: #607d8b !important;
}

.fg-grayBlue-hover:hover {
  color: #607d8b !important;
}

.fg-grayBlue-focus:focus {
  color: #607d8b !important;
}

.bg-grayBlue-active:active {
  background-color: #607d8b !important;
}

.bg-grayBlue-hover:hover {
  background-color: #607d8b !important;
}

.bg-grayBlue-focus:focus {
  background-color: #607d8b !important;
}

.bd-grayBlue-active:active {
  border-color: #607d8b !important;
}

.bd-grayBlue-hover:hover {
  border-color: #607d8b !important;
}

.bd-grayBlue-focus:focus {
  border-color: #607d8b !important;
}

.ol-grayBlue-active:active {
  outline-color: #607d8b !important;
}

.ol-grayBlue-hover:hover {
  outline-color: #607d8b !important;
}

.ol-grayBlue-focus:focus {
  outline-color: #607d8b !important;
}

.ribbed-grayBlue {
  background: #607d8b
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-grayBlue::before {
  background: #607d8b !important;
}

.before-fg-grayBlue::before {
  color: #607d8b !important;
}

.after-bg-grayBlue::after {
  background: #607d8b !important;
}

.after-fg-grayBlue::after {
  color: #607d8b !important;
}

.op-grayBlue {
  background-color: rgba(96, 125, 139, 0.7) !important;
}

.tg-grayBlue::before {
  border-color: #607d8b;
}

.fg-grayWhite {
  color: #f5f5f5 !important;
}

.bg-grayWhite {
  background-color: #f5f5f5 !important;
}

.bd-grayWhite {
  border-color: #f5f5f5 !important;
}

.ol-grayWhite {
  outline-color: #f5f5f5 !important;
}

.fg-grayWhite-active:active {
  color: #f5f5f5 !important;
}

.fg-grayWhite-hover:hover {
  color: #f5f5f5 !important;
}

.fg-grayWhite-focus:focus {
  color: #f5f5f5 !important;
}

.bg-grayWhite-active:active {
  background-color: #f5f5f5 !important;
}

.bg-grayWhite-hover:hover {
  background-color: #f5f5f5 !important;
}

.bg-grayWhite-focus:focus {
  background-color: #f5f5f5 !important;
}

.bd-grayWhite-active:active {
  border-color: #f5f5f5 !important;
}

.bd-grayWhite-hover:hover {
  border-color: #f5f5f5 !important;
}

.bd-grayWhite-focus:focus {
  border-color: #f5f5f5 !important;
}

.ol-grayWhite-active:active {
  outline-color: #f5f5f5 !important;
}

.ol-grayWhite-hover:hover {
  outline-color: #f5f5f5 !important;
}

.ol-grayWhite-focus:focus {
  outline-color: #f5f5f5 !important;
}

.ribbed-grayWhite {
  background: #f5f5f5
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-grayWhite::before {
  background: #f5f5f5 !important;
}

.before-fg-grayWhite::before {
  color: #f5f5f5 !important;
}

.after-bg-grayWhite::after {
  background: #f5f5f5 !important;
}

.after-fg-grayWhite::after {
  color: #f5f5f5 !important;
}

.op-grayWhite {
  background-color: rgba(245, 245, 245, 0.7) !important;
}

.tg-grayWhite::before {
  border-color: #f5f5f5;
}

.fg-grayMouse {
  color: #455a64 !important;
}

.bg-grayMouse {
  background-color: #455a64 !important;
}

.bd-grayMouse {
  border-color: #455a64 !important;
}

.ol-grayMouse {
  outline-color: #455a64 !important;
}

.fg-grayMouse-active:active {
  color: #455a64 !important;
}

.fg-grayMouse-hover:hover {
  color: #455a64 !important;
}

.fg-grayMouse-focus:focus {
  color: #455a64 !important;
}

.bg-grayMouse-active:active {
  background-color: #455a64 !important;
}

.bg-grayMouse-hover:hover {
  background-color: #455a64 !important;
}

.bg-grayMouse-focus:focus {
  background-color: #455a64 !important;
}

.bd-grayMouse-active:active {
  border-color: #455a64 !important;
}

.bd-grayMouse-hover:hover {
  border-color: #455a64 !important;
}

.bd-grayMouse-focus:focus {
  border-color: #455a64 !important;
}

.ol-grayMouse-active:active {
  outline-color: #455a64 !important;
}

.ol-grayMouse-hover:hover {
  outline-color: #455a64 !important;
}

.ol-grayMouse-focus:focus {
  outline-color: #455a64 !important;
}

.ribbed-grayMouse {
  background: #455a64
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-grayMouse::before {
  background: #455a64 !important;
}

.before-fg-grayMouse::before {
  color: #455a64 !important;
}

.after-bg-grayMouse::after {
  background: #455a64 !important;
}

.after-fg-grayMouse::after {
  color: #455a64 !important;
}

.op-grayMouse {
  background-color: rgba(69, 90, 100, 0.7) !important;
}

.tg-grayMouse::before {
  border-color: #455a64;
}

.fg-brandColor1 {
  color: #2ac4f4 !important;
}

.bg-brandColor1 {
  background-color: #2ac4f4 !important;
}

.bd-brandColor1 {
  border-color: #2ac4f4 !important;
}

.ol-brandColor1 {
  outline-color: #2ac4f4 !important;
}

.fg-brandColor1-active:active {
  color: #2ac4f4 !important;
}

.fg-brandColor1-hover:hover {
  color: #2ac4f4 !important;
}

.fg-brandColor1-focus:focus {
  color: #2ac4f4 !important;
}

.bg-brandColor1-active:active {
  background-color: #2ac4f4 !important;
}

.bg-brandColor1-hover:hover {
  background-color: #2ac4f4 !important;
}

.bg-brandColor1-focus:focus {
  background-color: #2ac4f4 !important;
}

.bd-brandColor1-active:active {
  border-color: #2ac4f4 !important;
}

.bd-brandColor1-hover:hover {
  border-color: #2ac4f4 !important;
}

.bd-brandColor1-focus:focus {
  border-color: #2ac4f4 !important;
}

.ol-brandColor1-active:active {
  outline-color: #2ac4f4 !important;
}

.ol-brandColor1-hover:hover {
  outline-color: #2ac4f4 !important;
}

.ol-brandColor1-focus:focus {
  outline-color: #2ac4f4 !important;
}

.ribbed-brandColor1 {
  background: #2ac4f4
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-brandColor1::before {
  background: #2ac4f4 !important;
}

.before-fg-brandColor1::before {
  color: #2ac4f4 !important;
}

.after-bg-brandColor1::after {
  background: #2ac4f4 !important;
}

.after-fg-brandColor1::after {
  color: #2ac4f4 !important;
}

.op-brandColor1 {
  background-color: rgba(42, 196, 244, 0.7) !important;
}

.tg-brandColor1::before {
  border-color: #2ac4f4;
}

.fg-brandColor2 {
  color: #004d6f !important;
}

.bg-brandColor2 {
  background-color: #004d6f !important;
}

.bd-brandColor2 {
  border-color: #004d6f !important;
}

.ol-brandColor2 {
  outline-color: #004d6f !important;
}

.fg-brandColor2-active:active {
  color: #004d6f !important;
}

.fg-brandColor2-hover:hover {
  color: #004d6f !important;
}

.fg-brandColor2-focus:focus {
  color: #004d6f !important;
}

.bg-brandColor2-active:active {
  background-color: #004d6f !important;
}

.bg-brandColor2-hover:hover {
  background-color: #004d6f !important;
}

.bg-brandColor2-focus:focus {
  background-color: #004d6f !important;
}

.bd-brandColor2-active:active {
  border-color: #004d6f !important;
}

.bd-brandColor2-hover:hover {
  border-color: #004d6f !important;
}

.bd-brandColor2-focus:focus {
  border-color: #004d6f !important;
}

.ol-brandColor2-active:active {
  outline-color: #004d6f !important;
}

.ol-brandColor2-hover:hover {
  outline-color: #004d6f !important;
}

.ol-brandColor2-focus:focus {
  outline-color: #004d6f !important;
}

.ribbed-brandColor2 {
  background: #004d6f
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-brandColor2::before {
  background: #004d6f !important;
}

.before-fg-brandColor2::before {
  color: #004d6f !important;
}

.after-bg-brandColor2::after {
  background: #004d6f !important;
}

.after-fg-brandColor2::after {
  color: #004d6f !important;
}

.op-brandColor2 {
  background-color: rgba(0, 77, 111, 0.7) !important;
}

.tg-brandColor2::before {
  border-color: #004d6f;
}

.fg-lime {
  color: #a4c400 !important;
}

.bg-lime {
  background-color: #a4c400 !important;
}

.bd-lime {
  border-color: #a4c400 !important;
}

.ol-lime {
  outline-color: #a4c400 !important;
}

.fg-lime-active:active {
  color: #a4c400 !important;
}

.fg-lime-hover:hover {
  color: #a4c400 !important;
}

.fg-lime-focus:focus {
  color: #a4c400 !important;
}

.bg-lime-active:active {
  background-color: #a4c400 !important;
}

.bg-lime-hover:hover {
  background-color: #a4c400 !important;
}

.bg-lime-focus:focus {
  background-color: #a4c400 !important;
}

.bd-lime-active:active {
  border-color: #a4c400 !important;
}

.bd-lime-hover:hover {
  border-color: #a4c400 !important;
}

.bd-lime-focus:focus {
  border-color: #a4c400 !important;
}

.ol-lime-active:active {
  outline-color: #a4c400 !important;
}

.ol-lime-hover:hover {
  outline-color: #a4c400 !important;
}

.ol-lime-focus:focus {
  outline-color: #a4c400 !important;
}

.ribbed-lime {
  background: #a4c400
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lime::before {
  background: #a4c400 !important;
}

.before-fg-lime::before {
  color: #a4c400 !important;
}

.after-bg-lime::after {
  background: #a4c400 !important;
}

.after-fg-lime::after {
  color: #a4c400 !important;
}

.op-lime {
  background-color: rgba(164, 196, 0, 0.7) !important;
}

.tg-lime::before {
  border-color: #a4c400;
}

.fg-green {
  color: #60a917 !important;
}

.bg-green {
  background-color: #60a917 !important;
}

.bd-green {
  border-color: #60a917 !important;
}

.ol-green {
  outline-color: #60a917 !important;
}

.fg-green-active:active {
  color: #60a917 !important;
}

.fg-green-hover:hover {
  color: #60a917 !important;
}

.fg-green-focus:focus {
  color: #60a917 !important;
}

.bg-green-active:active {
  background-color: #60a917 !important;
}

.bg-green-hover:hover {
  background-color: #60a917 !important;
}

.bg-green-focus:focus {
  background-color: #60a917 !important;
}

.bd-green-active:active {
  border-color: #60a917 !important;
}

.bd-green-hover:hover {
  border-color: #60a917 !important;
}

.bd-green-focus:focus {
  border-color: #60a917 !important;
}

.ol-green-active:active {
  outline-color: #60a917 !important;
}

.ol-green-hover:hover {
  outline-color: #60a917 !important;
}

.ol-green-focus:focus {
  outline-color: #60a917 !important;
}

.ribbed-green {
  background: #60a917
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-green::before {
  background: #60a917 !important;
}

.before-fg-green::before {
  color: #60a917 !important;
}

.after-bg-green::after {
  background: #60a917 !important;
}

.after-fg-green::after {
  color: #60a917 !important;
}

.op-green {
  background-color: rgba(96, 169, 23, 0.7) !important;
}

.tg-green::before {
  border-color: #60a917;
}

.fg-emerald {
  color: #008a00 !important;
}

.bg-emerald {
  background-color: #008a00 !important;
}

.bd-emerald {
  border-color: #008a00 !important;
}

.ol-emerald {
  outline-color: #008a00 !important;
}

.fg-emerald-active:active {
  color: #008a00 !important;
}

.fg-emerald-hover:hover {
  color: #008a00 !important;
}

.fg-emerald-focus:focus {
  color: #008a00 !important;
}

.bg-emerald-active:active {
  background-color: #008a00 !important;
}

.bg-emerald-hover:hover {
  background-color: #008a00 !important;
}

.bg-emerald-focus:focus {
  background-color: #008a00 !important;
}

.bd-emerald-active:active {
  border-color: #008a00 !important;
}

.bd-emerald-hover:hover {
  border-color: #008a00 !important;
}

.bd-emerald-focus:focus {
  border-color: #008a00 !important;
}

.ol-emerald-active:active {
  outline-color: #008a00 !important;
}

.ol-emerald-hover:hover {
  outline-color: #008a00 !important;
}

.ol-emerald-focus:focus {
  outline-color: #008a00 !important;
}

.ribbed-emerald {
  background: #008a00
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-emerald::before {
  background: #008a00 !important;
}

.before-fg-emerald::before {
  color: #008a00 !important;
}

.after-bg-emerald::after {
  background: #008a00 !important;
}

.after-fg-emerald::after {
  color: #008a00 !important;
}

.op-emerald {
  background-color: rgba(0, 138, 0, 0.7) !important;
}

.tg-emerald::before {
  border-color: #008a00;
}

.fg-blue {
  color: #00aff0 !important;
}

.bg-blue {
  background-color: #00aff0 !important;
}

.bd-blue {
  border-color: #00aff0 !important;
}

.ol-blue {
  outline-color: #00aff0 !important;
}

.fg-blue-active:active {
  color: #00aff0 !important;
}

.fg-blue-hover:hover {
  color: #00aff0 !important;
}

.fg-blue-focus:focus {
  color: #00aff0 !important;
}

.bg-blue-active:active {
  background-color: #00aff0 !important;
}

.bg-blue-hover:hover {
  background-color: #00aff0 !important;
}

.bg-blue-focus:focus {
  background-color: #00aff0 !important;
}

.bd-blue-active:active {
  border-color: #00aff0 !important;
}

.bd-blue-hover:hover {
  border-color: #00aff0 !important;
}

.bd-blue-focus:focus {
  border-color: #00aff0 !important;
}

.ol-blue-active:active {
  outline-color: #00aff0 !important;
}

.ol-blue-hover:hover {
  outline-color: #00aff0 !important;
}

.ol-blue-focus:focus {
  outline-color: #00aff0 !important;
}

.ribbed-blue {
  background: #00aff0
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-blue::before {
  background: #00aff0 !important;
}

.before-fg-blue::before {
  color: #00aff0 !important;
}

.after-bg-blue::after {
  background: #00aff0 !important;
}

.after-fg-blue::after {
  color: #00aff0 !important;
}

.op-blue {
  background-color: rgba(0, 175, 240, 0.7) !important;
}

.tg-blue::before {
  border-color: #00aff0;
}

.fg-teal {
  color: #00aba9 !important;
}

.bg-teal {
  background-color: #00aba9 !important;
}

.bd-teal {
  border-color: #00aba9 !important;
}

.ol-teal {
  outline-color: #00aba9 !important;
}

.fg-teal-active:active {
  color: #00aba9 !important;
}

.fg-teal-hover:hover {
  color: #00aba9 !important;
}

.fg-teal-focus:focus {
  color: #00aba9 !important;
}

.bg-teal-active:active {
  background-color: #00aba9 !important;
}

.bg-teal-hover:hover {
  background-color: #00aba9 !important;
}

.bg-teal-focus:focus {
  background-color: #00aba9 !important;
}

.bd-teal-active:active {
  border-color: #00aba9 !important;
}

.bd-teal-hover:hover {
  border-color: #00aba9 !important;
}

.bd-teal-focus:focus {
  border-color: #00aba9 !important;
}

.ol-teal-active:active {
  outline-color: #00aba9 !important;
}

.ol-teal-hover:hover {
  outline-color: #00aba9 !important;
}

.ol-teal-focus:focus {
  outline-color: #00aba9 !important;
}

.ribbed-teal {
  background: #00aba9
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-teal::before {
  background: #00aba9 !important;
}

.before-fg-teal::before {
  color: #00aba9 !important;
}

.after-bg-teal::after {
  background: #00aba9 !important;
}

.after-fg-teal::after {
  color: #00aba9 !important;
}

.op-teal {
  background-color: rgba(0, 171, 169, 0.7) !important;
}

.tg-teal::before {
  border-color: #00aba9;
}

.fg-cyan {
  color: #1ba1e2 !important;
}

.bg-cyan {
  background-color: #1ba1e2 !important;
}

.bd-cyan {
  border-color: #1ba1e2 !important;
}

.ol-cyan {
  outline-color: #1ba1e2 !important;
}

.fg-cyan-active:active {
  color: #1ba1e2 !important;
}

.fg-cyan-hover:hover {
  color: #1ba1e2 !important;
}

.fg-cyan-focus:focus {
  color: #1ba1e2 !important;
}

.bg-cyan-active:active {
  background-color: #1ba1e2 !important;
}

.bg-cyan-hover:hover {
  background-color: #1ba1e2 !important;
}

.bg-cyan-focus:focus {
  background-color: #1ba1e2 !important;
}

.bd-cyan-active:active {
  border-color: #1ba1e2 !important;
}

.bd-cyan-hover:hover {
  border-color: #1ba1e2 !important;
}

.bd-cyan-focus:focus {
  border-color: #1ba1e2 !important;
}

.ol-cyan-active:active {
  outline-color: #1ba1e2 !important;
}

.ol-cyan-hover:hover {
  outline-color: #1ba1e2 !important;
}

.ol-cyan-focus:focus {
  outline-color: #1ba1e2 !important;
}

.ribbed-cyan {
  background: #1ba1e2
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-cyan::before {
  background: #1ba1e2 !important;
}

.before-fg-cyan::before {
  color: #1ba1e2 !important;
}

.after-bg-cyan::after {
  background: #1ba1e2 !important;
}

.after-fg-cyan::after {
  color: #1ba1e2 !important;
}

.op-cyan {
  background-color: rgba(27, 161, 226, 0.7) !important;
}

.tg-cyan::before {
  border-color: #1ba1e2;
}

.fg-cobalt {
  color: #0050ef !important;
}

.bg-cobalt {
  background-color: #0050ef !important;
}

.bd-cobalt {
  border-color: #0050ef !important;
}

.ol-cobalt {
  outline-color: #0050ef !important;
}

.fg-cobalt-active:active {
  color: #0050ef !important;
}

.fg-cobalt-hover:hover {
  color: #0050ef !important;
}

.fg-cobalt-focus:focus {
  color: #0050ef !important;
}

.bg-cobalt-active:active {
  background-color: #0050ef !important;
}

.bg-cobalt-hover:hover {
  background-color: #0050ef !important;
}

.bg-cobalt-focus:focus {
  background-color: #0050ef !important;
}

.bd-cobalt-active:active {
  border-color: #0050ef !important;
}

.bd-cobalt-hover:hover {
  border-color: #0050ef !important;
}

.bd-cobalt-focus:focus {
  border-color: #0050ef !important;
}

.ol-cobalt-active:active {
  outline-color: #0050ef !important;
}

.ol-cobalt-hover:hover {
  outline-color: #0050ef !important;
}

.ol-cobalt-focus:focus {
  outline-color: #0050ef !important;
}

.ribbed-cobalt {
  background: #0050ef
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-cobalt::before {
  background: #0050ef !important;
}

.before-fg-cobalt::before {
  color: #0050ef !important;
}

.after-bg-cobalt::after {
  background: #0050ef !important;
}

.after-fg-cobalt::after {
  color: #0050ef !important;
}

.op-cobalt {
  background-color: rgba(0, 80, 239, 0.7) !important;
}

.tg-cobalt::before {
  border-color: #0050ef;
}

.fg-indigo {
  color: #6a00ff !important;
}

.bg-indigo {
  background-color: #6a00ff !important;
}

.bd-indigo {
  border-color: #6a00ff !important;
}

.ol-indigo {
  outline-color: #6a00ff !important;
}

.fg-indigo-active:active {
  color: #6a00ff !important;
}

.fg-indigo-hover:hover {
  color: #6a00ff !important;
}

.fg-indigo-focus:focus {
  color: #6a00ff !important;
}

.bg-indigo-active:active {
  background-color: #6a00ff !important;
}

.bg-indigo-hover:hover {
  background-color: #6a00ff !important;
}

.bg-indigo-focus:focus {
  background-color: #6a00ff !important;
}

.bd-indigo-active:active {
  border-color: #6a00ff !important;
}

.bd-indigo-hover:hover {
  border-color: #6a00ff !important;
}

.bd-indigo-focus:focus {
  border-color: #6a00ff !important;
}

.ol-indigo-active:active {
  outline-color: #6a00ff !important;
}

.ol-indigo-hover:hover {
  outline-color: #6a00ff !important;
}

.ol-indigo-focus:focus {
  outline-color: #6a00ff !important;
}

.ribbed-indigo {
  background: #6a00ff
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-indigo::before {
  background: #6a00ff !important;
}

.before-fg-indigo::before {
  color: #6a00ff !important;
}

.after-bg-indigo::after {
  background: #6a00ff !important;
}

.after-fg-indigo::after {
  color: #6a00ff !important;
}

.op-indigo {
  background-color: rgba(106, 0, 255, 0.7) !important;
}

.tg-indigo::before {
  border-color: #6a00ff;
}

.fg-violet {
  color: #a0f !important;
}

.bg-violet {
  background-color: #a0f !important;
}

.bd-violet {
  border-color: #a0f !important;
}

.ol-violet {
  outline-color: #a0f !important;
}

.fg-violet-active:active {
  color: #a0f !important;
}

.fg-violet-hover:hover {
  color: #a0f !important;
}

.fg-violet-focus:focus {
  color: #a0f !important;
}

.bg-violet-active:active {
  background-color: #a0f !important;
}

.bg-violet-hover:hover {
  background-color: #a0f !important;
}

.bg-violet-focus:focus {
  background-color: #a0f !important;
}

.bd-violet-active:active {
  border-color: #a0f !important;
}

.bd-violet-hover:hover {
  border-color: #a0f !important;
}

.bd-violet-focus:focus {
  border-color: #a0f !important;
}

.ol-violet-active:active {
  outline-color: #a0f !important;
}

.ol-violet-hover:hover {
  outline-color: #a0f !important;
}

.ol-violet-focus:focus {
  outline-color: #a0f !important;
}

.ribbed-violet {
  background: #a0f
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-violet::before {
  background: #a0f !important;
}

.before-fg-violet::before {
  color: #a0f !important;
}

.after-bg-violet::after {
  background: #a0f !important;
}

.after-fg-violet::after {
  color: #a0f !important;
}

.op-violet {
  background-color: rgba(170, 0, 255, 0.7) !important;
}

.tg-violet::before {
  border-color: #a0f;
}

.fg-pink {
  color: #dc4fad !important;
}

.bg-pink {
  background-color: #dc4fad !important;
}

.bd-pink {
  border-color: #dc4fad !important;
}

.ol-pink {
  outline-color: #dc4fad !important;
}

.fg-pink-active:active {
  color: #dc4fad !important;
}

.fg-pink-hover:hover {
  color: #dc4fad !important;
}

.fg-pink-focus:focus {
  color: #dc4fad !important;
}

.bg-pink-active:active {
  background-color: #dc4fad !important;
}

.bg-pink-hover:hover {
  background-color: #dc4fad !important;
}

.bg-pink-focus:focus {
  background-color: #dc4fad !important;
}

.bd-pink-active:active {
  border-color: #dc4fad !important;
}

.bd-pink-hover:hover {
  border-color: #dc4fad !important;
}

.bd-pink-focus:focus {
  border-color: #dc4fad !important;
}

.ol-pink-active:active {
  outline-color: #dc4fad !important;
}

.ol-pink-hover:hover {
  outline-color: #dc4fad !important;
}

.ol-pink-focus:focus {
  outline-color: #dc4fad !important;
}

.ribbed-pink {
  background: #dc4fad
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-pink::before {
  background: #dc4fad !important;
}

.before-fg-pink::before {
  color: #dc4fad !important;
}

.after-bg-pink::after {
  background: #dc4fad !important;
}

.after-fg-pink::after {
  color: #dc4fad !important;
}

.op-pink {
  background-color: rgba(220, 79, 173, 0.7) !important;
}

.tg-pink::before {
  border-color: #dc4fad;
}

.fg-magenta {
  color: #d80073 !important;
}

.bg-magenta {
  background-color: #d80073 !important;
}

.bd-magenta {
  border-color: #d80073 !important;
}

.ol-magenta {
  outline-color: #d80073 !important;
}

.fg-magenta-active:active {
  color: #d80073 !important;
}

.fg-magenta-hover:hover {
  color: #d80073 !important;
}

.fg-magenta-focus:focus {
  color: #d80073 !important;
}

.bg-magenta-active:active {
  background-color: #d80073 !important;
}

.bg-magenta-hover:hover {
  background-color: #d80073 !important;
}

.bg-magenta-focus:focus {
  background-color: #d80073 !important;
}

.bd-magenta-active:active {
  border-color: #d80073 !important;
}

.bd-magenta-hover:hover {
  border-color: #d80073 !important;
}

.bd-magenta-focus:focus {
  border-color: #d80073 !important;
}

.ol-magenta-active:active {
  outline-color: #d80073 !important;
}

.ol-magenta-hover:hover {
  outline-color: #d80073 !important;
}

.ol-magenta-focus:focus {
  outline-color: #d80073 !important;
}

.ribbed-magenta {
  background: #d80073
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-magenta::before {
  background: #d80073 !important;
}

.before-fg-magenta::before {
  color: #d80073 !important;
}

.after-bg-magenta::after {
  background: #d80073 !important;
}

.after-fg-magenta::after {
  color: #d80073 !important;
}

.op-magenta {
  background-color: rgba(216, 0, 115, 0.7) !important;
}

.tg-magenta::before {
  border-color: #d80073;
}

.fg-crimson {
  color: #a20025 !important;
}

.bg-crimson {
  background-color: #a20025 !important;
}

.bd-crimson {
  border-color: #a20025 !important;
}

.ol-crimson {
  outline-color: #a20025 !important;
}

.fg-crimson-active:active {
  color: #a20025 !important;
}

.fg-crimson-hover:hover {
  color: #a20025 !important;
}

.fg-crimson-focus:focus {
  color: #a20025 !important;
}

.bg-crimson-active:active {
  background-color: #a20025 !important;
}

.bg-crimson-hover:hover {
  background-color: #a20025 !important;
}

.bg-crimson-focus:focus {
  background-color: #a20025 !important;
}

.bd-crimson-active:active {
  border-color: #a20025 !important;
}

.bd-crimson-hover:hover {
  border-color: #a20025 !important;
}

.bd-crimson-focus:focus {
  border-color: #a20025 !important;
}

.ol-crimson-active:active {
  outline-color: #a20025 !important;
}

.ol-crimson-hover:hover {
  outline-color: #a20025 !important;
}

.ol-crimson-focus:focus {
  outline-color: #a20025 !important;
}

.ribbed-crimson {
  background: #a20025
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-crimson::before {
  background: #a20025 !important;
}

.before-fg-crimson::before {
  color: #a20025 !important;
}

.after-bg-crimson::after {
  background: #a20025 !important;
}

.after-fg-crimson::after {
  color: #a20025 !important;
}

.op-crimson {
  background-color: rgba(162, 0, 37, 0.7) !important;
}

.tg-crimson::before {
  border-color: #a20025;
}

.fg-red {
  color: #ce352c !important;
}

.bg-red {
  background-color: #ce352c !important;
}

.bd-red {
  border-color: #ce352c !important;
}

.ol-red {
  outline-color: #ce352c !important;
}

.fg-red-active:active {
  color: #ce352c !important;
}

.fg-red-hover:hover {
  color: #ce352c !important;
}

.fg-red-focus:focus {
  color: #ce352c !important;
}

.bg-red-active:active {
  background-color: #ce352c !important;
}

.bg-red-hover:hover {
  background-color: #ce352c !important;
}

.bg-red-focus:focus {
  background-color: #ce352c !important;
}

.bd-red-active:active {
  border-color: #ce352c !important;
}

.bd-red-hover:hover {
  border-color: #ce352c !important;
}

.bd-red-focus:focus {
  border-color: #ce352c !important;
}

.ol-red-active:active {
  outline-color: #ce352c !important;
}

.ol-red-hover:hover {
  outline-color: #ce352c !important;
}

.ol-red-focus:focus {
  outline-color: #ce352c !important;
}

.ribbed-red {
  background: #ce352c
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-red::before {
  background: #ce352c !important;
}

.before-fg-red::before {
  color: #ce352c !important;
}

.after-bg-red::after {
  background: #ce352c !important;
}

.after-fg-red::after {
  color: #ce352c !important;
}

.op-red {
  background-color: rgba(206, 53, 44, 0.7) !important;
}

.tg-red::before {
  border-color: #ce352c;
}

.fg-orange {
  color: #fa6800 !important;
}

.bg-orange {
  background-color: #fa6800 !important;
}

.bd-orange {
  border-color: #fa6800 !important;
}

.ol-orange {
  outline-color: #fa6800 !important;
}

.fg-orange-active:active {
  color: #fa6800 !important;
}

.fg-orange-hover:hover {
  color: #fa6800 !important;
}

.fg-orange-focus:focus {
  color: #fa6800 !important;
}

.bg-orange-active:active {
  background-color: #fa6800 !important;
}

.bg-orange-hover:hover {
  background-color: #fa6800 !important;
}

.bg-orange-focus:focus {
  background-color: #fa6800 !important;
}

.bd-orange-active:active {
  border-color: #fa6800 !important;
}

.bd-orange-hover:hover {
  border-color: #fa6800 !important;
}

.bd-orange-focus:focus {
  border-color: #fa6800 !important;
}

.ol-orange-active:active {
  outline-color: #fa6800 !important;
}

.ol-orange-hover:hover {
  outline-color: #fa6800 !important;
}

.ol-orange-focus:focus {
  outline-color: #fa6800 !important;
}

.ribbed-orange {
  background: #fa6800
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-orange::before {
  background: #fa6800 !important;
}

.before-fg-orange::before {
  color: #fa6800 !important;
}

.after-bg-orange::after {
  background: #fa6800 !important;
}

.after-fg-orange::after {
  color: #fa6800 !important;
}

.op-orange {
  background-color: rgba(250, 104, 0, 0.7) !important;
}

.tg-orange::before {
  border-color: #fa6800;
}

.fg-amber {
  color: #f0a30a !important;
}

.bg-amber {
  background-color: #f0a30a !important;
}

.bd-amber {
  border-color: #f0a30a !important;
}

.ol-amber {
  outline-color: #f0a30a !important;
}

.fg-amber-active:active {
  color: #f0a30a !important;
}

.fg-amber-hover:hover {
  color: #f0a30a !important;
}

.fg-amber-focus:focus {
  color: #f0a30a !important;
}

.bg-amber-active:active {
  background-color: #f0a30a !important;
}

.bg-amber-hover:hover {
  background-color: #f0a30a !important;
}

.bg-amber-focus:focus {
  background-color: #f0a30a !important;
}

.bd-amber-active:active {
  border-color: #f0a30a !important;
}

.bd-amber-hover:hover {
  border-color: #f0a30a !important;
}

.bd-amber-focus:focus {
  border-color: #f0a30a !important;
}

.ol-amber-active:active {
  outline-color: #f0a30a !important;
}

.ol-amber-hover:hover {
  outline-color: #f0a30a !important;
}

.ol-amber-focus:focus {
  outline-color: #f0a30a !important;
}

.ribbed-amber {
  background: #f0a30a
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-amber::before {
  background: #f0a30a !important;
}

.before-fg-amber::before {
  color: #f0a30a !important;
}

.after-bg-amber::after {
  background: #f0a30a !important;
}

.after-fg-amber::after {
  color: #f0a30a !important;
}

.op-amber {
  background-color: rgba(240, 163, 10, 0.7) !important;
}

.tg-amber::before {
  border-color: #f0a30a;
}

.fg-yellow {
  color: #fff000 !important;
}

.bg-yellow {
  background-color: #fff000 !important;
}

.bd-yellow {
  border-color: #fff000 !important;
}

.ol-yellow {
  outline-color: #fff000 !important;
}

.fg-yellow-active:active {
  color: #fff000 !important;
}

.fg-yellow-hover:hover {
  color: #fff000 !important;
}

.fg-yellow-focus:focus {
  color: #fff000 !important;
}

.bg-yellow-active:active {
  background-color: #fff000 !important;
}

.bg-yellow-hover:hover {
  background-color: #fff000 !important;
}

.bg-yellow-focus:focus {
  background-color: #fff000 !important;
}

.bd-yellow-active:active {
  border-color: #fff000 !important;
}

.bd-yellow-hover:hover {
  border-color: #fff000 !important;
}

.bd-yellow-focus:focus {
  border-color: #fff000 !important;
}

.ol-yellow-active:active {
  outline-color: #fff000 !important;
}

.ol-yellow-hover:hover {
  outline-color: #fff000 !important;
}

.ol-yellow-focus:focus {
  outline-color: #fff000 !important;
}

.ribbed-yellow {
  background: #fff000
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-yellow::before {
  background: #fff000 !important;
}

.before-fg-yellow::before {
  color: #fff000 !important;
}

.after-bg-yellow::after {
  background: #fff000 !important;
}

.after-fg-yellow::after {
  color: #fff000 !important;
}

.op-yellow {
  background-color: rgba(255, 240, 0, 0.7) !important;
}

.tg-yellow::before {
  border-color: #fff000;
}

.fg-brown {
  color: #825a2c !important;
}

.bg-brown {
  background-color: #825a2c !important;
}

.bd-brown {
  border-color: #825a2c !important;
}

.ol-brown {
  outline-color: #825a2c !important;
}

.fg-brown-active:active {
  color: #825a2c !important;
}

.fg-brown-hover:hover {
  color: #825a2c !important;
}

.fg-brown-focus:focus {
  color: #825a2c !important;
}

.bg-brown-active:active {
  background-color: #825a2c !important;
}

.bg-brown-hover:hover {
  background-color: #825a2c !important;
}

.bg-brown-focus:focus {
  background-color: #825a2c !important;
}

.bd-brown-active:active {
  border-color: #825a2c !important;
}

.bd-brown-hover:hover {
  border-color: #825a2c !important;
}

.bd-brown-focus:focus {
  border-color: #825a2c !important;
}

.ol-brown-active:active {
  outline-color: #825a2c !important;
}

.ol-brown-hover:hover {
  outline-color: #825a2c !important;
}

.ol-brown-focus:focus {
  outline-color: #825a2c !important;
}

.ribbed-brown {
  background: #825a2c
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-brown::before {
  background: #825a2c !important;
}

.before-fg-brown::before {
  color: #825a2c !important;
}

.after-bg-brown::after {
  background: #825a2c !important;
}

.after-fg-brown::after {
  color: #825a2c !important;
}

.op-brown {
  background-color: rgba(130, 90, 44, 0.7) !important;
}

.tg-brown::before {
  border-color: #825a2c;
}

.fg-olive {
  color: #6d8764 !important;
}

.bg-olive {
  background-color: #6d8764 !important;
}

.bd-olive {
  border-color: #6d8764 !important;
}

.ol-olive {
  outline-color: #6d8764 !important;
}

.fg-olive-active:active {
  color: #6d8764 !important;
}

.fg-olive-hover:hover {
  color: #6d8764 !important;
}

.fg-olive-focus:focus {
  color: #6d8764 !important;
}

.bg-olive-active:active {
  background-color: #6d8764 !important;
}

.bg-olive-hover:hover {
  background-color: #6d8764 !important;
}

.bg-olive-focus:focus {
  background-color: #6d8764 !important;
}

.bd-olive-active:active {
  border-color: #6d8764 !important;
}

.bd-olive-hover:hover {
  border-color: #6d8764 !important;
}

.bd-olive-focus:focus {
  border-color: #6d8764 !important;
}

.ol-olive-active:active {
  outline-color: #6d8764 !important;
}

.ol-olive-hover:hover {
  outline-color: #6d8764 !important;
}

.ol-olive-focus:focus {
  outline-color: #6d8764 !important;
}

.ribbed-olive {
  background: #6d8764
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-olive::before {
  background: #6d8764 !important;
}

.before-fg-olive::before {
  color: #6d8764 !important;
}

.after-bg-olive::after {
  background: #6d8764 !important;
}

.after-fg-olive::after {
  color: #6d8764 !important;
}

.op-olive {
  background-color: rgba(109, 135, 100, 0.7) !important;
}

.tg-olive::before {
  border-color: #6d8764;
}

.fg-steel {
  color: #647687 !important;
}

.bg-steel {
  background-color: #647687 !important;
}

.bd-steel {
  border-color: #647687 !important;
}

.ol-steel {
  outline-color: #647687 !important;
}

.fg-steel-active:active {
  color: #647687 !important;
}

.fg-steel-hover:hover {
  color: #647687 !important;
}

.fg-steel-focus:focus {
  color: #647687 !important;
}

.bg-steel-active:active {
  background-color: #647687 !important;
}

.bg-steel-hover:hover {
  background-color: #647687 !important;
}

.bg-steel-focus:focus {
  background-color: #647687 !important;
}

.bd-steel-active:active {
  border-color: #647687 !important;
}

.bd-steel-hover:hover {
  border-color: #647687 !important;
}

.bd-steel-focus:focus {
  border-color: #647687 !important;
}

.ol-steel-active:active {
  outline-color: #647687 !important;
}

.ol-steel-hover:hover {
  outline-color: #647687 !important;
}

.ol-steel-focus:focus {
  outline-color: #647687 !important;
}

.ribbed-steel {
  background: #647687
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-steel::before {
  background: #647687 !important;
}

.before-fg-steel::before {
  color: #647687 !important;
}

.after-bg-steel::after {
  background: #647687 !important;
}

.after-fg-steel::after {
  color: #647687 !important;
}

.op-steel {
  background-color: rgba(100, 118, 135, 0.7) !important;
}

.tg-steel::before {
  border-color: #647687;
}

.fg-mauve {
  color: #76608a !important;
}

.bg-mauve {
  background-color: #76608a !important;
}

.bd-mauve {
  border-color: #76608a !important;
}

.ol-mauve {
  outline-color: #76608a !important;
}

.fg-mauve-active:active {
  color: #76608a !important;
}

.fg-mauve-hover:hover {
  color: #76608a !important;
}

.fg-mauve-focus:focus {
  color: #76608a !important;
}

.bg-mauve-active:active {
  background-color: #76608a !important;
}

.bg-mauve-hover:hover {
  background-color: #76608a !important;
}

.bg-mauve-focus:focus {
  background-color: #76608a !important;
}

.bd-mauve-active:active {
  border-color: #76608a !important;
}

.bd-mauve-hover:hover {
  border-color: #76608a !important;
}

.bd-mauve-focus:focus {
  border-color: #76608a !important;
}

.ol-mauve-active:active {
  outline-color: #76608a !important;
}

.ol-mauve-hover:hover {
  outline-color: #76608a !important;
}

.ol-mauve-focus:focus {
  outline-color: #76608a !important;
}

.ribbed-mauve {
  background: #76608a
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-mauve::before {
  background: #76608a !important;
}

.before-fg-mauve::before {
  color: #76608a !important;
}

.after-bg-mauve::after {
  background: #76608a !important;
}

.after-fg-mauve::after {
  color: #76608a !important;
}

.op-mauve {
  background-color: rgba(118, 96, 138, 0.7) !important;
}

.tg-mauve::before {
  border-color: #76608a;
}

.fg-taupe {
  color: #87794e !important;
}

.bg-taupe {
  background-color: #87794e !important;
}

.bd-taupe {
  border-color: #87794e !important;
}

.ol-taupe {
  outline-color: #87794e !important;
}

.fg-taupe-active:active {
  color: #87794e !important;
}

.fg-taupe-hover:hover {
  color: #87794e !important;
}

.fg-taupe-focus:focus {
  color: #87794e !important;
}

.bg-taupe-active:active {
  background-color: #87794e !important;
}

.bg-taupe-hover:hover {
  background-color: #87794e !important;
}

.bg-taupe-focus:focus {
  background-color: #87794e !important;
}

.bd-taupe-active:active {
  border-color: #87794e !important;
}

.bd-taupe-hover:hover {
  border-color: #87794e !important;
}

.bd-taupe-focus:focus {
  border-color: #87794e !important;
}

.ol-taupe-active:active {
  outline-color: #87794e !important;
}

.ol-taupe-hover:hover {
  outline-color: #87794e !important;
}

.ol-taupe-focus:focus {
  outline-color: #87794e !important;
}

.ribbed-taupe {
  background: #87794e
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-taupe::before {
  background: #87794e !important;
}

.before-fg-taupe::before {
  color: #87794e !important;
}

.after-bg-taupe::after {
  background: #87794e !important;
}

.after-fg-taupe::after {
  color: #87794e !important;
}

.op-taupe {
  background-color: rgba(135, 121, 78, 0.7) !important;
}

.tg-taupe::before {
  border-color: #87794e;
}

.fg-gray {
  color: #bebebe !important;
}

.bg-gray {
  background-color: #bebebe !important;
}

.bd-gray {
  border-color: #bebebe !important;
}

.ol-gray {
  outline-color: #bebebe !important;
}

.fg-gray-active:active {
  color: #bebebe !important;
}

.fg-gray-hover:hover {
  color: #bebebe !important;
}

.fg-gray-focus:focus {
  color: #bebebe !important;
}

.bg-gray-active:active {
  background-color: #bebebe !important;
}

.bg-gray-hover:hover {
  background-color: #bebebe !important;
}

.bg-gray-focus:focus {
  background-color: #bebebe !important;
}

.bd-gray-active:active {
  border-color: #bebebe !important;
}

.bd-gray-hover:hover {
  border-color: #bebebe !important;
}

.bd-gray-focus:focus {
  border-color: #bebebe !important;
}

.ol-gray-active:active {
  outline-color: #bebebe !important;
}

.ol-gray-hover:hover {
  outline-color: #bebebe !important;
}

.ol-gray-focus:focus {
  outline-color: #bebebe !important;
}

.ribbed-gray {
  background: #bebebe
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-gray::before {
  background: #bebebe !important;
}

.before-fg-gray::before {
  color: #bebebe !important;
}

.after-bg-gray::after {
  background: #bebebe !important;
}

.after-fg-gray::after {
  color: #bebebe !important;
}

.op-gray {
  background-color: rgba(190, 190, 190, 0.7) !important;
}

.tg-gray::before {
  border-color: #bebebe;
}

.fg-lightLime {
  color: #d8ff12 !important;
}

.bg-lightLime {
  background-color: #d8ff12 !important;
}

.bd-lightLime {
  border-color: #d8ff12 !important;
}

.ol-lightLime {
  outline-color: #d8ff12 !important;
}

.fg-lightLime-active:active {
  color: #d8ff12 !important;
}

.fg-lightLime-hover:hover {
  color: #d8ff12 !important;
}

.fg-lightLime-focus:focus {
  color: #d8ff12 !important;
}

.bg-lightLime-active:active {
  background-color: #d8ff12 !important;
}

.bg-lightLime-hover:hover {
  background-color: #d8ff12 !important;
}

.bg-lightLime-focus:focus {
  background-color: #d8ff12 !important;
}

.bd-lightLime-active:active {
  border-color: #d8ff12 !important;
}

.bd-lightLime-hover:hover {
  border-color: #d8ff12 !important;
}

.bd-lightLime-focus:focus {
  border-color: #d8ff12 !important;
}

.ol-lightLime-active:active {
  outline-color: #d8ff12 !important;
}

.ol-lightLime-hover:hover {
  outline-color: #d8ff12 !important;
}

.ol-lightLime-focus:focus {
  outline-color: #d8ff12 !important;
}

.ribbed-lightLime {
  background: #d8ff12
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightLime::before {
  background: #d8ff12 !important;
}

.before-fg-lightLime::before {
  color: #d8ff12 !important;
}

.after-bg-lightLime::after {
  background: #d8ff12 !important;
}

.after-fg-lightLime::after {
  color: #d8ff12 !important;
}

.op-lightLime {
  background-color: rgba(216, 255, 18, 0.7) !important;
}

.tg-lightLime::before {
  border-color: #d8ff12;
}

.fg-lightGreen {
  color: #86e22a !important;
}

.bg-lightGreen {
  background-color: #86e22a !important;
}

.bd-lightGreen {
  border-color: #86e22a !important;
}

.ol-lightGreen {
  outline-color: #86e22a !important;
}

.fg-lightGreen-active:active {
  color: #86e22a !important;
}

.fg-lightGreen-hover:hover {
  color: #86e22a !important;
}

.fg-lightGreen-focus:focus {
  color: #86e22a !important;
}

.bg-lightGreen-active:active {
  background-color: #86e22a !important;
}

.bg-lightGreen-hover:hover {
  background-color: #86e22a !important;
}

.bg-lightGreen-focus:focus {
  background-color: #86e22a !important;
}

.bd-lightGreen-active:active {
  border-color: #86e22a !important;
}

.bd-lightGreen-hover:hover {
  border-color: #86e22a !important;
}

.bd-lightGreen-focus:focus {
  border-color: #86e22a !important;
}

.ol-lightGreen-active:active {
  outline-color: #86e22a !important;
}

.ol-lightGreen-hover:hover {
  outline-color: #86e22a !important;
}

.ol-lightGreen-focus:focus {
  outline-color: #86e22a !important;
}

.ribbed-lightGreen {
  background: #86e22a
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightGreen::before {
  background: #86e22a !important;
}

.before-fg-lightGreen::before {
  color: #86e22a !important;
}

.after-bg-lightGreen::after {
  background: #86e22a !important;
}

.after-fg-lightGreen::after {
  color: #86e22a !important;
}

.op-lightGreen {
  background-color: rgba(134, 226, 42, 0.7) !important;
}

.tg-lightGreen::before {
  border-color: #86e22a;
}

.fg-lightEmerald {
  color: #00d600 !important;
}

.bg-lightEmerald {
  background-color: #00d600 !important;
}

.bd-lightEmerald {
  border-color: #00d600 !important;
}

.ol-lightEmerald {
  outline-color: #00d600 !important;
}

.fg-lightEmerald-active:active {
  color: #00d600 !important;
}

.fg-lightEmerald-hover:hover {
  color: #00d600 !important;
}

.fg-lightEmerald-focus:focus {
  color: #00d600 !important;
}

.bg-lightEmerald-active:active {
  background-color: #00d600 !important;
}

.bg-lightEmerald-hover:hover {
  background-color: #00d600 !important;
}

.bg-lightEmerald-focus:focus {
  background-color: #00d600 !important;
}

.bd-lightEmerald-active:active {
  border-color: #00d600 !important;
}

.bd-lightEmerald-hover:hover {
  border-color: #00d600 !important;
}

.bd-lightEmerald-focus:focus {
  border-color: #00d600 !important;
}

.ol-lightEmerald-active:active {
  outline-color: #00d600 !important;
}

.ol-lightEmerald-hover:hover {
  outline-color: #00d600 !important;
}

.ol-lightEmerald-focus:focus {
  outline-color: #00d600 !important;
}

.ribbed-lightEmerald {
  background: #00d600
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightEmerald::before {
  background: #00d600 !important;
}

.before-fg-lightEmerald::before {
  color: #00d600 !important;
}

.after-bg-lightEmerald::after {
  background: #00d600 !important;
}

.after-fg-lightEmerald::after {
  color: #00d600 !important;
}

.op-lightEmerald {
  background-color: rgba(0, 214, 0, 0.7) !important;
}

.tg-lightEmerald::before {
  border-color: #00d600;
}

.fg-lightBlue {
  color: #3ecbff !important;
}

.bg-lightBlue {
  background-color: #3ecbff !important;
}

.bd-lightBlue {
  border-color: #3ecbff !important;
}

.ol-lightBlue {
  outline-color: #3ecbff !important;
}

.fg-lightBlue-active:active {
  color: #3ecbff !important;
}

.fg-lightBlue-hover:hover {
  color: #3ecbff !important;
}

.fg-lightBlue-focus:focus {
  color: #3ecbff !important;
}

.bg-lightBlue-active:active {
  background-color: #3ecbff !important;
}

.bg-lightBlue-hover:hover {
  background-color: #3ecbff !important;
}

.bg-lightBlue-focus:focus {
  background-color: #3ecbff !important;
}

.bd-lightBlue-active:active {
  border-color: #3ecbff !important;
}

.bd-lightBlue-hover:hover {
  border-color: #3ecbff !important;
}

.bd-lightBlue-focus:focus {
  border-color: #3ecbff !important;
}

.ol-lightBlue-active:active {
  outline-color: #3ecbff !important;
}

.ol-lightBlue-hover:hover {
  outline-color: #3ecbff !important;
}

.ol-lightBlue-focus:focus {
  outline-color: #3ecbff !important;
}

.ribbed-lightBlue {
  background: #3ecbff
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightBlue::before {
  background: #3ecbff !important;
}

.before-fg-lightBlue::before {
  color: #3ecbff !important;
}

.after-bg-lightBlue::after {
  background: #3ecbff !important;
}

.after-fg-lightBlue::after {
  color: #3ecbff !important;
}

.op-lightBlue {
  background-color: rgba(62, 203, 255, 0.7) !important;
}

.tg-lightBlue::before {
  border-color: #3ecbff;
}

.fg-lightTeal {
  color: #00f7f5 !important;
}

.bg-lightTeal {
  background-color: #00f7f5 !important;
}

.bd-lightTeal {
  border-color: #00f7f5 !important;
}

.ol-lightTeal {
  outline-color: #00f7f5 !important;
}

.fg-lightTeal-active:active {
  color: #00f7f5 !important;
}

.fg-lightTeal-hover:hover {
  color: #00f7f5 !important;
}

.fg-lightTeal-focus:focus {
  color: #00f7f5 !important;
}

.bg-lightTeal-active:active {
  background-color: #00f7f5 !important;
}

.bg-lightTeal-hover:hover {
  background-color: #00f7f5 !important;
}

.bg-lightTeal-focus:focus {
  background-color: #00f7f5 !important;
}

.bd-lightTeal-active:active {
  border-color: #00f7f5 !important;
}

.bd-lightTeal-hover:hover {
  border-color: #00f7f5 !important;
}

.bd-lightTeal-focus:focus {
  border-color: #00f7f5 !important;
}

.ol-lightTeal-active:active {
  outline-color: #00f7f5 !important;
}

.ol-lightTeal-hover:hover {
  outline-color: #00f7f5 !important;
}

.ol-lightTeal-focus:focus {
  outline-color: #00f7f5 !important;
}

.ribbed-lightTeal {
  background: #00f7f5
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightTeal::before {
  background: #00f7f5 !important;
}

.before-fg-lightTeal::before {
  color: #00f7f5 !important;
}

.after-bg-lightTeal::after {
  background: #00f7f5 !important;
}

.after-fg-lightTeal::after {
  color: #00f7f5 !important;
}

.op-lightTeal {
  background-color: rgba(0, 247, 245, 0.7) !important;
}

.tg-lightTeal::before {
  border-color: #00f7f5;
}

.fg-lightCyan {
  color: #5ebdec !important;
}

.bg-lightCyan {
  background-color: #5ebdec !important;
}

.bd-lightCyan {
  border-color: #5ebdec !important;
}

.ol-lightCyan {
  outline-color: #5ebdec !important;
}

.fg-lightCyan-active:active {
  color: #5ebdec !important;
}

.fg-lightCyan-hover:hover {
  color: #5ebdec !important;
}

.fg-lightCyan-focus:focus {
  color: #5ebdec !important;
}

.bg-lightCyan-active:active {
  background-color: #5ebdec !important;
}

.bg-lightCyan-hover:hover {
  background-color: #5ebdec !important;
}

.bg-lightCyan-focus:focus {
  background-color: #5ebdec !important;
}

.bd-lightCyan-active:active {
  border-color: #5ebdec !important;
}

.bd-lightCyan-hover:hover {
  border-color: #5ebdec !important;
}

.bd-lightCyan-focus:focus {
  border-color: #5ebdec !important;
}

.ol-lightCyan-active:active {
  outline-color: #5ebdec !important;
}

.ol-lightCyan-hover:hover {
  outline-color: #5ebdec !important;
}

.ol-lightCyan-focus:focus {
  outline-color: #5ebdec !important;
}

.ribbed-lightCyan {
  background: #5ebdec
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightCyan::before {
  background: #5ebdec !important;
}

.before-fg-lightCyan::before {
  color: #5ebdec !important;
}

.after-bg-lightCyan::after {
  background: #5ebdec !important;
}

.after-fg-lightCyan::after {
  color: #5ebdec !important;
}

.op-lightCyan {
  background-color: rgba(94, 189, 236, 0.7) !important;
}

.tg-lightCyan::before {
  border-color: #5ebdec;
}

.fg-lightCobalt {
  color: #3d7eff !important;
}

.bg-lightCobalt {
  background-color: #3d7eff !important;
}

.bd-lightCobalt {
  border-color: #3d7eff !important;
}

.ol-lightCobalt {
  outline-color: #3d7eff !important;
}

.fg-lightCobalt-active:active {
  color: #3d7eff !important;
}

.fg-lightCobalt-hover:hover {
  color: #3d7eff !important;
}

.fg-lightCobalt-focus:focus {
  color: #3d7eff !important;
}

.bg-lightCobalt-active:active {
  background-color: #3d7eff !important;
}

.bg-lightCobalt-hover:hover {
  background-color: #3d7eff !important;
}

.bg-lightCobalt-focus:focus {
  background-color: #3d7eff !important;
}

.bd-lightCobalt-active:active {
  border-color: #3d7eff !important;
}

.bd-lightCobalt-hover:hover {
  border-color: #3d7eff !important;
}

.bd-lightCobalt-focus:focus {
  border-color: #3d7eff !important;
}

.ol-lightCobalt-active:active {
  outline-color: #3d7eff !important;
}

.ol-lightCobalt-hover:hover {
  outline-color: #3d7eff !important;
}

.ol-lightCobalt-focus:focus {
  outline-color: #3d7eff !important;
}

.ribbed-lightCobalt {
  background: #3d7eff
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightCobalt::before {
  background: #3d7eff !important;
}

.before-fg-lightCobalt::before {
  color: #3d7eff !important;
}

.after-bg-lightCobalt::after {
  background: #3d7eff !important;
}

.after-fg-lightCobalt::after {
  color: #3d7eff !important;
}

.op-lightCobalt {
  background-color: rgba(61, 126, 255, 0.7) !important;
}

.tg-lightCobalt::before {
  border-color: #3d7eff;
}

.fg-lightIndigo {
  color: #974dff !important;
}

.bg-lightIndigo {
  background-color: #974dff !important;
}

.bd-lightIndigo {
  border-color: #974dff !important;
}

.ol-lightIndigo {
  outline-color: #974dff !important;
}

.fg-lightIndigo-active:active {
  color: #974dff !important;
}

.fg-lightIndigo-hover:hover {
  color: #974dff !important;
}

.fg-lightIndigo-focus:focus {
  color: #974dff !important;
}

.bg-lightIndigo-active:active {
  background-color: #974dff !important;
}

.bg-lightIndigo-hover:hover {
  background-color: #974dff !important;
}

.bg-lightIndigo-focus:focus {
  background-color: #974dff !important;
}

.bd-lightIndigo-active:active {
  border-color: #974dff !important;
}

.bd-lightIndigo-hover:hover {
  border-color: #974dff !important;
}

.bd-lightIndigo-focus:focus {
  border-color: #974dff !important;
}

.ol-lightIndigo-active:active {
  outline-color: #974dff !important;
}

.ol-lightIndigo-hover:hover {
  outline-color: #974dff !important;
}

.ol-lightIndigo-focus:focus {
  outline-color: #974dff !important;
}

.ribbed-lightIndigo {
  background: #974dff
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightIndigo::before {
  background: #974dff !important;
}

.before-fg-lightIndigo::before {
  color: #974dff !important;
}

.after-bg-lightIndigo::after {
  background: #974dff !important;
}

.after-fg-lightIndigo::after {
  color: #974dff !important;
}

.op-lightIndigo {
  background-color: rgba(151, 77, 255, 0.7) !important;
}

.tg-lightIndigo::before {
  border-color: #974dff;
}

.fg-lightViolet {
  color: #c44dff !important;
}

.bg-lightViolet {
  background-color: #c44dff !important;
}

.bd-lightViolet {
  border-color: #c44dff !important;
}

.ol-lightViolet {
  outline-color: #c44dff !important;
}

.fg-lightViolet-active:active {
  color: #c44dff !important;
}

.fg-lightViolet-hover:hover {
  color: #c44dff !important;
}

.fg-lightViolet-focus:focus {
  color: #c44dff !important;
}

.bg-lightViolet-active:active {
  background-color: #c44dff !important;
}

.bg-lightViolet-hover:hover {
  background-color: #c44dff !important;
}

.bg-lightViolet-focus:focus {
  background-color: #c44dff !important;
}

.bd-lightViolet-active:active {
  border-color: #c44dff !important;
}

.bd-lightViolet-hover:hover {
  border-color: #c44dff !important;
}

.bd-lightViolet-focus:focus {
  border-color: #c44dff !important;
}

.ol-lightViolet-active:active {
  outline-color: #c44dff !important;
}

.ol-lightViolet-hover:hover {
  outline-color: #c44dff !important;
}

.ol-lightViolet-focus:focus {
  outline-color: #c44dff !important;
}

.ribbed-lightViolet {
  background: #c44dff
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightViolet::before {
  background: #c44dff !important;
}

.before-fg-lightViolet::before {
  color: #c44dff !important;
}

.after-bg-lightViolet::after {
  background: #c44dff !important;
}

.after-fg-lightViolet::after {
  color: #c44dff !important;
}

.op-lightViolet {
  background-color: rgba(196, 77, 255, 0.7) !important;
}

.tg-lightViolet::before {
  border-color: #c44dff;
}

.fg-lightPink {
  color: #e98fcb !important;
}

.bg-lightPink {
  background-color: #e98fcb !important;
}

.bd-lightPink {
  border-color: #e98fcb !important;
}

.ol-lightPink {
  outline-color: #e98fcb !important;
}

.fg-lightPink-active:active {
  color: #e98fcb !important;
}

.fg-lightPink-hover:hover {
  color: #e98fcb !important;
}

.fg-lightPink-focus:focus {
  color: #e98fcb !important;
}

.bg-lightPink-active:active {
  background-color: #e98fcb !important;
}

.bg-lightPink-hover:hover {
  background-color: #e98fcb !important;
}

.bg-lightPink-focus:focus {
  background-color: #e98fcb !important;
}

.bd-lightPink-active:active {
  border-color: #e98fcb !important;
}

.bd-lightPink-hover:hover {
  border-color: #e98fcb !important;
}

.bd-lightPink-focus:focus {
  border-color: #e98fcb !important;
}

.ol-lightPink-active:active {
  outline-color: #e98fcb !important;
}

.ol-lightPink-hover:hover {
  outline-color: #e98fcb !important;
}

.ol-lightPink-focus:focus {
  outline-color: #e98fcb !important;
}

.ribbed-lightPink {
  background: #e98fcb
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightPink::before {
  background: #e98fcb !important;
}

.before-fg-lightPink::before {
  color: #e98fcb !important;
}

.after-bg-lightPink::after {
  background: #e98fcb !important;
}

.after-fg-lightPink::after {
  color: #e98fcb !important;
}

.op-lightPink {
  background-color: rgba(233, 143, 203, 0.7) !important;
}

.tg-lightPink::before {
  border-color: #e98fcb;
}

.fg-lightMagenta {
  color: #ff2599 !important;
}

.bg-lightMagenta {
  background-color: #ff2599 !important;
}

.bd-lightMagenta {
  border-color: #ff2599 !important;
}

.ol-lightMagenta {
  outline-color: #ff2599 !important;
}

.fg-lightMagenta-active:active {
  color: #ff2599 !important;
}

.fg-lightMagenta-hover:hover {
  color: #ff2599 !important;
}

.fg-lightMagenta-focus:focus {
  color: #ff2599 !important;
}

.bg-lightMagenta-active:active {
  background-color: #ff2599 !important;
}

.bg-lightMagenta-hover:hover {
  background-color: #ff2599 !important;
}

.bg-lightMagenta-focus:focus {
  background-color: #ff2599 !important;
}

.bd-lightMagenta-active:active {
  border-color: #ff2599 !important;
}

.bd-lightMagenta-hover:hover {
  border-color: #ff2599 !important;
}

.bd-lightMagenta-focus:focus {
  border-color: #ff2599 !important;
}

.ol-lightMagenta-active:active {
  outline-color: #ff2599 !important;
}

.ol-lightMagenta-hover:hover {
  outline-color: #ff2599 !important;
}

.ol-lightMagenta-focus:focus {
  outline-color: #ff2599 !important;
}

.ribbed-lightMagenta {
  background: #ff2599
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightMagenta::before {
  background: #ff2599 !important;
}

.before-fg-lightMagenta::before {
  color: #ff2599 !important;
}

.after-bg-lightMagenta::after {
  background: #ff2599 !important;
}

.after-fg-lightMagenta::after {
  color: #ff2599 !important;
}

.op-lightMagenta {
  background-color: rgba(255, 37, 153, 0.7) !important;
}

.tg-lightMagenta::before {
  border-color: #ff2599;
}

.fg-lightCrimson {
  color: #ef0036 !important;
}

.bg-lightCrimson {
  background-color: #ef0036 !important;
}

.bd-lightCrimson {
  border-color: #ef0036 !important;
}

.ol-lightCrimson {
  outline-color: #ef0036 !important;
}

.fg-lightCrimson-active:active {
  color: #ef0036 !important;
}

.fg-lightCrimson-hover:hover {
  color: #ef0036 !important;
}

.fg-lightCrimson-focus:focus {
  color: #ef0036 !important;
}

.bg-lightCrimson-active:active {
  background-color: #ef0036 !important;
}

.bg-lightCrimson-hover:hover {
  background-color: #ef0036 !important;
}

.bg-lightCrimson-focus:focus {
  background-color: #ef0036 !important;
}

.bd-lightCrimson-active:active {
  border-color: #ef0036 !important;
}

.bd-lightCrimson-hover:hover {
  border-color: #ef0036 !important;
}

.bd-lightCrimson-focus:focus {
  border-color: #ef0036 !important;
}

.ol-lightCrimson-active:active {
  outline-color: #ef0036 !important;
}

.ol-lightCrimson-hover:hover {
  outline-color: #ef0036 !important;
}

.ol-lightCrimson-focus:focus {
  outline-color: #ef0036 !important;
}

.ribbed-lightCrimson {
  background: #ef0036
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightCrimson::before {
  background: #ef0036 !important;
}

.before-fg-lightCrimson::before {
  color: #ef0036 !important;
}

.after-bg-lightCrimson::after {
  background: #ef0036 !important;
}

.after-fg-lightCrimson::after {
  color: #ef0036 !important;
}

.op-lightCrimson {
  background-color: rgba(239, 0, 54, 0.7) !important;
}

.tg-lightCrimson::before {
  border-color: #ef0036;
}

.fg-lightRed {
  color: #df6e68 !important;
}

.bg-lightRed {
  background-color: #df6e68 !important;
}

.bd-lightRed {
  border-color: #df6e68 !important;
}

.ol-lightRed {
  outline-color: #df6e68 !important;
}

.fg-lightRed-active:active {
  color: #df6e68 !important;
}

.fg-lightRed-hover:hover {
  color: #df6e68 !important;
}

.fg-lightRed-focus:focus {
  color: #df6e68 !important;
}

.bg-lightRed-active:active {
  background-color: #df6e68 !important;
}

.bg-lightRed-hover:hover {
  background-color: #df6e68 !important;
}

.bg-lightRed-focus:focus {
  background-color: #df6e68 !important;
}

.bd-lightRed-active:active {
  border-color: #df6e68 !important;
}

.bd-lightRed-hover:hover {
  border-color: #df6e68 !important;
}

.bd-lightRed-focus:focus {
  border-color: #df6e68 !important;
}

.ol-lightRed-active:active {
  outline-color: #df6e68 !important;
}

.ol-lightRed-hover:hover {
  outline-color: #df6e68 !important;
}

.ol-lightRed-focus:focus {
  outline-color: #df6e68 !important;
}

.ribbed-lightRed {
  background: #df6e68
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightRed::before {
  background: #df6e68 !important;
}

.before-fg-lightRed::before {
  color: #df6e68 !important;
}

.after-bg-lightRed::after {
  background: #df6e68 !important;
}

.after-fg-lightRed::after {
  color: #df6e68 !important;
}

.op-lightRed {
  background-color: rgba(223, 110, 104, 0.7) !important;
}

.tg-lightRed::before {
  border-color: #df6e68;
}

.fg-lightOrange {
  color: #ff9447 !important;
}

.bg-lightOrange {
  background-color: #ff9447 !important;
}

.bd-lightOrange {
  border-color: #ff9447 !important;
}

.ol-lightOrange {
  outline-color: #ff9447 !important;
}

.fg-lightOrange-active:active {
  color: #ff9447 !important;
}

.fg-lightOrange-hover:hover {
  color: #ff9447 !important;
}

.fg-lightOrange-focus:focus {
  color: #ff9447 !important;
}

.bg-lightOrange-active:active {
  background-color: #ff9447 !important;
}

.bg-lightOrange-hover:hover {
  background-color: #ff9447 !important;
}

.bg-lightOrange-focus:focus {
  background-color: #ff9447 !important;
}

.bd-lightOrange-active:active {
  border-color: #ff9447 !important;
}

.bd-lightOrange-hover:hover {
  border-color: #ff9447 !important;
}

.bd-lightOrange-focus:focus {
  border-color: #ff9447 !important;
}

.ol-lightOrange-active:active {
  outline-color: #ff9447 !important;
}

.ol-lightOrange-hover:hover {
  outline-color: #ff9447 !important;
}

.ol-lightOrange-focus:focus {
  outline-color: #ff9447 !important;
}

.ribbed-lightOrange {
  background: #ff9447
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightOrange::before {
  background: #ff9447 !important;
}

.before-fg-lightOrange::before {
  color: #ff9447 !important;
}

.after-bg-lightOrange::after {
  background: #ff9447 !important;
}

.after-fg-lightOrange::after {
  color: #ff9447 !important;
}

.op-lightOrange {
  background-color: rgba(255, 148, 71, 0.7) !important;
}

.tg-lightOrange::before {
  border-color: #ff9447;
}

.fg-lightAmber {
  color: #f8bf4f !important;
}

.bg-lightAmber {
  background-color: #f8bf4f !important;
}

.bd-lightAmber {
  border-color: #f8bf4f !important;
}

.ol-lightAmber {
  outline-color: #f8bf4f !important;
}

.fg-lightAmber-active:active {
  color: #f8bf4f !important;
}

.fg-lightAmber-hover:hover {
  color: #f8bf4f !important;
}

.fg-lightAmber-focus:focus {
  color: #f8bf4f !important;
}

.bg-lightAmber-active:active {
  background-color: #f8bf4f !important;
}

.bg-lightAmber-hover:hover {
  background-color: #f8bf4f !important;
}

.bg-lightAmber-focus:focus {
  background-color: #f8bf4f !important;
}

.bd-lightAmber-active:active {
  border-color: #f8bf4f !important;
}

.bd-lightAmber-hover:hover {
  border-color: #f8bf4f !important;
}

.bd-lightAmber-focus:focus {
  border-color: #f8bf4f !important;
}

.ol-lightAmber-active:active {
  outline-color: #f8bf4f !important;
}

.ol-lightAmber-hover:hover {
  outline-color: #f8bf4f !important;
}

.ol-lightAmber-focus:focus {
  outline-color: #f8bf4f !important;
}

.ribbed-lightAmber {
  background: #f8bf4f
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightAmber::before {
  background: #f8bf4f !important;
}

.before-fg-lightAmber::before {
  color: #f8bf4f !important;
}

.after-bg-lightAmber::after {
  background: #f8bf4f !important;
}

.after-fg-lightAmber::after {
  color: #f8bf4f !important;
}

.op-lightAmber {
  background-color: rgba(248, 191, 79, 0.7) !important;
}

.tg-lightAmber::before {
  border-color: #f8bf4f;
}

.fg-lightYellow {
  color: #fff44d !important;
}

.bg-lightYellow {
  background-color: #fff44d !important;
}

.bd-lightYellow {
  border-color: #fff44d !important;
}

.ol-lightYellow {
  outline-color: #fff44d !important;
}

.fg-lightYellow-active:active {
  color: #fff44d !important;
}

.fg-lightYellow-hover:hover {
  color: #fff44d !important;
}

.fg-lightYellow-focus:focus {
  color: #fff44d !important;
}

.bg-lightYellow-active:active {
  background-color: #fff44d !important;
}

.bg-lightYellow-hover:hover {
  background-color: #fff44d !important;
}

.bg-lightYellow-focus:focus {
  background-color: #fff44d !important;
}

.bd-lightYellow-active:active {
  border-color: #fff44d !important;
}

.bd-lightYellow-hover:hover {
  border-color: #fff44d !important;
}

.bd-lightYellow-focus:focus {
  border-color: #fff44d !important;
}

.ol-lightYellow-active:active {
  outline-color: #fff44d !important;
}

.ol-lightYellow-hover:hover {
  outline-color: #fff44d !important;
}

.ol-lightYellow-focus:focus {
  outline-color: #fff44d !important;
}

.ribbed-lightYellow {
  background: #fff44d
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightYellow::before {
  background: #fff44d !important;
}

.before-fg-lightYellow::before {
  color: #fff44d !important;
}

.after-bg-lightYellow::after {
  background: #fff44d !important;
}

.after-fg-lightYellow::after {
  color: #fff44d !important;
}

.op-lightYellow {
  background-color: rgba(255, 244, 77, 0.7) !important;
}

.tg-lightYellow::before {
  border-color: #fff44d;
}

.fg-lightBrown {
  color: #bb823f !important;
}

.bg-lightBrown {
  background-color: #bb823f !important;
}

.bd-lightBrown {
  border-color: #bb823f !important;
}

.ol-lightBrown {
  outline-color: #bb823f !important;
}

.fg-lightBrown-active:active {
  color: #bb823f !important;
}

.fg-lightBrown-hover:hover {
  color: #bb823f !important;
}

.fg-lightBrown-focus:focus {
  color: #bb823f !important;
}

.bg-lightBrown-active:active {
  background-color: #bb823f !important;
}

.bg-lightBrown-hover:hover {
  background-color: #bb823f !important;
}

.bg-lightBrown-focus:focus {
  background-color: #bb823f !important;
}

.bd-lightBrown-active:active {
  border-color: #bb823f !important;
}

.bd-lightBrown-hover:hover {
  border-color: #bb823f !important;
}

.bd-lightBrown-focus:focus {
  border-color: #bb823f !important;
}

.ol-lightBrown-active:active {
  outline-color: #bb823f !important;
}

.ol-lightBrown-hover:hover {
  outline-color: #bb823f !important;
}

.ol-lightBrown-focus:focus {
  outline-color: #bb823f !important;
}

.ribbed-lightBrown {
  background: #bb823f
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightBrown::before {
  background: #bb823f !important;
}

.before-fg-lightBrown::before {
  color: #bb823f !important;
}

.after-bg-lightBrown::after {
  background: #bb823f !important;
}

.after-fg-lightBrown::after {
  color: #bb823f !important;
}

.op-lightBrown {
  background-color: rgba(187, 130, 63, 0.7) !important;
}

.tg-lightBrown::before {
  border-color: #bb823f;
}

.fg-lightOlive {
  color: #95ab8d !important;
}

.bg-lightOlive {
  background-color: #95ab8d !important;
}

.bd-lightOlive {
  border-color: #95ab8d !important;
}

.ol-lightOlive {
  outline-color: #95ab8d !important;
}

.fg-lightOlive-active:active {
  color: #95ab8d !important;
}

.fg-lightOlive-hover:hover {
  color: #95ab8d !important;
}

.fg-lightOlive-focus:focus {
  color: #95ab8d !important;
}

.bg-lightOlive-active:active {
  background-color: #95ab8d !important;
}

.bg-lightOlive-hover:hover {
  background-color: #95ab8d !important;
}

.bg-lightOlive-focus:focus {
  background-color: #95ab8d !important;
}

.bd-lightOlive-active:active {
  border-color: #95ab8d !important;
}

.bd-lightOlive-hover:hover {
  border-color: #95ab8d !important;
}

.bd-lightOlive-focus:focus {
  border-color: #95ab8d !important;
}

.ol-lightOlive-active:active {
  outline-color: #95ab8d !important;
}

.ol-lightOlive-hover:hover {
  outline-color: #95ab8d !important;
}

.ol-lightOlive-focus:focus {
  outline-color: #95ab8d !important;
}

.ribbed-lightOlive {
  background: #95ab8d
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightOlive::before {
  background: #95ab8d !important;
}

.before-fg-lightOlive::before {
  color: #95ab8d !important;
}

.after-bg-lightOlive::after {
  background: #95ab8d !important;
}

.after-fg-lightOlive::after {
  color: #95ab8d !important;
}

.op-lightOlive {
  background-color: rgba(149, 171, 141, 0.7) !important;
}

.tg-lightOlive::before {
  border-color: #95ab8d;
}

.fg-lightSteel {
  color: #8d9cab !important;
}

.bg-lightSteel {
  background-color: #8d9cab !important;
}

.bd-lightSteel {
  border-color: #8d9cab !important;
}

.ol-lightSteel {
  outline-color: #8d9cab !important;
}

.fg-lightSteel-active:active {
  color: #8d9cab !important;
}

.fg-lightSteel-hover:hover {
  color: #8d9cab !important;
}

.fg-lightSteel-focus:focus {
  color: #8d9cab !important;
}

.bg-lightSteel-active:active {
  background-color: #8d9cab !important;
}

.bg-lightSteel-hover:hover {
  background-color: #8d9cab !important;
}

.bg-lightSteel-focus:focus {
  background-color: #8d9cab !important;
}

.bd-lightSteel-active:active {
  border-color: #8d9cab !important;
}

.bd-lightSteel-hover:hover {
  border-color: #8d9cab !important;
}

.bd-lightSteel-focus:focus {
  border-color: #8d9cab !important;
}

.ol-lightSteel-active:active {
  outline-color: #8d9cab !important;
}

.ol-lightSteel-hover:hover {
  outline-color: #8d9cab !important;
}

.ol-lightSteel-focus:focus {
  outline-color: #8d9cab !important;
}

.ribbed-lightSteel {
  background: #8d9cab
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightSteel::before {
  background: #8d9cab !important;
}

.before-fg-lightSteel::before {
  color: #8d9cab !important;
}

.after-bg-lightSteel::after {
  background: #8d9cab !important;
}

.after-fg-lightSteel::after {
  color: #8d9cab !important;
}

.op-lightSteel {
  background-color: rgba(141, 156, 171, 0.7) !important;
}

.tg-lightSteel::before {
  border-color: #8d9cab;
}

.fg-lightMauve {
  color: #9c89ad !important;
}

.bg-lightMauve {
  background-color: #9c89ad !important;
}

.bd-lightMauve {
  border-color: #9c89ad !important;
}

.ol-lightMauve {
  outline-color: #9c89ad !important;
}

.fg-lightMauve-active:active {
  color: #9c89ad !important;
}

.fg-lightMauve-hover:hover {
  color: #9c89ad !important;
}

.fg-lightMauve-focus:focus {
  color: #9c89ad !important;
}

.bg-lightMauve-active:active {
  background-color: #9c89ad !important;
}

.bg-lightMauve-hover:hover {
  background-color: #9c89ad !important;
}

.bg-lightMauve-focus:focus {
  background-color: #9c89ad !important;
}

.bd-lightMauve-active:active {
  border-color: #9c89ad !important;
}

.bd-lightMauve-hover:hover {
  border-color: #9c89ad !important;
}

.bd-lightMauve-focus:focus {
  border-color: #9c89ad !important;
}

.ol-lightMauve-active:active {
  outline-color: #9c89ad !important;
}

.ol-lightMauve-hover:hover {
  outline-color: #9c89ad !important;
}

.ol-lightMauve-focus:focus {
  outline-color: #9c89ad !important;
}

.ribbed-lightMauve {
  background: #9c89ad
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightMauve::before {
  background: #9c89ad !important;
}

.before-fg-lightMauve::before {
  color: #9c89ad !important;
}

.after-bg-lightMauve::after {
  background: #9c89ad !important;
}

.after-fg-lightMauve::after {
  color: #9c89ad !important;
}

.op-lightMauve {
  background-color: rgba(156, 137, 173, 0.7) !important;
}

.tg-lightMauve::before {
  border-color: #9c89ad;
}

.fg-lightTaupe {
  color: #aea073 !important;
}

.bg-lightTaupe {
  background-color: #aea073 !important;
}

.bd-lightTaupe {
  border-color: #aea073 !important;
}

.ol-lightTaupe {
  outline-color: #aea073 !important;
}

.fg-lightTaupe-active:active {
  color: #aea073 !important;
}

.fg-lightTaupe-hover:hover {
  color: #aea073 !important;
}

.fg-lightTaupe-focus:focus {
  color: #aea073 !important;
}

.bg-lightTaupe-active:active {
  background-color: #aea073 !important;
}

.bg-lightTaupe-hover:hover {
  background-color: #aea073 !important;
}

.bg-lightTaupe-focus:focus {
  background-color: #aea073 !important;
}

.bd-lightTaupe-active:active {
  border-color: #aea073 !important;
}

.bd-lightTaupe-hover:hover {
  border-color: #aea073 !important;
}

.bd-lightTaupe-focus:focus {
  border-color: #aea073 !important;
}

.ol-lightTaupe-active:active {
  outline-color: #aea073 !important;
}

.ol-lightTaupe-hover:hover {
  outline-color: #aea073 !important;
}

.ol-lightTaupe-focus:focus {
  outline-color: #aea073 !important;
}

.ribbed-lightTaupe {
  background: #aea073
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightTaupe::before {
  background: #aea073 !important;
}

.before-fg-lightTaupe::before {
  color: #aea073 !important;
}

.after-bg-lightTaupe::after {
  background: #aea073 !important;
}

.after-fg-lightTaupe::after {
  color: #aea073 !important;
}

.op-lightTaupe {
  background-color: rgba(174, 160, 115, 0.7) !important;
}

.tg-lightTaupe::before {
  border-color: #aea073;
}

.fg-lightGray {
  color: #e4e4e4 !important;
}

.bg-lightGray {
  background-color: #e4e4e4 !important;
}

.bd-lightGray {
  border-color: #e4e4e4 !important;
}

.ol-lightGray {
  outline-color: #e4e4e4 !important;
}

.fg-lightGray-active:active {
  color: #e4e4e4 !important;
}

.fg-lightGray-hover:hover {
  color: #e4e4e4 !important;
}

.fg-lightGray-focus:focus {
  color: #e4e4e4 !important;
}

.bg-lightGray-active:active {
  background-color: #e4e4e4 !important;
}

.bg-lightGray-hover:hover {
  background-color: #e4e4e4 !important;
}

.bg-lightGray-focus:focus {
  background-color: #e4e4e4 !important;
}

.bd-lightGray-active:active {
  border-color: #e4e4e4 !important;
}

.bd-lightGray-hover:hover {
  border-color: #e4e4e4 !important;
}

.bd-lightGray-focus:focus {
  border-color: #e4e4e4 !important;
}

.ol-lightGray-active:active {
  outline-color: #e4e4e4 !important;
}

.ol-lightGray-hover:hover {
  outline-color: #e4e4e4 !important;
}

.ol-lightGray-focus:focus {
  outline-color: #e4e4e4 !important;
}

.ribbed-lightGray {
  background: #e4e4e4
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightGray::before {
  background: #e4e4e4 !important;
}

.before-fg-lightGray::before {
  color: #e4e4e4 !important;
}

.after-bg-lightGray::after {
  background: #e4e4e4 !important;
}

.after-fg-lightGray::after {
  color: #e4e4e4 !important;
}

.op-lightGray {
  background-color: rgba(228, 228, 228, 0.7) !important;
}

.tg-lightGray::before {
  border-color: #e4e4e4;
}

.fg-lightGrayBlue {
  color: #8aa2ae !important;
}

.bg-lightGrayBlue {
  background-color: #8aa2ae !important;
}

.bd-lightGrayBlue {
  border-color: #8aa2ae !important;
}

.ol-lightGrayBlue {
  outline-color: #8aa2ae !important;
}

.fg-lightGrayBlue-active:active {
  color: #8aa2ae !important;
}

.fg-lightGrayBlue-hover:hover {
  color: #8aa2ae !important;
}

.fg-lightGrayBlue-focus:focus {
  color: #8aa2ae !important;
}

.bg-lightGrayBlue-active:active {
  background-color: #8aa2ae !important;
}

.bg-lightGrayBlue-hover:hover {
  background-color: #8aa2ae !important;
}

.bg-lightGrayBlue-focus:focus {
  background-color: #8aa2ae !important;
}

.bd-lightGrayBlue-active:active {
  border-color: #8aa2ae !important;
}

.bd-lightGrayBlue-hover:hover {
  border-color: #8aa2ae !important;
}

.bd-lightGrayBlue-focus:focus {
  border-color: #8aa2ae !important;
}

.ol-lightGrayBlue-active:active {
  outline-color: #8aa2ae !important;
}

.ol-lightGrayBlue-hover:hover {
  outline-color: #8aa2ae !important;
}

.ol-lightGrayBlue-focus:focus {
  outline-color: #8aa2ae !important;
}

.ribbed-lightGrayBlue {
  background: #8aa2ae
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-lightGrayBlue::before {
  background: #8aa2ae !important;
}

.before-fg-lightGrayBlue::before {
  color: #8aa2ae !important;
}

.after-bg-lightGrayBlue::after {
  background: #8aa2ae !important;
}

.after-fg-lightGrayBlue::after {
  color: #8aa2ae !important;
}

.op-lightGrayBlue {
  background-color: rgba(138, 162, 174, 0.7) !important;
}

.tg-lightGrayBlue::before {
  border-color: #8aa2ae;
}

.fg-darkLime {
  color: #647800 !important;
}

.bg-darkLime {
  background-color: #647800 !important;
}

.bd-darkLime {
  border-color: #647800 !important;
}

.ol-darkLime {
  outline-color: #647800 !important;
}

.fg-darkLime-active:active {
  color: #647800 !important;
}

.fg-darkLime-hover:hover {
  color: #647800 !important;
}

.fg-darkLime-focus:focus {
  color: #647800 !important;
}

.bg-darkLime-active:active {
  background-color: #647800 !important;
}

.bg-darkLime-hover:hover {
  background-color: #647800 !important;
}

.bg-darkLime-focus:focus {
  background-color: #647800 !important;
}

.bd-darkLime-active:active {
  border-color: #647800 !important;
}

.bd-darkLime-hover:hover {
  border-color: #647800 !important;
}

.bd-darkLime-focus:focus {
  border-color: #647800 !important;
}

.ol-darkLime-active:active {
  outline-color: #647800 !important;
}

.ol-darkLime-hover:hover {
  outline-color: #647800 !important;
}

.ol-darkLime-focus:focus {
  outline-color: #647800 !important;
}

.ribbed-darkLime {
  background: #647800
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkLime::before {
  background: #647800 !important;
}

.before-fg-darkLime::before {
  color: #647800 !important;
}

.after-bg-darkLime::after {
  background: #647800 !important;
}

.after-fg-darkLime::after {
  color: #647800 !important;
}

.op-darkLime {
  background-color: rgba(100, 120, 0, 0.7) !important;
}

.tg-darkLime::before {
  border-color: #647800;
}

.fg-darkGreen {
  color: #3a660e !important;
}

.bg-darkGreen {
  background-color: #3a660e !important;
}

.bd-darkGreen {
  border-color: #3a660e !important;
}

.ol-darkGreen {
  outline-color: #3a660e !important;
}

.fg-darkGreen-active:active {
  color: #3a660e !important;
}

.fg-darkGreen-hover:hover {
  color: #3a660e !important;
}

.fg-darkGreen-focus:focus {
  color: #3a660e !important;
}

.bg-darkGreen-active:active {
  background-color: #3a660e !important;
}

.bg-darkGreen-hover:hover {
  background-color: #3a660e !important;
}

.bg-darkGreen-focus:focus {
  background-color: #3a660e !important;
}

.bd-darkGreen-active:active {
  border-color: #3a660e !important;
}

.bd-darkGreen-hover:hover {
  border-color: #3a660e !important;
}

.bd-darkGreen-focus:focus {
  border-color: #3a660e !important;
}

.ol-darkGreen-active:active {
  outline-color: #3a660e !important;
}

.ol-darkGreen-hover:hover {
  outline-color: #3a660e !important;
}

.ol-darkGreen-focus:focus {
  outline-color: #3a660e !important;
}

.ribbed-darkGreen {
  background: #3a660e
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkGreen::before {
  background: #3a660e !important;
}

.before-fg-darkGreen::before {
  color: #3a660e !important;
}

.after-bg-darkGreen::after {
  background: #3a660e !important;
}

.after-fg-darkGreen::after {
  color: #3a660e !important;
}

.op-darkGreen {
  background-color: rgba(58, 102, 14, 0.7) !important;
}

.tg-darkGreen::before {
  border-color: #3a660e;
}

.fg-darkEmerald {
  color: #003d00 !important;
}

.bg-darkEmerald {
  background-color: #003d00 !important;
}

.bd-darkEmerald {
  border-color: #003d00 !important;
}

.ol-darkEmerald {
  outline-color: #003d00 !important;
}

.fg-darkEmerald-active:active {
  color: #003d00 !important;
}

.fg-darkEmerald-hover:hover {
  color: #003d00 !important;
}

.fg-darkEmerald-focus:focus {
  color: #003d00 !important;
}

.bg-darkEmerald-active:active {
  background-color: #003d00 !important;
}

.bg-darkEmerald-hover:hover {
  background-color: #003d00 !important;
}

.bg-darkEmerald-focus:focus {
  background-color: #003d00 !important;
}

.bd-darkEmerald-active:active {
  border-color: #003d00 !important;
}

.bd-darkEmerald-hover:hover {
  border-color: #003d00 !important;
}

.bd-darkEmerald-focus:focus {
  border-color: #003d00 !important;
}

.ol-darkEmerald-active:active {
  outline-color: #003d00 !important;
}

.ol-darkEmerald-hover:hover {
  outline-color: #003d00 !important;
}

.ol-darkEmerald-focus:focus {
  outline-color: #003d00 !important;
}

.ribbed-darkEmerald {
  background: #003d00
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkEmerald::before {
  background: #003d00 !important;
}

.before-fg-darkEmerald::before {
  color: #003d00 !important;
}

.after-bg-darkEmerald::after {
  background: #003d00 !important;
}

.after-fg-darkEmerald::after {
  color: #003d00 !important;
}

.op-darkEmerald {
  background-color: rgba(0, 61, 0, 0.7) !important;
}

.tg-darkEmerald::before {
  border-color: #003d00;
}

.fg-darkBlue {
  color: #0077a3 !important;
}

.bg-darkBlue {
  background-color: #0077a3 !important;
}

.bd-darkBlue {
  border-color: #0077a3 !important;
}

.ol-darkBlue {
  outline-color: #0077a3 !important;
}

.fg-darkBlue-active:active {
  color: #0077a3 !important;
}

.fg-darkBlue-hover:hover {
  color: #0077a3 !important;
}

.fg-darkBlue-focus:focus {
  color: #0077a3 !important;
}

.bg-darkBlue-active:active {
  background-color: #0077a3 !important;
}

.bg-darkBlue-hover:hover {
  background-color: #0077a3 !important;
}

.bg-darkBlue-focus:focus {
  background-color: #0077a3 !important;
}

.bd-darkBlue-active:active {
  border-color: #0077a3 !important;
}

.bd-darkBlue-hover:hover {
  border-color: #0077a3 !important;
}

.bd-darkBlue-focus:focus {
  border-color: #0077a3 !important;
}

.ol-darkBlue-active:active {
  outline-color: #0077a3 !important;
}

.ol-darkBlue-hover:hover {
  outline-color: #0077a3 !important;
}

.ol-darkBlue-focus:focus {
  outline-color: #0077a3 !important;
}

.ribbed-darkBlue {
  background: #0077a3
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkBlue::before {
  background: #0077a3 !important;
}

.before-fg-darkBlue::before {
  color: #0077a3 !important;
}

.after-bg-darkBlue::after {
  background: #0077a3 !important;
}

.after-fg-darkBlue::after {
  color: #0077a3 !important;
}

.op-darkBlue {
  background-color: rgba(0, 119, 163, 0.7) !important;
}

.tg-darkBlue::before {
  border-color: #0077a3;
}

.fg-darkTeal {
  color: #005e5d !important;
}

.bg-darkTeal {
  background-color: #005e5d !important;
}

.bd-darkTeal {
  border-color: #005e5d !important;
}

.ol-darkTeal {
  outline-color: #005e5d !important;
}

.fg-darkTeal-active:active {
  color: #005e5d !important;
}

.fg-darkTeal-hover:hover {
  color: #005e5d !important;
}

.fg-darkTeal-focus:focus {
  color: #005e5d !important;
}

.bg-darkTeal-active:active {
  background-color: #005e5d !important;
}

.bg-darkTeal-hover:hover {
  background-color: #005e5d !important;
}

.bg-darkTeal-focus:focus {
  background-color: #005e5d !important;
}

.bd-darkTeal-active:active {
  border-color: #005e5d !important;
}

.bd-darkTeal-hover:hover {
  border-color: #005e5d !important;
}

.bd-darkTeal-focus:focus {
  border-color: #005e5d !important;
}

.ol-darkTeal-active:active {
  outline-color: #005e5d !important;
}

.ol-darkTeal-hover:hover {
  outline-color: #005e5d !important;
}

.ol-darkTeal-focus:focus {
  outline-color: #005e5d !important;
}

.ribbed-darkTeal {
  background: #005e5d
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkTeal::before {
  background: #005e5d !important;
}

.before-fg-darkTeal::before {
  color: #005e5d !important;
}

.after-bg-darkTeal::after {
  background: #005e5d !important;
}

.after-fg-darkTeal::after {
  color: #005e5d !important;
}

.op-darkTeal {
  background-color: rgba(0, 94, 93, 0.7) !important;
}

.tg-darkTeal::before {
  border-color: #005e5d;
}

.fg-darkCyan {
  color: #13709e !important;
}

.bg-darkCyan {
  background-color: #13709e !important;
}

.bd-darkCyan {
  border-color: #13709e !important;
}

.ol-darkCyan {
  outline-color: #13709e !important;
}

.fg-darkCyan-active:active {
  color: #13709e !important;
}

.fg-darkCyan-hover:hover {
  color: #13709e !important;
}

.fg-darkCyan-focus:focus {
  color: #13709e !important;
}

.bg-darkCyan-active:active {
  background-color: #13709e !important;
}

.bg-darkCyan-hover:hover {
  background-color: #13709e !important;
}

.bg-darkCyan-focus:focus {
  background-color: #13709e !important;
}

.bd-darkCyan-active:active {
  border-color: #13709e !important;
}

.bd-darkCyan-hover:hover {
  border-color: #13709e !important;
}

.bd-darkCyan-focus:focus {
  border-color: #13709e !important;
}

.ol-darkCyan-active:active {
  outline-color: #13709e !important;
}

.ol-darkCyan-hover:hover {
  outline-color: #13709e !important;
}

.ol-darkCyan-focus:focus {
  outline-color: #13709e !important;
}

.ribbed-darkCyan {
  background: #13709e
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkCyan::before {
  background: #13709e !important;
}

.before-fg-darkCyan::before {
  color: #13709e !important;
}

.after-bg-darkCyan::after {
  background: #13709e !important;
}

.after-fg-darkCyan::after {
  color: #13709e !important;
}

.op-darkCyan {
  background-color: rgba(19, 112, 158, 0.7) !important;
}

.tg-darkCyan::before {
  border-color: #13709e;
}

.fg-darkCobalt {
  color: #0036a3 !important;
}

.bg-darkCobalt {
  background-color: #0036a3 !important;
}

.bd-darkCobalt {
  border-color: #0036a3 !important;
}

.ol-darkCobalt {
  outline-color: #0036a3 !important;
}

.fg-darkCobalt-active:active {
  color: #0036a3 !important;
}

.fg-darkCobalt-hover:hover {
  color: #0036a3 !important;
}

.fg-darkCobalt-focus:focus {
  color: #0036a3 !important;
}

.bg-darkCobalt-active:active {
  background-color: #0036a3 !important;
}

.bg-darkCobalt-hover:hover {
  background-color: #0036a3 !important;
}

.bg-darkCobalt-focus:focus {
  background-color: #0036a3 !important;
}

.bd-darkCobalt-active:active {
  border-color: #0036a3 !important;
}

.bd-darkCobalt-hover:hover {
  border-color: #0036a3 !important;
}

.bd-darkCobalt-focus:focus {
  border-color: #0036a3 !important;
}

.ol-darkCobalt-active:active {
  outline-color: #0036a3 !important;
}

.ol-darkCobalt-hover:hover {
  outline-color: #0036a3 !important;
}

.ol-darkCobalt-focus:focus {
  outline-color: #0036a3 !important;
}

.ribbed-darkCobalt {
  background: #0036a3
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkCobalt::before {
  background: #0036a3 !important;
}

.before-fg-darkCobalt::before {
  color: #0036a3 !important;
}

.after-bg-darkCobalt::after {
  background: #0036a3 !important;
}

.after-fg-darkCobalt::after {
  color: #0036a3 !important;
}

.op-darkCobalt {
  background-color: rgba(0, 54, 163, 0.7) !important;
}

.tg-darkCobalt::before {
  border-color: #0036a3;
}

.fg-darkIndigo {
  color: #4a00b3 !important;
}

.bg-darkIndigo {
  background-color: #4a00b3 !important;
}

.bd-darkIndigo {
  border-color: #4a00b3 !important;
}

.ol-darkIndigo {
  outline-color: #4a00b3 !important;
}

.fg-darkIndigo-active:active {
  color: #4a00b3 !important;
}

.fg-darkIndigo-hover:hover {
  color: #4a00b3 !important;
}

.fg-darkIndigo-focus:focus {
  color: #4a00b3 !important;
}

.bg-darkIndigo-active:active {
  background-color: #4a00b3 !important;
}

.bg-darkIndigo-hover:hover {
  background-color: #4a00b3 !important;
}

.bg-darkIndigo-focus:focus {
  background-color: #4a00b3 !important;
}

.bd-darkIndigo-active:active {
  border-color: #4a00b3 !important;
}

.bd-darkIndigo-hover:hover {
  border-color: #4a00b3 !important;
}

.bd-darkIndigo-focus:focus {
  border-color: #4a00b3 !important;
}

.ol-darkIndigo-active:active {
  outline-color: #4a00b3 !important;
}

.ol-darkIndigo-hover:hover {
  outline-color: #4a00b3 !important;
}

.ol-darkIndigo-focus:focus {
  outline-color: #4a00b3 !important;
}

.ribbed-darkIndigo {
  background: #4a00b3
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkIndigo::before {
  background: #4a00b3 !important;
}

.before-fg-darkIndigo::before {
  color: #4a00b3 !important;
}

.after-bg-darkIndigo::after {
  background: #4a00b3 !important;
}

.after-fg-darkIndigo::after {
  color: #4a00b3 !important;
}

.op-darkIndigo {
  background-color: rgba(74, 0, 179, 0.7) !important;
}

.tg-darkIndigo::before {
  border-color: #4a00b3;
}

.fg-darkViolet {
  color: #7700b3 !important;
}

.bg-darkViolet {
  background-color: #7700b3 !important;
}

.bd-darkViolet {
  border-color: #7700b3 !important;
}

.ol-darkViolet {
  outline-color: #7700b3 !important;
}

.fg-darkViolet-active:active {
  color: #7700b3 !important;
}

.fg-darkViolet-hover:hover {
  color: #7700b3 !important;
}

.fg-darkViolet-focus:focus {
  color: #7700b3 !important;
}

.bg-darkViolet-active:active {
  background-color: #7700b3 !important;
}

.bg-darkViolet-hover:hover {
  background-color: #7700b3 !important;
}

.bg-darkViolet-focus:focus {
  background-color: #7700b3 !important;
}

.bd-darkViolet-active:active {
  border-color: #7700b3 !important;
}

.bd-darkViolet-hover:hover {
  border-color: #7700b3 !important;
}

.bd-darkViolet-focus:focus {
  border-color: #7700b3 !important;
}

.ol-darkViolet-active:active {
  outline-color: #7700b3 !important;
}

.ol-darkViolet-hover:hover {
  outline-color: #7700b3 !important;
}

.ol-darkViolet-focus:focus {
  outline-color: #7700b3 !important;
}

.ribbed-darkViolet {
  background: #7700b3
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkViolet::before {
  background: #7700b3 !important;
}

.before-fg-darkViolet::before {
  color: #7700b3 !important;
}

.after-bg-darkViolet::after {
  background: #7700b3 !important;
}

.after-fg-darkViolet::after {
  color: #7700b3 !important;
}

.op-darkViolet {
  background-color: rgba(119, 0, 179, 0.7) !important;
}

.tg-darkViolet::before {
  border-color: #7700b3;
}

.fg-darkPink {
  color: #ba2588 !important;
}

.bg-darkPink {
  background-color: #ba2588 !important;
}

.bd-darkPink {
  border-color: #ba2588 !important;
}

.ol-darkPink {
  outline-color: #ba2588 !important;
}

.fg-darkPink-active:active {
  color: #ba2588 !important;
}

.fg-darkPink-hover:hover {
  color: #ba2588 !important;
}

.fg-darkPink-focus:focus {
  color: #ba2588 !important;
}

.bg-darkPink-active:active {
  background-color: #ba2588 !important;
}

.bg-darkPink-hover:hover {
  background-color: #ba2588 !important;
}

.bg-darkPink-focus:focus {
  background-color: #ba2588 !important;
}

.bd-darkPink-active:active {
  border-color: #ba2588 !important;
}

.bd-darkPink-hover:hover {
  border-color: #ba2588 !important;
}

.bd-darkPink-focus:focus {
  border-color: #ba2588 !important;
}

.ol-darkPink-active:active {
  outline-color: #ba2588 !important;
}

.ol-darkPink-hover:hover {
  outline-color: #ba2588 !important;
}

.ol-darkPink-focus:focus {
  outline-color: #ba2588 !important;
}

.ribbed-darkPink {
  background: #ba2588
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkPink::before {
  background: #ba2588 !important;
}

.before-fg-darkPink::before {
  color: #ba2588 !important;
}

.after-bg-darkPink::after {
  background: #ba2588 !important;
}

.after-fg-darkPink::after {
  color: #ba2588 !important;
}

.op-darkPink {
  background-color: rgba(186, 37, 136, 0.7) !important;
}

.tg-darkPink::before {
  border-color: #ba2588;
}

.fg-darkMagenta {
  color: #8c004a !important;
}

.bg-darkMagenta {
  background-color: #8c004a !important;
}

.bd-darkMagenta {
  border-color: #8c004a !important;
}

.ol-darkMagenta {
  outline-color: #8c004a !important;
}

.fg-darkMagenta-active:active {
  color: #8c004a !important;
}

.fg-darkMagenta-hover:hover {
  color: #8c004a !important;
}

.fg-darkMagenta-focus:focus {
  color: #8c004a !important;
}

.bg-darkMagenta-active:active {
  background-color: #8c004a !important;
}

.bg-darkMagenta-hover:hover {
  background-color: #8c004a !important;
}

.bg-darkMagenta-focus:focus {
  background-color: #8c004a !important;
}

.bd-darkMagenta-active:active {
  border-color: #8c004a !important;
}

.bd-darkMagenta-hover:hover {
  border-color: #8c004a !important;
}

.bd-darkMagenta-focus:focus {
  border-color: #8c004a !important;
}

.ol-darkMagenta-active:active {
  outline-color: #8c004a !important;
}

.ol-darkMagenta-hover:hover {
  outline-color: #8c004a !important;
}

.ol-darkMagenta-focus:focus {
  outline-color: #8c004a !important;
}

.ribbed-darkMagenta {
  background: #8c004a
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkMagenta::before {
  background: #8c004a !important;
}

.before-fg-darkMagenta::before {
  color: #8c004a !important;
}

.after-bg-darkMagenta::after {
  background: #8c004a !important;
}

.after-fg-darkMagenta::after {
  color: #8c004a !important;
}

.op-darkMagenta {
  background-color: rgba(140, 0, 74, 0.7) !important;
}

.tg-darkMagenta::before {
  border-color: #8c004a;
}

.fg-darkCrimson {
  color: #560014 !important;
}

.bg-darkCrimson {
  background-color: #560014 !important;
}

.bd-darkCrimson {
  border-color: #560014 !important;
}

.ol-darkCrimson {
  outline-color: #560014 !important;
}

.fg-darkCrimson-active:active {
  color: #560014 !important;
}

.fg-darkCrimson-hover:hover {
  color: #560014 !important;
}

.fg-darkCrimson-focus:focus {
  color: #560014 !important;
}

.bg-darkCrimson-active:active {
  background-color: #560014 !important;
}

.bg-darkCrimson-hover:hover {
  background-color: #560014 !important;
}

.bg-darkCrimson-focus:focus {
  background-color: #560014 !important;
}

.bd-darkCrimson-active:active {
  border-color: #560014 !important;
}

.bd-darkCrimson-hover:hover {
  border-color: #560014 !important;
}

.bd-darkCrimson-focus:focus {
  border-color: #560014 !important;
}

.ol-darkCrimson-active:active {
  outline-color: #560014 !important;
}

.ol-darkCrimson-hover:hover {
  outline-color: #560014 !important;
}

.ol-darkCrimson-focus:focus {
  outline-color: #560014 !important;
}

.ribbed-darkCrimson {
  background: #560014
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkCrimson::before {
  background: #560014 !important;
}

.before-fg-darkCrimson::before {
  color: #560014 !important;
}

.after-bg-darkCrimson::after {
  background: #560014 !important;
}

.after-fg-darkCrimson::after {
  color: #560014 !important;
}

.op-darkCrimson {
  background-color: rgba(86, 0, 20, 0.7) !important;
}

.tg-darkCrimson::before {
  border-color: #560014;
}

.fg-darkRed {
  color: #8f251f !important;
}

.bg-darkRed {
  background-color: #8f251f !important;
}

.bd-darkRed {
  border-color: #8f251f !important;
}

.ol-darkRed {
  outline-color: #8f251f !important;
}

.fg-darkRed-active:active {
  color: #8f251f !important;
}

.fg-darkRed-hover:hover {
  color: #8f251f !important;
}

.fg-darkRed-focus:focus {
  color: #8f251f !important;
}

.bg-darkRed-active:active {
  background-color: #8f251f !important;
}

.bg-darkRed-hover:hover {
  background-color: #8f251f !important;
}

.bg-darkRed-focus:focus {
  background-color: #8f251f !important;
}

.bd-darkRed-active:active {
  border-color: #8f251f !important;
}

.bd-darkRed-hover:hover {
  border-color: #8f251f !important;
}

.bd-darkRed-focus:focus {
  border-color: #8f251f !important;
}

.ol-darkRed-active:active {
  outline-color: #8f251f !important;
}

.ol-darkRed-hover:hover {
  outline-color: #8f251f !important;
}

.ol-darkRed-focus:focus {
  outline-color: #8f251f !important;
}

.ribbed-darkRed {
  background: #8f251f
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkRed::before {
  background: #8f251f !important;
}

.before-fg-darkRed::before {
  color: #8f251f !important;
}

.after-bg-darkRed::after {
  background: #8f251f !important;
}

.after-fg-darkRed::after {
  color: #8f251f !important;
}

.op-darkRed {
  background-color: rgba(143, 37, 31, 0.7) !important;
}

.tg-darkRed::before {
  border-color: #8f251f;
}

.fg-darkOrange {
  color: #ae4800 !important;
}

.bg-darkOrange {
  background-color: #ae4800 !important;
}

.bd-darkOrange {
  border-color: #ae4800 !important;
}

.ol-darkOrange {
  outline-color: #ae4800 !important;
}

.fg-darkOrange-active:active {
  color: #ae4800 !important;
}

.fg-darkOrange-hover:hover {
  color: #ae4800 !important;
}

.fg-darkOrange-focus:focus {
  color: #ae4800 !important;
}

.bg-darkOrange-active:active {
  background-color: #ae4800 !important;
}

.bg-darkOrange-hover:hover {
  background-color: #ae4800 !important;
}

.bg-darkOrange-focus:focus {
  background-color: #ae4800 !important;
}

.bd-darkOrange-active:active {
  border-color: #ae4800 !important;
}

.bd-darkOrange-hover:hover {
  border-color: #ae4800 !important;
}

.bd-darkOrange-focus:focus {
  border-color: #ae4800 !important;
}

.ol-darkOrange-active:active {
  outline-color: #ae4800 !important;
}

.ol-darkOrange-hover:hover {
  outline-color: #ae4800 !important;
}

.ol-darkOrange-focus:focus {
  outline-color: #ae4800 !important;
}

.ribbed-darkOrange {
  background: #ae4800
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkOrange::before {
  background: #ae4800 !important;
}

.before-fg-darkOrange::before {
  color: #ae4800 !important;
}

.after-bg-darkOrange::after {
  background: #ae4800 !important;
}

.after-fg-darkOrange::after {
  color: #ae4800 !important;
}

.op-darkOrange {
  background-color: rgba(174, 72, 0, 0.7) !important;
}

.tg-darkOrange::before {
  border-color: #ae4800;
}

.fg-darkAmber {
  color: #a77107 !important;
}

.bg-darkAmber {
  background-color: #a77107 !important;
}

.bd-darkAmber {
  border-color: #a77107 !important;
}

.ol-darkAmber {
  outline-color: #a77107 !important;
}

.fg-darkAmber-active:active {
  color: #a77107 !important;
}

.fg-darkAmber-hover:hover {
  color: #a77107 !important;
}

.fg-darkAmber-focus:focus {
  color: #a77107 !important;
}

.bg-darkAmber-active:active {
  background-color: #a77107 !important;
}

.bg-darkAmber-hover:hover {
  background-color: #a77107 !important;
}

.bg-darkAmber-focus:focus {
  background-color: #a77107 !important;
}

.bd-darkAmber-active:active {
  border-color: #a77107 !important;
}

.bd-darkAmber-hover:hover {
  border-color: #a77107 !important;
}

.bd-darkAmber-focus:focus {
  border-color: #a77107 !important;
}

.ol-darkAmber-active:active {
  outline-color: #a77107 !important;
}

.ol-darkAmber-hover:hover {
  outline-color: #a77107 !important;
}

.ol-darkAmber-focus:focus {
  outline-color: #a77107 !important;
}

.ribbed-darkAmber {
  background: #a77107
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkAmber::before {
  background: #a77107 !important;
}

.before-fg-darkAmber::before {
  color: #a77107 !important;
}

.after-bg-darkAmber::after {
  background: #a77107 !important;
}

.after-fg-darkAmber::after {
  color: #a77107 !important;
}

.op-darkAmber {
  background-color: rgba(167, 113, 7, 0.7) !important;
}

.tg-darkAmber::before {
  border-color: #a77107;
}

.fg-darkYellow {
  color: #b3a800 !important;
}

.bg-darkYellow {
  background-color: #b3a800 !important;
}

.bd-darkYellow {
  border-color: #b3a800 !important;
}

.ol-darkYellow {
  outline-color: #b3a800 !important;
}

.fg-darkYellow-active:active {
  color: #b3a800 !important;
}

.fg-darkYellow-hover:hover {
  color: #b3a800 !important;
}

.fg-darkYellow-focus:focus {
  color: #b3a800 !important;
}

.bg-darkYellow-active:active {
  background-color: #b3a800 !important;
}

.bg-darkYellow-hover:hover {
  background-color: #b3a800 !important;
}

.bg-darkYellow-focus:focus {
  background-color: #b3a800 !important;
}

.bd-darkYellow-active:active {
  border-color: #b3a800 !important;
}

.bd-darkYellow-hover:hover {
  border-color: #b3a800 !important;
}

.bd-darkYellow-focus:focus {
  border-color: #b3a800 !important;
}

.ol-darkYellow-active:active {
  outline-color: #b3a800 !important;
}

.ol-darkYellow-hover:hover {
  outline-color: #b3a800 !important;
}

.ol-darkYellow-focus:focus {
  outline-color: #b3a800 !important;
}

.ribbed-darkYellow {
  background: #b3a800
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkYellow::before {
  background: #b3a800 !important;
}

.before-fg-darkYellow::before {
  color: #b3a800 !important;
}

.after-bg-darkYellow::after {
  background: #b3a800 !important;
}

.after-fg-darkYellow::after {
  color: #b3a800 !important;
}

.op-darkYellow {
  background-color: rgba(179, 168, 0, 0.7) !important;
}

.tg-darkYellow::before {
  border-color: #b3a800;
}

.fg-darkBrown {
  color: #493219 !important;
}

.bg-darkBrown {
  background-color: #493219 !important;
}

.bd-darkBrown {
  border-color: #493219 !important;
}

.ol-darkBrown {
  outline-color: #493219 !important;
}

.fg-darkBrown-active:active {
  color: #493219 !important;
}

.fg-darkBrown-hover:hover {
  color: #493219 !important;
}

.fg-darkBrown-focus:focus {
  color: #493219 !important;
}

.bg-darkBrown-active:active {
  background-color: #493219 !important;
}

.bg-darkBrown-hover:hover {
  background-color: #493219 !important;
}

.bg-darkBrown-focus:focus {
  background-color: #493219 !important;
}

.bd-darkBrown-active:active {
  border-color: #493219 !important;
}

.bd-darkBrown-hover:hover {
  border-color: #493219 !important;
}

.bd-darkBrown-focus:focus {
  border-color: #493219 !important;
}

.ol-darkBrown-active:active {
  outline-color: #493219 !important;
}

.ol-darkBrown-hover:hover {
  outline-color: #493219 !important;
}

.ol-darkBrown-focus:focus {
  outline-color: #493219 !important;
}

.ribbed-darkBrown {
  background: #493219
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkBrown::before {
  background: #493219 !important;
}

.before-fg-darkBrown::before {
  color: #493219 !important;
}

.after-bg-darkBrown::after {
  background: #493219 !important;
}

.after-fg-darkBrown::after {
  color: #493219 !important;
}

.op-darkBrown {
  background-color: rgba(73, 50, 25, 0.7) !important;
}

.tg-darkBrown::before {
  border-color: #493219;
}

.fg-darkOlive {
  color: #4a5b43 !important;
}

.bg-darkOlive {
  background-color: #4a5b43 !important;
}

.bd-darkOlive {
  border-color: #4a5b43 !important;
}

.ol-darkOlive {
  outline-color: #4a5b43 !important;
}

.fg-darkOlive-active:active {
  color: #4a5b43 !important;
}

.fg-darkOlive-hover:hover {
  color: #4a5b43 !important;
}

.fg-darkOlive-focus:focus {
  color: #4a5b43 !important;
}

.bg-darkOlive-active:active {
  background-color: #4a5b43 !important;
}

.bg-darkOlive-hover:hover {
  background-color: #4a5b43 !important;
}

.bg-darkOlive-focus:focus {
  background-color: #4a5b43 !important;
}

.bd-darkOlive-active:active {
  border-color: #4a5b43 !important;
}

.bd-darkOlive-hover:hover {
  border-color: #4a5b43 !important;
}

.bd-darkOlive-focus:focus {
  border-color: #4a5b43 !important;
}

.ol-darkOlive-active:active {
  outline-color: #4a5b43 !important;
}

.ol-darkOlive-hover:hover {
  outline-color: #4a5b43 !important;
}

.ol-darkOlive-focus:focus {
  outline-color: #4a5b43 !important;
}

.ribbed-darkOlive {
  background: #4a5b43
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkOlive::before {
  background: #4a5b43 !important;
}

.before-fg-darkOlive::before {
  color: #4a5b43 !important;
}

.after-bg-darkOlive::after {
  background: #4a5b43 !important;
}

.after-fg-darkOlive::after {
  color: #4a5b43 !important;
}

.op-darkOlive {
  background-color: rgba(74, 91, 67, 0.7) !important;
}

.tg-darkOlive::before {
  border-color: #4a5b43;
}

.fg-darkSteel {
  color: #43505b !important;
}

.bg-darkSteel {
  background-color: #43505b !important;
}

.bd-darkSteel {
  border-color: #43505b !important;
}

.ol-darkSteel {
  outline-color: #43505b !important;
}

.fg-darkSteel-active:active {
  color: #43505b !important;
}

.fg-darkSteel-hover:hover {
  color: #43505b !important;
}

.fg-darkSteel-focus:focus {
  color: #43505b !important;
}

.bg-darkSteel-active:active {
  background-color: #43505b !important;
}

.bg-darkSteel-hover:hover {
  background-color: #43505b !important;
}

.bg-darkSteel-focus:focus {
  background-color: #43505b !important;
}

.bd-darkSteel-active:active {
  border-color: #43505b !important;
}

.bd-darkSteel-hover:hover {
  border-color: #43505b !important;
}

.bd-darkSteel-focus:focus {
  border-color: #43505b !important;
}

.ol-darkSteel-active:active {
  outline-color: #43505b !important;
}

.ol-darkSteel-hover:hover {
  outline-color: #43505b !important;
}

.ol-darkSteel-focus:focus {
  outline-color: #43505b !important;
}

.ribbed-darkSteel {
  background: #43505b
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkSteel::before {
  background: #43505b !important;
}

.before-fg-darkSteel::before {
  color: #43505b !important;
}

.after-bg-darkSteel::after {
  background: #43505b !important;
}

.after-fg-darkSteel::after {
  color: #43505b !important;
}

.op-darkSteel {
  background-color: rgba(67, 80, 91, 0.7) !important;
}

.tg-darkSteel::before {
  border-color: #43505b;
}

.fg-darkMauve {
  color: #4f415d !important;
}

.bg-darkMauve {
  background-color: #4f415d !important;
}

.bd-darkMauve {
  border-color: #4f415d !important;
}

.ol-darkMauve {
  outline-color: #4f415d !important;
}

.fg-darkMauve-active:active {
  color: #4f415d !important;
}

.fg-darkMauve-hover:hover {
  color: #4f415d !important;
}

.fg-darkMauve-focus:focus {
  color: #4f415d !important;
}

.bg-darkMauve-active:active {
  background-color: #4f415d !important;
}

.bg-darkMauve-hover:hover {
  background-color: #4f415d !important;
}

.bg-darkMauve-focus:focus {
  background-color: #4f415d !important;
}

.bd-darkMauve-active:active {
  border-color: #4f415d !important;
}

.bd-darkMauve-hover:hover {
  border-color: #4f415d !important;
}

.bd-darkMauve-focus:focus {
  border-color: #4f415d !important;
}

.ol-darkMauve-active:active {
  outline-color: #4f415d !important;
}

.ol-darkMauve-hover:hover {
  outline-color: #4f415d !important;
}

.ol-darkMauve-focus:focus {
  outline-color: #4f415d !important;
}

.ribbed-darkMauve {
  background: #4f415d
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkMauve::before {
  background: #4f415d !important;
}

.before-fg-darkMauve::before {
  color: #4f415d !important;
}

.after-bg-darkMauve::after {
  background: #4f415d !important;
}

.after-fg-darkMauve::after {
  color: #4f415d !important;
}

.op-darkMauve {
  background-color: rgba(79, 65, 93, 0.7) !important;
}

.tg-darkMauve::before {
  border-color: #4f415d;
}

.fg-darkTaupe {
  color: #574e32 !important;
}

.bg-darkTaupe {
  background-color: #574e32 !important;
}

.bd-darkTaupe {
  border-color: #574e32 !important;
}

.ol-darkTaupe {
  outline-color: #574e32 !important;
}

.fg-darkTaupe-active:active {
  color: #574e32 !important;
}

.fg-darkTaupe-hover:hover {
  color: #574e32 !important;
}

.fg-darkTaupe-focus:focus {
  color: #574e32 !important;
}

.bg-darkTaupe-active:active {
  background-color: #574e32 !important;
}

.bg-darkTaupe-hover:hover {
  background-color: #574e32 !important;
}

.bg-darkTaupe-focus:focus {
  background-color: #574e32 !important;
}

.bd-darkTaupe-active:active {
  border-color: #574e32 !important;
}

.bd-darkTaupe-hover:hover {
  border-color: #574e32 !important;
}

.bd-darkTaupe-focus:focus {
  border-color: #574e32 !important;
}

.ol-darkTaupe-active:active {
  outline-color: #574e32 !important;
}

.ol-darkTaupe-hover:hover {
  outline-color: #574e32 !important;
}

.ol-darkTaupe-focus:focus {
  outline-color: #574e32 !important;
}

.ribbed-darkTaupe {
  background: #574e32
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkTaupe::before {
  background: #574e32 !important;
}

.before-fg-darkTaupe::before {
  color: #574e32 !important;
}

.after-bg-darkTaupe::after {
  background: #574e32 !important;
}

.after-fg-darkTaupe::after {
  color: #574e32 !important;
}

.op-darkTaupe {
  background-color: rgba(87, 78, 50, 0.7) !important;
}

.tg-darkTaupe::before {
  border-color: #574e32;
}

.fg-darkGray {
  color: #989898 !important;
}

.bg-darkGray {
  background-color: #989898 !important;
}

.bd-darkGray {
  border-color: #989898 !important;
}

.ol-darkGray {
  outline-color: #989898 !important;
}

.fg-darkGray-active:active {
  color: #989898 !important;
}

.fg-darkGray-hover:hover {
  color: #989898 !important;
}

.fg-darkGray-focus:focus {
  color: #989898 !important;
}

.bg-darkGray-active:active {
  background-color: #989898 !important;
}

.bg-darkGray-hover:hover {
  background-color: #989898 !important;
}

.bg-darkGray-focus:focus {
  background-color: #989898 !important;
}

.bd-darkGray-active:active {
  border-color: #989898 !important;
}

.bd-darkGray-hover:hover {
  border-color: #989898 !important;
}

.bd-darkGray-focus:focus {
  border-color: #989898 !important;
}

.ol-darkGray-active:active {
  outline-color: #989898 !important;
}

.ol-darkGray-hover:hover {
  outline-color: #989898 !important;
}

.ol-darkGray-focus:focus {
  outline-color: #989898 !important;
}

.ribbed-darkGray {
  background: #989898
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkGray::before {
  background: #989898 !important;
}

.before-fg-darkGray::before {
  color: #989898 !important;
}

.after-bg-darkGray::after {
  background: #989898 !important;
}

.after-fg-darkGray::after {
  color: #989898 !important;
}

.op-darkGray {
  background-color: rgba(152, 152, 152, 0.7) !important;
}

.tg-darkGray::before {
  border-color: #989898;
}

.fg-darkGrayBlue {
  color: #41545e !important;
}

.bg-darkGrayBlue {
  background-color: #41545e !important;
}

.bd-darkGrayBlue {
  border-color: #41545e !important;
}

.ol-darkGrayBlue {
  outline-color: #41545e !important;
}

.fg-darkGrayBlue-active:active {
  color: #41545e !important;
}

.fg-darkGrayBlue-hover:hover {
  color: #41545e !important;
}

.fg-darkGrayBlue-focus:focus {
  color: #41545e !important;
}

.bg-darkGrayBlue-active:active {
  background-color: #41545e !important;
}

.bg-darkGrayBlue-hover:hover {
  background-color: #41545e !important;
}

.bg-darkGrayBlue-focus:focus {
  background-color: #41545e !important;
}

.bd-darkGrayBlue-active:active {
  border-color: #41545e !important;
}

.bd-darkGrayBlue-hover:hover {
  border-color: #41545e !important;
}

.bd-darkGrayBlue-focus:focus {
  border-color: #41545e !important;
}

.ol-darkGrayBlue-active:active {
  outline-color: #41545e !important;
}

.ol-darkGrayBlue-hover:hover {
  outline-color: #41545e !important;
}

.ol-darkGrayBlue-focus:focus {
  outline-color: #41545e !important;
}

.ribbed-darkGrayBlue {
  background: #41545e
    linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 20px 20px !important;
}

.before-bg-darkGrayBlue::before {
  background: #41545e !important;
}

.before-fg-darkGrayBlue::before {
  color: #41545e !important;
}

.after-bg-darkGrayBlue::after {
  background: #41545e !important;
}

.after-fg-darkGrayBlue::after {
  color: #41545e !important;
}

.op-darkGrayBlue {
  background-color: rgba(65, 84, 94, 0.7) !important;
}

.tg-darkGrayBlue::before {
  border-color: #41545e;
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .d-none-ie {
    display: none !important;
  }

  .d-block-ie {
    display: block !important;
  }

  .img-fluid {
    width: 100%\9;
  }

  .img-container img {
    width: 100%\9;
  }

  .tiles-grid {
    display: block;
  }

  .tiles-grid::after {
    display: block;
    clear: both;
    content: "";
  }

  .tiles-grid [class*="tile-"] {
    margin: 5px;
    float: left;
  }

  .ribbon-menu .content-holder .section {
    height: 130px;
  }

  .ribbon-toggle-group {
    height: 80px;
  }
}

@supports (-ms-ime-align: auto) {
  .d-none-edge {
    display: none !important;
  }

  .d-block-edge {
    display: block !important;
  }

  .ribbon-menu .content-holder .section {
    height: 130px;
  }
}

.tile-app,
.tile-large,
.tile-medium,
.tile-small,
.tile-wide {
  display: block;
  background-color: #1ba1e2;
  color: #fff;
  width: 150px;
  height: 150px;
  box-shadow: inset 0 0 1px #ffc;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  user-select: none;
}

.tile-small {
  width: 70px;
  height: 70px;
}

.tile-medium {
  width: 150px;
  height: 150px;
}

.tile-wide {
  width: 310px;
  height: 150px;
}

.tile-large {
  width: 310px;
  height: 310px;
}

.tile-app {
  width: 44px;
  height: 44px;
}

.tile-app .icon,
.tile-large .icon,
.tile-medium .icon,
.tile-small .icon,
.tile-wide .icon {
  max-width: 33%;
  height: 33%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size: 50px;
  line-height: 50px;
  z-index: 2;
}

.tile-app .branding-bar,
.tile-large .branding-bar,
.tile-medium .branding-bar,
.tile-small .branding-bar,
.tile-wide .branding-bar {
  height: 42px;
  /*line-height: 32px;*/
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0 10px 5px;
  font-size: 0.875rem;
  font-weight: 500;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  z-index: 2;
  text-align: center;
}

.tile-app .badge-bottom,
.tile-app .badge-top,
.tile-large .badge-bottom,
.tile-large .badge-top,
.tile-medium .badge-bottom,
.tile-medium .badge-top,
.tile-small .badge-bottom,
.tile-small .badge-top,
.tile-wide .badge-bottom,
.tile-wide .badge-top {
  position: absolute;
  display: block;
  padding: 4px 8px;
  font-size: 12px;
  text-align: center;
  background-color: rgba(29, 29, 29, 0.15);
  border-radius: 4px;
  z-index: 2;
}

.tile-app .badge-bottom,
.tile-large .badge-bottom,
.tile-medium .badge-bottom,
.tile-small .badge-bottom,
.tile-wide .badge-bottom {
  right: 10px;
  bottom: 0;
}

.tile-app .badge-top,
.tile-large .badge-top,
.tile-medium .badge-top,
.tile-small .badge-top,
.tile-wide .badge-top {
  top: 10px;
  right: 10px;
}

.tile-app:hover,
.tile-large:hover,
.tile-medium:hover,
.tile-small:hover,
.tile-wide:hover {
  outline: rgba(29, 29, 29, 0.1) solid 4px;
}

.tile-small .icon {
  max-width: 50%;
  height: 50%;
  font-size: 35px;
  line-height: 35px;
}

.tile-large .icon {
  font-size: 102px;
  line-height: 102px;
}

.tile-app .icon {
  max-width: 75%;
  height: 75%;
}

.tile-app.transform-right,
.tile-large.transform-right,
.tile-medium.transform-right,
.tile-small.transform-right,
.tile-wide.transform-right {
  transform-origin: left 50%;
  transform: perspective(500px) rotateY(0.138372rad) !important;
}

.tile-app.transform-right.tile-small,
.tile-large.transform-right.tile-small,
.tile-medium.transform-right.tile-small,
.tile-small.transform-right.tile-small,
.tile-wide.transform-right.tile-small {
  transform: perspective(500px) rotateY(0.276744rad) !important;
}

.tile-app.transform-right.tile-wide,
.tile-large.transform-right.tile-wide,
.tile-medium.transform-right.tile-wide,
.tile-small.transform-right.tile-wide,
.tile-wide.transform-right.tile-wide {
  transform: perspective(500px) rotateY(0.069186rad) !important;
}

.tile-app.transform-right.tile-large,
.tile-large.transform-right.tile-large,
.tile-medium.transform-right.tile-large,
.tile-small.transform-right.tile-large,
.tile-wide.transform-right.tile-large {
  transform: perspective(500px) rotateY(0.046124rad) !important;
}

.tile-app.transform-left,
.tile-large.transform-left,
.tile-medium.transform-left,
.tile-small.transform-left,
.tile-wide.transform-left {
  transform-origin: right 50%;
  transform: perspective(500px) rotateY(-0.138372rad) !important;
}

.tile-app.transform-left.tile-small,
.tile-large.transform-left.tile-small,
.tile-medium.transform-left.tile-small,
.tile-small.transform-left.tile-small,
.tile-wide.transform-left.tile-small {
  transform: perspective(500px) rotateY(-0.276744rad) !important;
}

.tile-app.transform-left.tile-wide,
.tile-large.transform-left.tile-wide,
.tile-medium.transform-left.tile-wide,
.tile-small.transform-left.tile-wide,
.tile-wide.transform-left.tile-wide {
  transform: perspective(500px) rotateY(-0.069186rad) !important;
}

.tile-app.transform-left.tile-large,
.tile-large.transform-left.tile-large,
.tile-medium.transform-left.tile-large,
.tile-small.transform-left.tile-large,
.tile-wide.transform-left.tile-large {
  transform: perspective(500px) rotateY(-0.046124rad) !important;
}

.tile-app.transform-top,
.tile-large.transform-top,
.tile-medium.transform-top,
.tile-small.transform-top,
.tile-wide.transform-top {
  transform-origin: 50% bottom;
  transform: perspective(500px) rotateX(0.138372rad) !important;
}

.tile-app.transform-top.tile-small,
.tile-large.transform-top.tile-small,
.tile-medium.transform-top.tile-small,
.tile-small.transform-top.tile-small,
.tile-wide.transform-top.tile-small {
  transform: perspective(500px) rotateX(0.276744rad) !important;
}

.tile-app.transform-top.tile-wide,
.tile-large.transform-top.tile-wide,
.tile-medium.transform-top.tile-wide,
.tile-small.transform-top.tile-wide,
.tile-wide.transform-top.tile-wide {
  transform: perspective(500px) rotateX(0.069186rad) !important;
}

.tile-app.transform-top.tile-large,
.tile-large.transform-top.tile-large,
.tile-medium.transform-top.tile-large,
.tile-small.transform-top.tile-large,
.tile-wide.transform-top.tile-large {
  transform: perspective(500px) rotateX(0.046124rad) !important;
}

.tile-app.transform-bottom,
.tile-large.transform-bottom,
.tile-medium.transform-bottom,
.tile-small.transform-bottom,
.tile-wide.transform-bottom {
  transform-origin: 50% top;
  transform: perspective(500px) rotateX(-0.138372rad) !important;
}

.tile-app.transform-bottom.tile-small,
.tile-large.transform-bottom.tile-small,
.tile-medium.transform-bottom.tile-small,
.tile-small.transform-bottom.tile-small,
.tile-wide.transform-bottom.tile-small {
  transform: perspective(500px) rotateX(-0.276744rad) !important;
}

.tile-app.transform-bottom.tile-wide,
.tile-large.transform-bottom.tile-wide,
.tile-medium.transform-bottom.tile-wide,
.tile-small.transform-bottom.tile-wide,
.tile-wide.transform-bottom.tile-wide {
  transform: perspective(500px) rotateX(-0.069186rad) !important;
}

.tile-app.transform-bottom.tile-large,
.tile-large.transform-bottom.tile-large,
.tile-medium.transform-bottom.tile-large,
.tile-small.transform-bottom.tile-large,
.tile-wide.transform-bottom.tile-large {
  transform: perspective(500px) rotateX(-0.046124rad) !important;
}

.tiles-grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, 70px);
  grid-template-rows: repeat(auto-fit, 70px);
  grid-gap: 10px;
}

.tiles-grid .tile-small {
  grid-column: span 1;
  grid-row: span 1;
}

.tiles-grid .tile-medium {
  grid-column: span 2;
  grid-row: span 2;
}

.tiles-grid .tile-wide {
  grid-column: span 4;
  grid-row: span 2;
}

.tiles-grid .tile-large {
  grid-column: span 4;
  grid-row: span 4;
}

.tiles-grid .tile-small.col-1 {
  grid-column: 1 / span 1;
}

.tiles-grid .tile-medium.col-1 {
  grid-column: 1 / span 2;
}

.tiles-grid .tile-wide.col-1 {
  grid-column: 1 / span 4;
}

.tiles-grid .tile-large.col-1 {
  grid-column: 1 / span 4;
}

.tiles-grid .tile-small.row-1 {
  grid-row: 1 / span 1;
}

.tiles-grid .tile-medium.row-1 {
  grid-row: 1 / span 2;
}

.tiles-grid .tile-wide.row-1 {
  grid-row: 1 / span 4;
}

.tiles-grid .tile-large.row-1 {
  grid-row: 1 / span 4;
}

.tiles-grid .tile-small.col-2 {
  grid-column: 2 / span 1;
}

.tiles-grid .tile-medium.col-2 {
  grid-column: 2 / span 2;
}

.tiles-grid .tile-wide.col-2 {
  grid-column: 2 / span 4;
}

.tiles-grid .tile-large.col-2 {
  grid-column: 2 / span 4;
}

.tiles-grid .tile-small.row-2 {
  grid-row: 2 / span 1;
}

.tiles-grid .tile-medium.row-2 {
  grid-row: 2 / span 2;
}

.tiles-grid .tile-wide.row-2 {
  grid-row: 2 / span 4;
}

.tiles-grid .tile-large.row-2 {
  grid-row: 2 / span 4;
}

.tiles-grid .tile-small.col-3 {
  grid-column: 3 / span 1;
}

.tiles-grid .tile-medium.col-3 {
  grid-column: 3 / span 2;
}

.tiles-grid .tile-wide.col-3 {
  grid-column: 3 / span 4;
}

.tiles-grid .tile-large.col-3 {
  grid-column: 3 / span 4;
}

.tiles-grid .tile-small.row-3 {
  grid-row: 3 / span 1;
}

.tiles-grid .tile-medium.row-3 {
  grid-row: 3 / span 2;
}

.tiles-grid .tile-wide.row-3 {
  grid-row: 3 / span 4;
}

.tiles-grid .tile-large.row-3 {
  grid-row: 3 / span 4;
}

.tiles-grid .tile-small.col-4 {
  grid-column: 4 / span 1;
}

.tiles-grid .tile-medium.col-4 {
  grid-column: 4 / span 2;
}

.tiles-grid .tile-wide.col-4 {
  grid-column: 4 / span 4;
}

.tiles-grid .tile-large.col-4 {
  grid-column: 4 / span 4;
}

.tiles-grid .tile-small.row-4 {
  grid-row: 4 / span 1;
}

.tiles-grid .tile-medium.row-4 {
  grid-row: 4 / span 2;
}

.tiles-grid .tile-wide.row-4 {
  grid-row: 4 / span 4;
}

.tiles-grid .tile-large.row-4 {
  grid-row: 4 / span 4;
}

.tiles-grid .tile-small.col-5 {
  grid-column: 5 / span 1;
}

.tiles-grid .tile-medium.col-5 {
  grid-column: 5 / span 2;
}

.tiles-grid .tile-wide.col-5 {
  grid-column: 5 / span 4;
}

.tiles-grid .tile-large.col-5 {
  grid-column: 5 / span 4;
}

.tiles-grid .tile-small.row-5 {
  grid-row: 5 / span 1;
}

.tiles-grid .tile-medium.row-5 {
  grid-row: 5 / span 2;
}

.tiles-grid .tile-wide.row-5 {
  grid-row: 5 / span 4;
}

.tiles-grid .tile-large.row-5 {
  grid-row: 5 / span 4;
}

.tiles-grid .tile-small.col-6 {
  grid-column: 6 / span 1;
}

.tiles-grid .tile-medium.col-6 {
  grid-column: 6 / span 2;
}

.tiles-grid .tile-wide.col-6 {
  grid-column: 6 / span 4;
}

.tiles-grid .tile-large.col-6 {
  grid-column: 6 / span 4;
}

.tiles-grid .tile-small.row-6 {
  grid-row: 6 / span 1;
}

.tiles-grid .tile-medium.row-6 {
  grid-row: 6 / span 2;
}

.tiles-grid .tile-wide.row-6 {
  grid-row: 6 / span 4;
}

.tiles-grid .tile-large.row-6 {
  grid-row: 6 / span 4;
}

.tiles-grid .tile-small.col-7 {
  grid-column: 7 / span 1;
}

.tiles-grid .tile-medium.col-7 {
  grid-column: 7 / span 2;
}

.tiles-grid .tile-wide.col-7 {
  grid-column: 7 / span 4;
}

.tiles-grid .tile-large.col-7 {
  grid-column: 7 / span 4;
}

.tiles-grid .tile-small.row-7 {
  grid-row: 7 / span 1;
}

.tiles-grid .tile-medium.row-7 {
  grid-row: 7 / span 2;
}

.tiles-grid .tile-wide.row-7 {
  grid-row: 7 / span 4;
}

.tiles-grid .tile-large.row-7 {
  grid-row: 7 / span 4;
}

.tiles-grid .tile-small.col-8 {
  grid-column: 8 / span 1;
}

.tiles-grid .tile-medium.col-8 {
  grid-column: 8 / span 2;
}

.tiles-grid .tile-wide.col-8 {
  grid-column: 8 / span 4;
}

.tiles-grid .tile-large.col-8 {
  grid-column: 8 / span 4;
}

.tiles-grid .tile-small.row-8 {
  grid-row: 8 / span 1;
}

.tiles-grid .tile-medium.row-8 {
  grid-row: 8 / span 2;
}

.tiles-grid .tile-wide.row-8 {
  grid-row: 8 / span 4;
}

.tiles-grid .tile-large.row-8 {
  grid-row: 8 / span 4;
}

.tiles-grid .tile-small.col-9 {
  grid-column: 9 / span 1;
}

.tiles-grid .tile-medium.col-9 {
  grid-column: 9 / span 2;
}

.tiles-grid .tile-wide.col-9 {
  grid-column: 9 / span 4;
}

.tiles-grid .tile-large.col-9 {
  grid-column: 9 / span 4;
}

.tiles-grid .tile-small.row-9 {
  grid-row: 9 / span 1;
}

.tiles-grid .tile-medium.row-9 {
  grid-row: 9 / span 2;
}

.tiles-grid .tile-wide.row-9 {
  grid-row: 9 / span 4;
}

.tiles-grid .tile-large.row-9 {
  grid-row: 9 / span 4;
}

.tiles-grid .tile-small.col-10 {
  grid-column: 10 / span 1;
}

.tiles-grid .tile-medium.col-10 {
  grid-column: 10 / span 2;
}

.tiles-grid .tile-wide.col-10 {
  grid-column: 10 / span 4;
}

.tiles-grid .tile-large.col-10 {
  grid-column: 10 / span 4;
}

.tiles-grid .tile-small.row-10 {
  grid-row: 10 / span 1;
}

.tiles-grid .tile-medium.row-10 {
  grid-row: 10 / span 2;
}

.tiles-grid .tile-wide.row-10 {
  grid-row: 10 / span 4;
}

.tiles-grid .tile-large.row-10 {
  grid-row: 10 / span 4;
}

.tiles-grid .tile-small.col-11 {
  grid-column: 11 / span 1;
}

.tiles-grid .tile-medium.col-11 {
  grid-column: 11 / span 2;
}

.tiles-grid .tile-wide.col-11 {
  grid-column: 11 / span 4;
}

.tiles-grid .tile-large.col-11 {
  grid-column: 11 / span 4;
}

.tiles-grid .tile-small.row-11 {
  grid-row: 11 / span 1;
}

.tiles-grid .tile-medium.row-11 {
  grid-row: 11 / span 2;
}

.tiles-grid .tile-wide.row-11 {
  grid-row: 11 / span 4;
}

.tiles-grid .tile-large.row-11 {
  grid-row: 11 / span 4;
}

.tiles-grid .tile-small.col-12 {
  grid-column: 12 / span 1;
}

.tiles-grid .tile-medium.col-12 {
  grid-column: 12 / span 2;
}

.tiles-grid .tile-wide.col-12 {
  grid-column: 12 / span 4;
}

.tiles-grid .tile-large.col-12 {
  grid-column: 12 / span 4;
}

.tiles-grid .tile-small.row-12 {
  grid-row: 12 / span 1;
}

.tiles-grid .tile-medium.row-12 {
  grid-row: 12 / span 2;
}

.tiles-grid .tile-wide.row-12 {
  grid-row: 12 / span 4;
}

.tiles-grid .tile-large.row-12 {
  grid-row: 12 / span 4;
}

.tiles-grid.size-half {
  width: 80px;
}

.tiles-grid.size-1 {
  width: 160px;
}

.tiles-grid.size-2 {
  width: 320px;
}

.tiles-grid.size-3 {
  width: 480px;
}

.tiles-grid.size-4 {
  width: 640px;
}

.tiles-grid.size-5 {
  width: 800px;
}

.tiles-grid.size-6 {
  width: 960px;
}

.tiles-grid.size-7 {
  width: 1120px;
}

.tiles-grid.size-8 {
  width: 1280px;
}

.tiles-grid.size-9 {
  width: 1440px;
}

.tiles-grid.size-10 {
  width: 1600px;
}

@media screen and (min-width: 0) {
  .tiles-grid .col-fs-1 {
    grid-column: 1;
  }

  .tiles-grid .row-fs-1 {
    grid-row: 1;
  }

  .tiles-grid .col-fs-2 {
    grid-column: 2;
  }

  .tiles-grid .row-fs-2 {
    grid-row: 2;
  }

  .tiles-grid .col-fs-3 {
    grid-column: 3;
  }

  .tiles-grid .row-fs-3 {
    grid-row: 3;
  }

  .tiles-grid .col-fs-4 {
    grid-column: 4;
  }

  .tiles-grid .row-fs-4 {
    grid-row: 4;
  }

  .tiles-grid .col-fs-5 {
    grid-column: 5;
  }

  .tiles-grid .row-fs-5 {
    grid-row: 5;
  }

  .tiles-grid .col-fs-6 {
    grid-column: 6;
  }

  .tiles-grid .row-fs-6 {
    grid-row: 6;
  }

  .tiles-grid .col-fs-7 {
    grid-column: 7;
  }

  .tiles-grid .row-fs-7 {
    grid-row: 7;
  }

  .tiles-grid .col-fs-8 {
    grid-column: 8;
  }

  .tiles-grid .row-fs-8 {
    grid-row: 8;
  }

  .tiles-grid .col-fs-9 {
    grid-column: 9;
  }

  .tiles-grid .row-fs-9 {
    grid-row: 9;
  }

  .tiles-grid .col-fs-10 {
    grid-column: 10;
  }

  .tiles-grid .row-fs-10 {
    grid-row: 10;
  }

  .tiles-grid .col-fs-11 {
    grid-column: 11;
  }

  .tiles-grid .row-fs-11 {
    grid-row: 11;
  }

  .tiles-grid .col-fs-12 {
    grid-column: 12;
  }

  .tiles-grid .row-fs-12 {
    grid-row: 12;
  }

  .tiles-grid.size-fs-half {
    width: 80px;
  }

  .tiles-grid.size-fs-1 {
    width: 160px;
  }

  .tiles-grid.size-fs-half {
    width: 80px;
  }

  .tiles-grid.size-fs-2 {
    width: 320px;
  }

  .tiles-grid.size-fs-half {
    width: 80px;
  }

  .tiles-grid.size-fs-3 {
    width: 480px;
  }

  .tiles-grid.size-fs-half {
    width: 80px;
  }

  .tiles-grid.size-fs-4 {
    width: 640px;
  }

  .tiles-grid.size-fs-half {
    width: 80px;
  }

  .tiles-grid.size-fs-5 {
    width: 800px;
  }

  .tiles-grid.size-fs-half {
    width: 80px;
  }

  .tiles-grid.size-fs-6 {
    width: 960px;
  }

  .tiles-grid.size-fs-half {
    width: 80px;
  }

  .tiles-grid.size-fs-7 {
    width: 1120px;
  }

  .tiles-grid.size-fs-half {
    width: 80px;
  }

  .tiles-grid.size-fs-8 {
    width: 1280px;
  }

  .tiles-grid.size-fs-half {
    width: 80px;
  }

  .tiles-grid.size-fs-9 {
    width: 1440px;
  }

  .tiles-grid.size-fs-half {
    width: 80px;
  }

  .tiles-grid.size-fs-10 {
    width: 1600px;
  }
}

@media screen and (min-width: 576px) {
  .tiles-grid .col-sm-1 {
    grid-column: 1;
  }

  .tiles-grid .row-sm-1 {
    grid-row: 1;
  }

  .tiles-grid .col-sm-2 {
    grid-column: 2;
  }

  .tiles-grid .row-sm-2 {
    grid-row: 2;
  }

  .tiles-grid .col-sm-3 {
    grid-column: 3;
  }

  .tiles-grid .row-sm-3 {
    grid-row: 3;
  }

  .tiles-grid .col-sm-4 {
    grid-column: 4;
  }

  .tiles-grid .row-sm-4 {
    grid-row: 4;
  }

  .tiles-grid .col-sm-5 {
    grid-column: 5;
  }

  .tiles-grid .row-sm-5 {
    grid-row: 5;
  }

  .tiles-grid .col-sm-6 {
    grid-column: 6;
  }

  .tiles-grid .row-sm-6 {
    grid-row: 6;
  }

  .tiles-grid .col-sm-7 {
    grid-column: 7;
  }

  .tiles-grid .row-sm-7 {
    grid-row: 7;
  }

  .tiles-grid .col-sm-8 {
    grid-column: 8;
  }

  .tiles-grid .row-sm-8 {
    grid-row: 8;
  }

  .tiles-grid .col-sm-9 {
    grid-column: 9;
  }

  .tiles-grid .row-sm-9 {
    grid-row: 9;
  }

  .tiles-grid .col-sm-10 {
    grid-column: 10;
  }

  .tiles-grid .row-sm-10 {
    grid-row: 10;
  }

  .tiles-grid .col-sm-11 {
    grid-column: 11;
  }

  .tiles-grid .row-sm-11 {
    grid-row: 11;
  }

  .tiles-grid .col-sm-12 {
    grid-column: 12;
  }

  .tiles-grid .row-sm-12 {
    grid-row: 12;
  }

  .tiles-grid.size-sm-half {
    width: 80px;
  }

  .tiles-grid.size-sm-1 {
    width: 160px;
  }

  .tiles-grid.size-sm-half {
    width: 80px;
  }

  .tiles-grid.size-sm-2 {
    width: 320px;
  }

  .tiles-grid.size-sm-half {
    width: 80px;
  }

  .tiles-grid.size-sm-3 {
    width: 480px;
  }

  .tiles-grid.size-sm-half {
    width: 80px;
  }

  .tiles-grid.size-sm-4 {
    width: 640px;
  }

  .tiles-grid.size-sm-half {
    width: 80px;
  }

  .tiles-grid.size-sm-5 {
    width: 800px;
  }

  .tiles-grid.size-sm-half {
    width: 80px;
  }

  .tiles-grid.size-sm-6 {
    width: 960px;
  }

  .tiles-grid.size-sm-half {
    width: 80px;
  }

  .tiles-grid.size-sm-7 {
    width: 1120px;
  }

  .tiles-grid.size-sm-half {
    width: 80px;
  }

  .tiles-grid.size-sm-8 {
    width: 1280px;
  }

  .tiles-grid.size-sm-half {
    width: 80px;
  }

  .tiles-grid.size-sm-9 {
    width: 1440px;
  }

  .tiles-grid.size-sm-half {
    width: 80px;
  }

  .tiles-grid.size-sm-10 {
    width: 1600px;
  }
}

@media screen and (min-width: 768px) {
  .tiles-grid .col-md-1 {
    grid-column: 1;
  }

  .tiles-grid .row-md-1 {
    grid-row: 1;
  }

  .tiles-grid .col-md-2 {
    grid-column: 2;
  }

  .tiles-grid .row-md-2 {
    grid-row: 2;
  }

  .tiles-grid .col-md-3 {
    grid-column: 3;
  }

  .tiles-grid .row-md-3 {
    grid-row: 3;
  }

  .tiles-grid .col-md-4 {
    grid-column: 4;
  }

  .tiles-grid .row-md-4 {
    grid-row: 4;
  }

  .tiles-grid .col-md-5 {
    grid-column: 5;
  }

  .tiles-grid .row-md-5 {
    grid-row: 5;
  }

  .tiles-grid .col-md-6 {
    grid-column: 6;
  }

  .tiles-grid .row-md-6 {
    grid-row: 6;
  }

  .tiles-grid .col-md-7 {
    grid-column: 7;
  }

  .tiles-grid .row-md-7 {
    grid-row: 7;
  }

  .tiles-grid .col-md-8 {
    grid-column: 8;
  }

  .tiles-grid .row-md-8 {
    grid-row: 8;
  }

  .tiles-grid .col-md-9 {
    grid-column: 9;
  }

  .tiles-grid .row-md-9 {
    grid-row: 9;
  }

  .tiles-grid .col-md-10 {
    grid-column: 10;
  }

  .tiles-grid .row-md-10 {
    grid-row: 10;
  }

  .tiles-grid .col-md-11 {
    grid-column: 11;
  }

  .tiles-grid .row-md-11 {
    grid-row: 11;
  }

  .tiles-grid .col-md-12 {
    grid-column: 12;
  }

  .tiles-grid .row-md-12 {
    grid-row: 12;
  }

  .tiles-grid.size-md-half {
    width: 80px;
  }

  .tiles-grid.size-md-1 {
    width: 160px;
  }

  .tiles-grid.size-md-half {
    width: 80px;
  }

  .tiles-grid.size-md-2 {
    width: 320px;
  }

  .tiles-grid.size-md-half {
    width: 80px;
  }

  .tiles-grid.size-md-3 {
    width: 480px;
  }

  .tiles-grid.size-md-half {
    width: 80px;
  }

  .tiles-grid.size-md-4 {
    width: 640px;
  }

  .tiles-grid.size-md-half {
    width: 80px;
  }

  .tiles-grid.size-md-5 {
    width: 800px;
  }

  .tiles-grid.size-md-half {
    width: 80px;
  }

  .tiles-grid.size-md-6 {
    width: 960px;
  }

  .tiles-grid.size-md-half {
    width: 80px;
  }

  .tiles-grid.size-md-7 {
    width: 1120px;
  }

  .tiles-grid.size-md-half {
    width: 80px;
  }

  .tiles-grid.size-md-8 {
    width: 1280px;
  }

  .tiles-grid.size-md-half {
    width: 80px;
  }

  .tiles-grid.size-md-9 {
    width: 1440px;
  }

  .tiles-grid.size-md-half {
    width: 80px;
  }

  .tiles-grid.size-md-10 {
    width: 1600px;
  }
}

@media screen and (min-width: 992px) {
  .tiles-grid .col-lg-1 {
    grid-column: 1;
  }

  .tiles-grid .row-lg-1 {
    grid-row: 1;
  }

  .tiles-grid .col-lg-2 {
    grid-column: 2;
  }

  .tiles-grid .row-lg-2 {
    grid-row: 2;
  }

  .tiles-grid .col-lg-3 {
    grid-column: 3;
  }

  .tiles-grid .row-lg-3 {
    grid-row: 3;
  }

  .tiles-grid .col-lg-4 {
    grid-column: 4;
  }

  .tiles-grid .row-lg-4 {
    grid-row: 4;
  }

  .tiles-grid .col-lg-5 {
    grid-column: 5;
  }

  .tiles-grid .row-lg-5 {
    grid-row: 5;
  }

  .tiles-grid .col-lg-6 {
    grid-column: 6;
  }

  .tiles-grid .row-lg-6 {
    grid-row: 6;
  }

  .tiles-grid .col-lg-7 {
    grid-column: 7;
  }

  .tiles-grid .row-lg-7 {
    grid-row: 7;
  }

  .tiles-grid .col-lg-8 {
    grid-column: 8;
  }

  .tiles-grid .row-lg-8 {
    grid-row: 8;
  }

  .tiles-grid .col-lg-9 {
    grid-column: 9;
  }

  .tiles-grid .row-lg-9 {
    grid-row: 9;
  }

  .tiles-grid .col-lg-10 {
    grid-column: 10;
  }

  .tiles-grid .row-lg-10 {
    grid-row: 10;
  }

  .tiles-grid .col-lg-11 {
    grid-column: 11;
  }

  .tiles-grid .row-lg-11 {
    grid-row: 11;
  }

  .tiles-grid .col-lg-12 {
    grid-column: 12;
  }

  .tiles-grid .row-lg-12 {
    grid-row: 12;
  }

  .tiles-grid.size-lg-half {
    width: 80px;
  }

  .tiles-grid.size-lg-1 {
    width: 160px;
  }

  .tiles-grid.size-lg-half {
    width: 80px;
  }

  .tiles-grid.size-lg-2 {
    width: 320px;
  }

  .tiles-grid.size-lg-half {
    width: 80px;
  }

  .tiles-grid.size-lg-3 {
    width: 480px;
  }

  .tiles-grid.size-lg-half {
    width: 80px;
  }

  .tiles-grid.size-lg-4 {
    width: 640px;
  }

  .tiles-grid.size-lg-half {
    width: 80px;
  }

  .tiles-grid.size-lg-5 {
    width: 800px;
  }

  .tiles-grid.size-lg-half {
    width: 80px;
  }

  .tiles-grid.size-lg-6 {
    width: 960px;
  }

  .tiles-grid.size-lg-half {
    width: 80px;
  }

  .tiles-grid.size-lg-7 {
    width: 1120px;
  }

  .tiles-grid.size-lg-half {
    width: 80px;
  }

  .tiles-grid.size-lg-8 {
    width: 1280px;
  }

  .tiles-grid.size-lg-half {
    width: 80px;
  }

  .tiles-grid.size-lg-9 {
    width: 1440px;
  }

  .tiles-grid.size-lg-half {
    width: 80px;
  }

  .tiles-grid.size-lg-10 {
    width: 1600px;
  }
}

@media screen and (min-width: 1200px) {
  .tiles-grid .col-xl-1 {
    grid-column: 1;
  }

  .tiles-grid .row-xl-1 {
    grid-row: 1;
  }

  .tiles-grid .col-xl-2 {
    grid-column: 2;
  }

  .tiles-grid .row-xl-2 {
    grid-row: 2;
  }

  .tiles-grid .col-xl-3 {
    grid-column: 3;
  }

  .tiles-grid .row-xl-3 {
    grid-row: 3;
  }

  .tiles-grid .col-xl-4 {
    grid-column: 4;
  }

  .tiles-grid .row-xl-4 {
    grid-row: 4;
  }

  .tiles-grid .col-xl-5 {
    grid-column: 5;
  }

  .tiles-grid .row-xl-5 {
    grid-row: 5;
  }

  .tiles-grid .col-xl-6 {
    grid-column: 6;
  }

  .tiles-grid .row-xl-6 {
    grid-row: 6;
  }

  .tiles-grid .col-xl-7 {
    grid-column: 7;
  }

  .tiles-grid .row-xl-7 {
    grid-row: 7;
  }

  .tiles-grid .col-xl-8 {
    grid-column: 8;
  }

  .tiles-grid .row-xl-8 {
    grid-row: 8;
  }

  .tiles-grid .col-xl-9 {
    grid-column: 9;
  }

  .tiles-grid .row-xl-9 {
    grid-row: 9;
  }

  .tiles-grid .col-xl-10 {
    grid-column: 10;
  }

  .tiles-grid .row-xl-10 {
    grid-row: 10;
  }

  .tiles-grid .col-xl-11 {
    grid-column: 11;
  }

  .tiles-grid .row-xl-11 {
    grid-row: 11;
  }

  .tiles-grid .col-xl-12 {
    grid-column: 12;
  }

  .tiles-grid .row-xl-12 {
    grid-row: 12;
  }

  .tiles-grid.size-xl-half {
    width: 80px;
  }

  .tiles-grid.size-xl-1 {
    width: 160px;
  }

  .tiles-grid.size-xl-half {
    width: 80px;
  }

  .tiles-grid.size-xl-2 {
    width: 320px;
  }

  .tiles-grid.size-xl-half {
    width: 80px;
  }

  .tiles-grid.size-xl-3 {
    width: 480px;
  }

  .tiles-grid.size-xl-half {
    width: 80px;
  }

  .tiles-grid.size-xl-4 {
    width: 640px;
  }

  .tiles-grid.size-xl-half {
    width: 80px;
  }

  .tiles-grid.size-xl-5 {
    width: 800px;
  }

  .tiles-grid.size-xl-half {
    width: 80px;
  }

  .tiles-grid.size-xl-6 {
    width: 960px;
  }

  .tiles-grid.size-xl-half {
    width: 80px;
  }

  .tiles-grid.size-xl-7 {
    width: 1120px;
  }

  .tiles-grid.size-xl-half {
    width: 80px;
  }

  .tiles-grid.size-xl-8 {
    width: 1280px;
  }

  .tiles-grid.size-xl-half {
    width: 80px;
  }

  .tiles-grid.size-xl-9 {
    width: 1440px;
  }

  .tiles-grid.size-xl-half {
    width: 80px;
  }

  .tiles-grid.size-xl-10 {
    width: 1600px;
  }
}

@media screen and (min-width: 1452px) {
  .tiles-grid .col-xxl-1 {
    grid-column: 1;
  }

  .tiles-grid .row-xxl-1 {
    grid-row: 1;
  }

  .tiles-grid .col-xxl-2 {
    grid-column: 2;
  }

  .tiles-grid .row-xxl-2 {
    grid-row: 2;
  }

  .tiles-grid .col-xxl-3 {
    grid-column: 3;
  }

  .tiles-grid .row-xxl-3 {
    grid-row: 3;
  }

  .tiles-grid .col-xxl-4 {
    grid-column: 4;
  }

  .tiles-grid .row-xxl-4 {
    grid-row: 4;
  }

  .tiles-grid .col-xxl-5 {
    grid-column: 5;
  }

  .tiles-grid .row-xxl-5 {
    grid-row: 5;
  }

  .tiles-grid .col-xxl-6 {
    grid-column: 6;
  }

  .tiles-grid .row-xxl-6 {
    grid-row: 6;
  }

  .tiles-grid .col-xxl-7 {
    grid-column: 7;
  }

  .tiles-grid .row-xxl-7 {
    grid-row: 7;
  }

  .tiles-grid .col-xxl-8 {
    grid-column: 8;
  }

  .tiles-grid .row-xxl-8 {
    grid-row: 8;
  }

  .tiles-grid .col-xxl-9 {
    grid-column: 9;
  }

  .tiles-grid .row-xxl-9 {
    grid-row: 9;
  }

  .tiles-grid .col-xxl-10 {
    grid-column: 10;
  }

  .tiles-grid .row-xxl-10 {
    grid-row: 10;
  }

  .tiles-grid .col-xxl-11 {
    grid-column: 11;
  }

  .tiles-grid .row-xxl-11 {
    grid-row: 11;
  }

  .tiles-grid .col-xxl-12 {
    grid-column: 12;
  }

  .tiles-grid .row-xxl-12 {
    grid-row: 12;
  }

  .tiles-grid.size-xxl-half {
    width: 80px;
  }

  .tiles-grid.size-xxl-1 {
    width: 160px;
  }

  .tiles-grid.size-xxl-half {
    width: 80px;
  }

  .tiles-grid.size-xxl-2 {
    width: 320px;
  }

  .tiles-grid.size-xxl-half {
    width: 80px;
  }

  .tiles-grid.size-xxl-3 {
    width: 480px;
  }

  .tiles-grid.size-xxl-half {
    width: 80px;
  }

  .tiles-grid.size-xxl-4 {
    width: 640px;
  }

  .tiles-grid.size-xxl-half {
    width: 80px;
  }

  .tiles-grid.size-xxl-5 {
    width: 800px;
  }

  .tiles-grid.size-xxl-half {
    width: 80px;
  }

  .tiles-grid.size-xxl-6 {
    width: 960px;
  }

  .tiles-grid.size-xxl-half {
    width: 80px;
  }

  .tiles-grid.size-xxl-7 {
    width: 1120px;
  }

  .tiles-grid.size-xxl-half {
    width: 80px;
  }

  .tiles-grid.size-xxl-8 {
    width: 1280px;
  }

  .tiles-grid.size-xxl-half {
    width: 80px;
  }

  .tiles-grid.size-xxl-9 {
    width: 1440px;
  }

  .tiles-grid.size-xxl-half {
    width: 80px;
  }

  .tiles-grid.size-xxl-10 {
    width: 1600px;
  }
}

.tiles-group {
  width: auto;
  float: left;
  overflow: visible;
}

.tiles-group::before {
  content: attr(data-group-title);
  display: block;
  position: absolute;
  left: 0;
  top: -36px;
  height: 1.5em;
  line-height: 1.5em;
  z-index: 1;
  color: inherit;
}

[class*="tile-"].image-set .img {
  width: 25%;
  height: 50%;
  display: block;
  float: left;
  border: 1px solid #1d1d1d;
  background-size: cover;
}

[class*="tile-"].image-set .img:nth-child(1) {
  width: 50%;
  height: 100%;
}

[class*="tile-"] .slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}

[class*="tile-"] [class*="slide-"] {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.3s ease-in-out;
}

[class*="tile-"] .slide-front {
  top: 0;
  left: 0;
}

[class*="tile-"].effect-hover-slide-up .slide-back,
[class*="tile-"].effect-hover-zoom-up .slide-back {
  top: 100%;
  left: 0;
}

[class*="tile-"].effect-hover-slide-up:hover .slide-front,
[class*="tile-"].effect-hover-zoom-up:hover .slide-front {
  transform: translateY(-100%);
}

[class*="tile-"].effect-hover-slide-up:hover .slide-back,
[class*="tile-"].effect-hover-zoom-up:hover .slide-back {
  top: 0;
}

[class*="tile-"].effect-hover-slide-down .slide-back,
[class*="tile-"].effect-hover-zoom-down .slide-back {
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

[class*="tile-"].effect-hover-slide-down:hover .slide-front,
[class*="tile-"].effect-hover-zoom-down:hover .slide-front {
  top: 100%;
}

[class*="tile-"].effect-hover-slide-down:hover .slide-back,
[class*="tile-"].effect-hover-zoom-down:hover .slide-back {
  transform: translateY(0);
}

[class*="tile-"].effect-hover-slide-left .slide-back,
[class*="tile-"].effect-hover-zoom-left .slide-back {
  top: 0;
  left: 100%;
}

[class*="tile-"].effect-hover-slide-left:hover .slide-front,
[class*="tile-"].effect-hover-zoom-left:hover .slide-front {
  transform: translateX(-100%);
}

[class*="tile-"].effect-hover-slide-left:hover .slide-back,
[class*="tile-"].effect-hover-zoom-left:hover .slide-back {
  left: 0;
}

[class*="tile-"].effect-hover-slide-right .slide-back,
[class*="tile-"].effect-hover-zoom-right .slide-back {
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

[class*="tile-"].effect-hover-slide-right:hover .slide-front,
[class*="tile-"].effect-hover-zoom-right:hover .slide-front {
  left: 100%;
}

[class*="tile-"].effect-hover-slide-right:hover .slide-back,
[class*="tile-"].effect-hover-zoom-right:hover .slide-back {
  transform: translateX(0);
}

[class*="tile-"].effect-hover-zoom-down:hover .slide-front,
[class*="tile-"].effect-hover-zoom-left:hover .slide-front,
[class*="tile-"].effect-hover-zoom-right:hover .slide-front,
[class*="tile-"].effect-hover-zoom-up:hover .slide-front {
  left: 0;
  top: 0;
  transform: scale(2);
}

@font-face {
  font-family: metro;
  src: url(mif/metro.ttf?niykz5) format("truetype"),
    url(mif/metro.woff?niykz5) format("woff"),
    url(mif/metro.svg?niykz5#metro) format("svg");
  font-weight: 400;
  font-style: normal;
}

[class*="mif-"] {
  font-family: metro !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mif-lg {
  font-size: 1.2em;
  line-height: 1.2em;
}

.mif-2x {
  font-size: 1.5em;
  line-height: 1.5em;
}

.mif-3x {
  font-size: 2em;
  line-height: 2em;
}

.mif-4x {
  font-size: 2.5em;
  line-height: 2.5em;
}

.mif-5x {
  font-size: 3em;
  line-height: 3em;
}

.mif-6x {
  font-size: 3.5em;
  line-height: 3.5em;
}

.mif-7x {
  font-size: 4em;
  line-height: 4em;
}

.mif-8x {
  font-size: 4.5em;
  line-height: 4.5em;
}

.mif-9x {
  font-size: 5em;
  line-height: 5em;
}

.mif-10x {
  font-size: 5.5em;
  line-height: 5.5em;
}

.mif-add-shopping-cart:before {
  content: "\e854";
}

.mif-cast:before {
  content: "\e307";
}

.mif-cast-connected:before {
  content: "\e308";
}

.mif-create-new-folder:before {
  content: "\e2cc";
}

.mif-dashboard:before {
  content: "\e871";
}

.mif-description:before {
  content: "\e873";
}

.mif-equalizer2:before {
  content: "\e01d";
}

.mif-ev-station:before {
  content: "\e56d";
}

.mif-featured-play-list:before {
  content: "\e06d";
}

.mif-featured-video:before {
  content: "\e06e";
}

.mif-fingerprint:before {
  content: "\e912";
}

.mif-folder2:before {
  content: "\e2c7";
}

.mif-folder-open2:before {
  content: "\e2c8";
}

.mif-folder-shared:before {
  content: "\e2c9";
}

.mif-folder-special2:before {
  content: "\e6c4";
}

.mif-goat:before {
  content: "\e901";
}

.mif-import-contacts:before {
  content: "\e0e0";
}

.mif-insert-drive-file:before {
  content: "\e24d";
}

.mif-keyboard-hide:before {
  content: "\e31a";
}

.mif-note:before {
  content: "\e06f";
}

.mif-note-add:before {
  content: "\e89c";
}

.mif-pan-tool:before {
  content: "\e939";
}

.mif-directions-bike:before {
  content: "\e6bf";
}

.mif-location-city:before {
  content: "\e6c0";
}

.mif-wifi-low:before {
  content: "\e60c";
}

.mif-wifi-mid:before {
  content: "\e60d";
}

.mif-wifi-full:before {
  content: "\e634";
}

.mif-tablet-landscape:before {
  content: "\e635";
}

.mif-calculator2:before {
  content: "\e636";
}

.mif-barbell:before {
  content: "\e637";
}

.mif-chart-line:before {
  content: "\e656";
}

.mif-3d-rotation:before {
  content: "\e600";
}

.mif-alarm:before {
  content: "\e601";
}

.mif-alarm-on:before {
  content: "\e602";
}

.mif-favorite:before {
  content: "\e603";
}

.mif-perm-phone-msg:before {
  content: "\e604";
}

.mif-print:before {
  content: "\e605";
}

.mif-bt-settings:before {
  content: "\e606";
}

.mif-settings-ethernet:before {
  content: "\e607";
}

.mif-settings-phone:before {
  content: "\e608";
}

.mif-settings-power:before {
  content: "\e609";
}

.mif-settings-voice:before {
  content: "\e60a";
}

.mif-shopping-basket:before {
  content: "\e60b";
}

.mif-dialer-sip:before {
  content: "\e60e";
}

.mif-dialpad:before {
  content: "\e60f";
}

.mif-contacts-dialer:before {
  content: "\e610";
}

.mif-contacts-mail:before {
  content: "\e611";
}

.mif-ring-volume:before {
  content: "\e612";
}

.mif-voicemail:before {
  content: "\e613";
}

.mif-drafts:before {
  content: "\e614";
}

.mif-mail:before {
  content: "\e615";
}

.mif-bluetooth:before {
  content: "\e626";
}

.mif-bt-connected:before {
  content: "\e627";
}

.mif-bt-disabled:before {
  content: "\e628";
}

.mif-bt-searching:before {
  content: "\e629";
}

.mif-brightness-auto:before {
  content: "\e62a";
}

.mif-multitrack-audio:before {
  content: "\e616";
}

.mif-widgets:before {
  content: "\e617";
}

.mif-usb:before {
  content: "\e638";
}

.mif-money:before {
  content: "\e639";
}

.mif-vertical-align-bottom:before {
  content: "\e63a";
}

.mif-vertical-align-center:before {
  content: "\e63b";
}

.mif-vertical-align-top:before {
  content: "\e63c";
}

.mif-file-download:before {
  content: "\e63d";
}

.mif-file-upload:before {
  content: "\e63e";
}

.mif-keyboard-return:before {
  content: "\e63f";
}

.mif-keyboard-voice:before {
  content: "\e640";
}

.mif-phonelink:before {
  content: "\e641";
}

.mif-phonelink-off:before {
  content: "\e642";
}

.mif-security:before {
  content: "\e618";
}

.mif-looks:before {
  content: "\e643";
}

.mif-palette:before {
  content: "\e619";
}

.mif-layers:before {
  content: "\e644";
}

.mif-layers-clear:before {
  content: "\e61a";
}

.mif-local-airport:before {
  content: "\e645";
}

.mif-florist:before {
  content: "\e61b";
}

.mif-gas-station:before {
  content: "\e61c";
}

.mif-hotel:before {
  content: "\e646";
}

.mif-local-service:before {
  content: "\e61d";
}

.mif-map2:before {
  content: "\e620";
}

.mif-my-location:before {
  content: "\e61e";
}

.mif-traff:before {
  content: "\e621";
}

.mif-apps:before {
  content: "\e647";
}

.mif-chevron-left:before {
  content: "\e648";
}

.mif-chevron-right:before {
  content: "\e649";
}

.mif-expand-less:before {
  content: "\e64a";
}

.mif-expand-more:before {
  content: "\e64b";
}

.mif-menu:before {
  content: "\e64c";
}

.mif-more-horiz:before {
  content: "\e64d";
}

.mif-more-vert:before {
  content: "\e64e";
}

.mif-unfold-less:before {
  content: "\e64f";
}

.mif-unfold-more:before {
  content: "\e650";
}

.mif-bt-audio:before {
  content: "\e651";
}

.mif-not:before {
  content: "\e633";
}

.mif-drive-eta:before {
  content: "\e652";
}

.mif-event-available:before {
  content: "\e653";
}

.mif-event-busy:before {
  content: "\e654";
}

.mif-folder-special:before {
  content: "\e655";
}

.mif-phone-bt:before {
  content: "\e657";
}

.mif-phone-forwarded:before {
  content: "\e658";
}

.mif-phone-in-talk:before {
  content: "\e659";
}

.mif-phone-locked:before {
  content: "\e65a";
}

.mif-phone-missed:before {
  content: "\e65b";
}

.mif-phone-paused:before {
  content: "\e65c";
}

.mif-sd-card:before {
  content: "\e65d";
}

.mif-sync-disabled:before {
  content: "\e622";
}

.mif-sync-problem:before {
  content: "\e623";
}

.mif-vpn-lock:before {
  content: "\e624";
}

.mif-vpn-publ:before {
  content: "\e625";
}

.mif-school:before {
  content: "\e61f";
}

.mif-install:before {
  content: "\e902";
}

.mif-open-book:before {
  content: "\e903";
}

.mif-uninstall:before {
  content: "\e904";
}

.mif-drive2:before {
  content: "\e906";
}

.mif-news:before {
  content: "\e907";
}

.mif-book-reference:before {
  content: "\e908";
}

.mif-chevron-thin-down:before {
  content: "\e66b";
}

.mif-chevron-thin-left:before {
  content: "\e697";
}

.mif-chevron-thin-right:before {
  content: "\e69b";
}

.mif-chevron-thin-up:before {
  content: "\e69c";
}

.mif-flow-branch:before {
  content: "\e69d";
}

.mif-flow-cascade:before {
  content: "\e69e";
}

.mif-flow-line:before {
  content: "\e69f";
}

.mif-flow-parallel:before {
  content: "\e6a0";
}

.mif-flow-tree:before {
  content: "\e6a1";
}

.mif-air:before {
  content: "\e6a2";
}

.mif-medal:before {
  content: "\e6a3";
}

.mif-paper-plane:before {
  content: "\e6a4";
}

.mif-shareable:before {
  content: "\e6a5";
}

.mif-shop:before {
  content: "\e6a6";
}

.mif-shopping-basket2:before {
  content: "\e6a7";
}

.mif-thumbs-down:before {
  content: "\e6a8";
}

.mif-thumbs-up:before {
  content: "\e6a9";
}

.mif-traffic-cone:before {
  content: "\e6aa";
}

.mif-water:before {
  content: "\e6ab";
}

.mif-creative-cloud:before {
  content: "\e6ac";
}

.mif-dropbox:before {
  content: "\e6ad";
}

.mif-evernote:before {
  content: "\e6ae";
}

.mif-paypal:before {
  content: "\e6af";
}

.mif-swarm:before {
  content: "\e6b0";
}

.mif-vk:before {
  content: "\e6b1";
}

.mif-yelp:before {
  content: "\e6b2";
}

.mif-dribbble:before {
  content: "\e6b3";
}

.mif-lastfm:before {
  content: "\e6b4";
}

.mif-pinterest:before {
  content: "\e6b5";
}

.mif-stumbleupon:before {
  content: "\e6b6";
}

.mif-vimeo:before {
  content: "\e6b7";
}

.mif-facebook:before {
  content: "\f09a";
}

.mif-google-plus:before {
  content: "\f0d5";
}

.mif-steam:before {
  content: "\f1b6";
}

.mif-leanpub:before {
  content: "\f212";
}

.mif-user-secret:before {
  content: "\f21b";
}

.mif-server:before {
  content: "\f233";
}

.mif-balance-scale:before {
  content: "\f24e";
}

.mif-odnoklassniki:before {
  content: "\f263";
}

.mif-gitlab:before {
  content: "\f296";
}

.mif-themeisle:before {
  content: "\f2b2";
}

.mif-handshake:before {
  content: "\f2b5";
}

.mif-grav:before {
  content: "\f2d6";
}

.mif-imdb:before {
  content: "\f2d8";
}

.mif-meetup:before {
  content: "\f2e0";
}

.mif-unlink:before {
  content: "\f127";
}

.mif-fire-extinguisher:before {
  content: "\f134";
}

.mif-eur:before {
  content: "\f153";
}

.mif-gbp:before {
  content: "\f154";
}

.mif-dollar2:before {
  content: "\f155";
}

.mif-inr:before {
  content: "\f156";
}

.mif-cny:before {
  content: "\f157";
}

.mif-rouble:before {
  content: "\f158";
}

.mif-krw:before {
  content: "\f159";
}

.mif-bitcoin:before {
  content: "\f15a";
}

.mif-youtube2:before {
  content: "\f167";
}

.mif-youtube-play:before {
  content: "\f16a";
}

.mif-linux:before {
  content: "\f17c";
}

.mif-try:before {
  content: "\f195";
}

.mif-space-shuttle:before {
  content: "\f197";
}

.mif-openid:before {
  content: "\f19b";
}

.mif-digg:before {
  content: "\f1a6";
}

.mif-language:before {
  content: "\f1ab";
}

.mif-automobile:before {
  content: "\f1b9";
}

.mif-cab:before {
  content: "\f1ba";
}

.mif-jsfiddle:before {
  content: "\f1cc";
}

.mif-google-wallet:before {
  content: "\f1ee";
}

.mif-copyright:before {
  content: "\f1f9";
}

.mif-bicycle:before {
  content: "\f206";
}

.mif-bus:before {
  content: "\f207";
}

.mif-ship:before {
  content: "\f21a";
}

.mif-motorcycle:before {
  content: "\f21c";
}

.mif-train:before {
  content: "\f238";
}

.mif-subway:before {
  content: "\f239";
}

.mif-opencart:before {
  content: "\f23d";
}

.mif-trademark:before {
  content: "\f25c";
}

.mif-registered:before {
  content: "\f25d";
}

.mif-creative-commons:before {
  content: "\f25e";
}

.mif-wikipedia:before {
  content: "\f266";
}

.mif-amazon:before {
  content: "\f270";
}

.mif-fonticons:before {
  content: "\f280";
}

.mif-user-md:before {
  content: "\f0f0";
}

.mif-stethoscope:before {
  content: "\f0f1";
}

.mif-ambulance:before {
  content: "\f0f9";
}

.mif-medkit:before {
  content: "\f0fa";
}

.mif-paw:before {
  content: "\f1b0";
}

.mif-file-pdf:before {
  content: "\f1c1";
}

.mif-file-word:before {
  content: "\f1c2";
}

.mif-file-excel:before {
  content: "\f1c3";
}

.mif-file-powerpoint:before {
  content: "\f1c4";
}

.mif-file-image:before {
  content: "\f1c5";
}

.mif-file-archive:before {
  content: "\f1c6";
}

.mif-file-audio:before {
  content: "\f1c7";
}

.mif-file-movie:before {
  content: "\f1c8";
}

.mif-file-code:before {
  content: "\f1c9";
}

.mif-visa:before {
  content: "\f1f0";
}

.mif-mastercard:before {
  content: "\f1f1";
}

.mif-discover:before {
  content: "\f1f2";
}

.mif-amex:before {
  content: "\f1f3";
}

.mif-cc-paypal:before {
  content: "\f1f4";
}

.mif-heartbeat:before {
  content: "\f21e";
}

.mif-venus:before {
  content: "\f221";
}

.mif-mars:before {
  content: "\f222";
}

.mif-medium:before {
  content: "\f23a";
}

.mif-stack2:before {
  content: "\e6b9";
}

.mif-stack3:before {
  content: "\e6ba";
}

.mif-lamp:before {
  content: "\e6bb";
}

.mif-injection:before {
  content: "\e6bc";
}

.mif-thermometer2:before {
  content: "\e6bd";
}

.mif-justice:before {
  content: "\e6be";
}

.mif-cabinet:before {
  content: "\e62b";
}

.mif-suitcase:before {
  content: "\e62c";
}

.mif-gamepad:before {
  content: "\e65e";
}

.mif-satellite:before {
  content: "\e65f";
}

.mif-lock:before {
  content: "\e660";
}

.mif-unlock:before {
  content: "\e661";
}

.mif-battery-full:before {
  content: "\e62d";
}

.mif-battery-two:before {
  content: "\e62e";
}

.mif-battery-one:before {
  content: "\e62f";
}

.mif-battery-empty:before {
  content: "\e630";
}

.mif-battery-charge:before {
  content: "\e631";
}

.mif-tools:before {
  content: "\e632";
}

.mif-pin:before {
  content: "\e662";
}

.mif-discout:before {
  content: "\e663";
}

.mif-profile:before {
  content: "\e664";
}

.mif-dollar:before {
  content: "\e665";
}

.mif-dollars:before {
  content: "\e666";
}

.mif-coins:before {
  content: "\e6b8";
}

.mif-male:before {
  content: "\e667";
}

.mif-female:before {
  content: "\e668";
}

.mif-piano:before {
  content: "\e669";
}

.mif-anchor:before {
  content: "\e66a";
}

.mif-copy:before {
  content: "\e92c";
}

.mif-paste:before {
  content: "\e92d";
}

.mif-cut:before {
  content: "\ea5a";
}

.mif-earth2:before {
  content: "\e6c1";
}

.mif-shit:before {
  content: "\e6c2";
}

.mif-broadcast:before {
  content: "\f048";
}

.mif-organization:before {
  content: "\f037";
}

.mif-squirrel:before {
  content: "\f0b2";
}

.mif-steps:before {
  content: "\f0c7";
}

.mif-versions:before {
  content: "\f064";
}

.mif-microscope:before {
  content: "\f089";
}

.mif-library:before {
  content: "\e921";
}

.mif-file-binary:before {
  content: "\f094";
}

.mif-mail-read:before {
  content: "\f03c";
}

.mif-quote:before {
  content: "\f063";
}

.mif-sunrise:before {
  content: "\e66c";
}

.mif-sun:before {
  content: "\e66d";
}

.mif-moon:before {
  content: "\e66e";
}

.mif-sun3:before {
  content: "\e66f";
}

.mif-windy:before {
  content: "\e670";
}

.mif-wind:before {
  content: "\e671";
}

.mif-snowflake:before {
  content: "\e672";
}

.mif-cloudy:before {
  content: "\e673";
}

.mif-cloud2:before {
  content: "\e674";
}

.mif-weather:before {
  content: "\e675";
}

.mif-weather2:before {
  content: "\e676";
}

.mif-weather3:before {
  content: "\e677";
}

.mif-lines:before {
  content: "\e678";
}

.mif-cloud3:before {
  content: "\e679";
}

.mif-lightning:before {
  content: "\e67a";
}

.mif-lightning2:before {
  content: "\e67b";
}

.mif-rainy:before {
  content: "\e67c";
}

.mif-rainy2:before {
  content: "\e67d";
}

.mif-windy2:before {
  content: "\e67e";
}

.mif-windy3:before {
  content: "\e67f";
}

.mif-snowy:before {
  content: "\e680";
}

.mif-snowy2:before {
  content: "\e681";
}

.mif-snowy3:before {
  content: "\e682";
}

.mif-weather4:before {
  content: "\e683";
}

.mif-cloudy2:before {
  content: "\e684";
}

.mif-cloud4:before {
  content: "\e685";
}

.mif-lightning3:before {
  content: "\e686";
}

.mif-sun4:before {
  content: "\e687";
}

.mif-moon2:before {
  content: "\e688";
}

.mif-cloudy3:before {
  content: "\e689";
}

.mif-cloud5:before {
  content: "\e68a";
}

.mif-cloud6:before {
  content: "\e68b";
}

.mif-lightning4:before {
  content: "\e68c";
}

.mif-rainy3:before {
  content: "\e68d";
}

.mif-rainy4:before {
  content: "\e68e";
}

.mif-windy4:before {
  content: "\e68f";
}

.mif-windy5:before {
  content: "\e690";
}

.mif-snowy4:before {
  content: "\e691";
}

.mif-snowy5:before {
  content: "\e692";
}

.mif-weather5:before {
  content: "\e693";
}

.mif-cloudy4:before {
  content: "\e694";
}

.mif-lightning5:before {
  content: "\e695";
}

.mif-thermometer:before {
  content: "\e696";
}

.mif-none:before {
  content: "\e698";
}

.mif-celsius:before {
  content: "\e699";
}

.mif-fahrenheit:before {
  content: "\e69a";
}

.mif-home:before {
  content: "\e900";
}

.mif-pencil:before {
  content: "\e905";
}

.mif-eyedropper:before {
  content: "\e90a";
}

.mif-paint:before {
  content: "\e90c";
}

.mif-image:before {
  content: "\e90d";
}

.mif-images:before {
  content: "\e90e";
}

.mif-camera:before {
  content: "\e90f";
}

.mif-headphones:before {
  content: "\e910";
}

.mif-music:before {
  content: "\e911";
}

.mif-film:before {
  content: "\e913";
}

.mif-video-camera:before {
  content: "\e914";
}

.mif-dice:before {
  content: "\e915";
}

.mif-wifi-connect:before {
  content: "\e91b";
}

.mif-feed:before {
  content: "\e91d";
}

.mif-mic:before {
  content: "\e91e";
}

.mif-books:before {
  content: "\e920";
}

.mif-file-empty:before {
  content: "\e924";
}

.mif-files-empty:before {
  content: "\e925";
}

.mif-file-text:before {
  content: "\e926";
}

.mif-file-picture:before {
  content: "\e927";
}

.mif-file-music:before {
  content: "\e928";
}

.mif-file-play:before {
  content: "\e929";
}

.mif-file-video:before {
  content: "\e92a";
}

.mif-file-zip:before {
  content: "\e92b";
}

.mif-stack:before {
  content: "\e92e";
}

.mif-folder:before {
  content: "\e92f";
}

.mif-folder-open:before {
  content: "\e930";
}

.mif-folder-plus:before {
  content: "\e931";
}

.mif-folder-minus:before {
  content: "\e932";
}

.mif-folder-download:before {
  content: "\e933";
}

.mif-folder-upload:before {
  content: "\e934";
}

.mif-tag:before {
  content: "\e935";
}

.mif-tags:before {
  content: "\e936";
}

.mif-barcode:before {
  content: "\e937";
}

.mif-qrcode:before {
  content: "\e938";
}

.mif-cart:before {
  content: "\e93a";
}

.mif-credit-card:before {
  content: "\e93f";
}

.mif-calculator:before {
  content: "\e940";
}

.mif-help:before {
  content: "\e941";
}

.mif-phone:before {
  content: "\e942";
}

.mif-envelop:before {
  content: "\e945";
}

.mif-location:before {
  content: "\e948";
}

.mif-compass:before {
  content: "\e949";
}

.mif-compass2:before {
  content: "\e94a";
}

.mif-map:before {
  content: "\e94b";
}

.mif-history:before {
  content: "\e94d";
}

.mif-bell:before {
  content: "\e951";
}

.mif-calendar:before {
  content: "\e953";
}

.mif-printer:before {
  content: "\e954";
}

.mif-keyboard:before {
  content: "\e955";
}

.mif-display:before {
  content: "\e956";
}

.mif-laptop:before {
  content: "\e957";
}

.mif-mobile:before {
  content: "\e959";
}

.mif-tablet:before {
  content: "\e95a";
}

.mif-download:before {
  content: "\e960";
}

.mif-upload:before {
  content: "\e961";
}

.mif-floppy-disk:before {
  content: "\e962";
}

.mif-drive:before {
  content: "\e963";
}

.mif-database:before {
  content: "\e964";
}

.mif-undo:before {
  content: "\e965";
}

.mif-redo:before {
  content: "\e966";
}

.mif-bubble:before {
  content: "\e96b";
}

.mif-bubbles:before {
  content: "\e96c";
}

.mif-user:before {
  content: "\e971";
}

.mif-users:before {
  content: "\e972";
}

.mif-user-plus:before {
  content: "\e973";
}

.mif-user-minus:before {
  content: "\e974";
}

.mif-user-check:before {
  content: "\e975";
}

.mif-hour-glass:before {
  content: "\e979";
}

.mif-spinner:before {
  content: "\e97a";
}

.mif-spinner1:before {
  content: "\e97b";
}

.mif-spinner2:before {
  content: "\e97d";
}

.mif-spinner3:before {
  content: "\e981";
}

.mif-spinner4:before {
  content: "\e982";
}

.mif-spinner5:before {
  content: "\e983";
}

.mif-search:before {
  content: "\e986";
}

.mif-zoom-in:before {
  content: "\e987";
}

.mif-zoom-out:before {
  content: "\e988";
}

.mif-enlarge:before {
  content: "\e989";
}

.mif-shrink:before {
  content: "\e98a";
}

.mif-enlarge2:before {
  content: "\e98b";
}

.mif-shrink2:before {
  content: "\e98c";
}

.mif-key:before {
  content: "\e98d";
}

.mif-wrench:before {
  content: "\e991";
}

.mif-equalizer:before {
  content: "\e992";
}

.mif-equalizer-v:before {
  content: "\e993";
}

.mif-cog:before {
  content: "\e994";
}

.mif-cogs:before {
  content: "\e995";
}

.mif-magic-wand:before {
  content: "\e997";
}

.mif-bug:before {
  content: "\e999";
}

.mif-chart-pie:before {
  content: "\e99a";
}

.mif-chart-dots:before {
  content: "\e99b";
}

.mif-chart-bars:before {
  content: "\e99c";
}

.mif-chart-bars2:before {
  content: "\e99d";
}

.mif-trophy:before {
  content: "\e99e";
}

.mif-gift:before {
  content: "\e99f";
}

.mif-spoon-fork:before {
  content: "\e9a3";
}

.mif-rocket:before {
  content: "\e9a5";
}

.mif-meter:before {
  content: "\e9a6";
}

.mif-hammer:before {
  content: "\e9a8";
}

.mif-fire:before {
  content: "\e9a9";
}

.mif-lab:before {
  content: "\e9aa";
}

.mif-bin:before {
  content: "\e9ac";
}

.mif-truck:before {
  content: "\e9b0";
}

.mif-target:before {
  content: "\e9b3";
}

.mif-power:before {
  content: "\e9b5";
}

.mif-switch:before {
  content: "\e9b6";
}

.mif-power-cord:before {
  content: "\e9b7";
}

.mif-clipboard:before {
  content: "\e9b8";
}

.mif-list-numbered:before {
  content: "\e9b9";
}

.mif-list:before {
  content: "\e9ba";
}

.mif-list2:before {
  content: "\e9bb";
}

.mif-tree:before {
  content: "\e9bc";
}

.mif-cloud:before {
  content: "\e9c1";
}

.mif-cloud-download:before {
  content: "\e9c2";
}

.mif-cloud-upload:before {
  content: "\e9c3";
}

.mif-download2:before {
  content: "\e9c7";
}

.mif-upload2:before {
  content: "\e9c8";
}

.mif-earth:before {
  content: "\e9ca";
}

.mif-link:before {
  content: "\e9cb";
}

.mif-flag:before {
  content: "\e9cc";
}

.mif-attachment:before {
  content: "\e9cd";
}

.mif-eye:before {
  content: "\e9ce";
}

.mif-bookmark:before {
  content: "\e9d2";
}

.mif-bookmarks:before {
  content: "\e9d3";
}

.mif-contrast:before {
  content: "\e9d5";
}

.mif-brightness:before {
  content: "\e9d6";
}

.mif-star-empty:before {
  content: "\e9d7";
}

.mif-star-half:before {
  content: "\e9d8";
}

.mif-star-full:before {
  content: "\e9d9";
}

.mif-heart:before {
  content: "\e9da";
}

.mif-heart-broken:before {
  content: "\e9db";
}

.mif-warning:before {
  content: "\ea07";
}

.mif-notification:before {
  content: "\ea08";
}

.mif-question:before {
  content: "\ea09";
}

.mif-plus:before {
  content: "\ea0a";
}

.mif-minus:before {
  content: "\ea0b";
}

.mif-info:before {
  content: "\ea0c";
}

.mif-cancel:before {
  content: "\ea0d";
}

.mif-blocked:before {
  content: "\ea0e";
}

.mif-cross:before {
  content: "\ea0f";
}

.mif-checkmark:before {
  content: "\ea10";
}

.mif-spell-check:before {
  content: "\ea12";
}

.mif-enter:before {
  content: "\ea13";
}

.mif-exit:before {
  content: "\ea14";
}

.mif-play:before {
  content: "\ea1c";
}

.mif-pause:before {
  content: "\ea1d";
}

.mif-stop:before {
  content: "\ea1e";
}

.mif-backward:before {
  content: "\ea1f";
}

.mif-forward:before {
  content: "\ea20";
}

.mif-first:before {
  content: "\ea21";
}

.mif-last:before {
  content: "\ea22";
}

.mif-previous:before {
  content: "\ea23";
}

.mif-next:before {
  content: "\ea24";
}

.mif-eject:before {
  content: "\ea25";
}

.mif-volume-high:before {
  content: "\ea26";
}

.mif-volume-medium:before {
  content: "\ea27";
}

.mif-volume-low:before {
  content: "\ea28";
}

.mif-volume-mute:before {
  content: "\ea29";
}

.mif-volume-mute2:before {
  content: "\ea2a";
}

.mif-volume-plus:before {
  content: "\ea2b";
}

.mif-volume-minus:before {
  content: "\ea2c";
}

.mif-loop:before {
  content: "\ea2d";
}

.mif-loop2:before {
  content: "\ea2e";
}

.mif-infinite:before {
  content: "\ea2f";
}

.mif-shuffle:before {
  content: "\ea30";
}

.mif-arrow-up-left:before {
  content: "\ea39";
}

.mif-arrow-up:before {
  content: "\ea3a";
}

.mif-arrow-up-right:before {
  content: "\ea3b";
}

.mif-arrow-right:before {
  content: "\ea3c";
}

.mif-arrow-down-right:before {
  content: "\ea3d";
}

.mif-arrow-down:before {
  content: "\ea3e";
}

.mif-arrow-down-left:before {
  content: "\ea3f";
}

.mif-arrow-left:before {
  content: "\ea40";
}

.mif-tab:before {
  content: "\ea45";
}

.mif-move-up:before {
  content: "\ea46";
}

.mif-move-down:before {
  content: "\ea47";
}

.mif-sort-asc:before {
  content: "\ea4c";
}

.mif-sort-desc:before {
  content: "\ea4d";
}

.mif-command:before {
  content: "\ea4e";
}

.mif-shift:before {
  content: "\ea4f";
}

.mif-crop:before {
  content: "\ea57";
}

.mif-filter:before {
  content: "\ea5b";
}

.mif-bold:before {
  content: "\ea62";
}

.mif-underline:before {
  content: "\ea63";
}

.mif-italic:before {
  content: "\ea64";
}

.mif-strikethrough:before {
  content: "\ea65";
}

.mif-page-break:before {
  content: "\ea68";
}

.mif-superscript:before {
  content: "\ea69";
}

.mif-subscript:before {
  content: "\ea6a";
}

.mif-table:before {
  content: "\ea71";
}

.mif-insert-template:before {
  content: "\ea72";
}

.mif-pilcrow:before {
  content: "\ea73";
}

.mif-ltr:before {
  content: "\ea74";
}

.mif-rtl:before {
  content: "\ea75";
}

.mif-section:before {
  content: "\ea76";
}

.mif-paragraph-left:before {
  content: "\ea77";
}

.mif-paragraph-center:before {
  content: "\ea78";
}

.mif-paragraph-right:before {
  content: "\ea79";
}

.mif-paragraph-justify:before {
  content: "\ea7a";
}

.mif-indent-increase:before {
  content: "\ea7b";
}

.mif-indent-decrease:before {
  content: "\ea7c";
}

.mif-embed:before {
  content: "\ea7f";
}

.mif-embed2:before {
  content: "\ea80";
}

.mif-share:before {
  content: "\ea82";
}

.mif-google:before {
  content: "\ea87";
}

.mif-google-plus2:before {
  content: "\ea88";
}

.mif-facebook2:before {
  content: "\ea8d";
}

.mif-twitter:before {
  content: "\ea91";
}

.mif-feed3:before {
  content: "\ea95";
}

.mif-youtube:before {
  content: "\ea99";
}

.mif-steam2:before {
  content: "\eaae";
}

.mif-onedrive:before {
  content: "\eab0";
}

.mif-github:before {
  content: "\eab3";
}

.mif-git:before {
  content: "\eab5";
}

.mif-apple:before {
  content: "\eabf";
}

.mif-android:before {
  content: "\eac1";
}

.mif-windows:before {
  content: "\eac3";
}

.mif-skype:before {
  content: "\eac6";
}

.mif-linkedin:before {
  content: "\eac8";
}

.mif-html5:before {
  content: "\eadf";
}

.mif-css3:before {
  content: "\eae1";
}

.mif-chrome:before {
  content: "\eae5";
}

.mif-firefox:before {
  content: "\eae6";
}

.mif-ie:before {
  content: "\eae7";
}

.mif-opera:before {
  content: "\eae8";
}

.mif-safari:before {
  content: "\eae9";
}

.mif-airplane:before {
  content: "\e6c3";
}

.mif-instagram:before {
  content: "\e6c5";
}

.mif-twitch:before {
  content: "\e6c6";
}

.mif-picassa:before {
  content: "\e6c7";
}

.mif-deviantart:before {
  content: "\e6c8";
}

.mif-wordpress:before {
  content: "\e6c9";
}

.mif-joomla:before {
  content: "\e6ca";
}

.mif-blogger:before {
  content: "\e6cb";
}

.mif-tux:before {
  content: "\e6cc";
}

.mif-finder:before {
  content: "\e6cd";
}

.mif-soundcloud:before {
  content: "\e6ce";
}

.mif-reddit:before {
  content: "\e6cf";
}

.mif-delicious:before {
  content: "\e6d0";
}

.mif-stackoverflow:before {
  content: "\e6d1";
}

.mif-flattr:before {
  content: "\e6d2";
}

.mif-foursquare:before {
  content: "\e6d3";
}

.mif-file-openoffice:before {
  content: "\e6d4";
}

.mif-libreoffice:before {
  content: "\e6d5";
}

.mif-codepen:before {
  content: "\e6d6";
}

.mif-IcoMoon:before {
  content: "\e6d7";
}

.selected1 {
  -webkit-box-shadow: 0 0 0 4px #5ebdec !important;
  box-shadow: 0 0 0 4px #5ebdec !important;
}

.selected1::after {
  position: absolute;
  display: block;
  border-top: 28px solid #5ebdec;
  border-left: 28px solid transparent;
  right: -1px;
  content: "";
  top: -1px;
  z-index: 100;
}

.selected1::before {
  position: absolute;
  display: block;
  content: "";
  background-color: transparent;
  border-color: #fff !important;
  border-left: 2px solid;
  border-bottom: 2px solid;
  height: 0.325rem;
  width: 0.5rem;
  right: 0.25rem;
  top: 0.25rem;
  z-index: 102;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
