.content-styles {
    color: white !important;
    max-width: 100%;
}

.content-styles h3 {
    font-size: 50px;
    text-align: center; 
}

.content-styles p {
    text-align: center;
    vertical-align: middle;
    font-size: 15px;
}

.custom-gradient-1 {
    max-width: 100%;
    height: 200px;
    background: linear-gradient(to right, #7c69b8 0, #2d177b 100%);
}

.custom-gradient-2 {
    max-width: 100%;
    height: 200px;
    background: linear-gradient(160deg, rgb(41, 150, 212) -10%, rgb(75, 55, 137) -20%, rgba(229, 195, 222, 0) 50%), linear-gradient(73deg, rgb(153, 82, 154) 3%, rgb(140, 181, 224) 56%, rgb(11, 187, 255) 90%);;
}

.custom-gradient-3 {
    max-width: 100%;
    height: 200px;
    background: linear-gradient(200deg, rgb(0, 106, 114) 0px, rgba(229, 195, 222, 0) 54%), linear-gradient(285deg, rgb(38, 192, 235) 15%, rgb(22, 175, 233) 40%, rgb(6, 181, 184) 53%, rgb(164, 201, 95) 90%);
}

.carousel-caption{
    right:10% !important;
    left:10% !important;
}
