.feedback-button {
  z-index: 15;
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform-origin: bottom right;
  position: fixed;
  right: 0px;
  top: 300px;
}