.selected {
    -webkit-box-shadow: 0 0 0 !important;
    box-shadow: 0 0 0  !important;
} 

.selected::after {
    position: absolute;
    display: block;
    border-top: 0x solid #5ebdec;
    border-left: 0px solid transparent;
    right: -1px;
    content: "";
    top: -1px;
    z-index: 0;
  }
  
  .selected::before {
    position: absolute;
    display: block;
    content: "";
    background-color: transparent;
    border-color: #fff !important;
    border-left: 2px solid;
    border-bottom: 2px solid;
    height: 0.325rem;
    width: 0.5rem;
    right: 0.25rem;
    top: 0.25rem;
    z-index: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
   
  .cjvConflictChild
  {
    display: flex;
    flex-flow: column wrap;
    overflow-wrap: break-word;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    align-content: center;
    justify-content: center;
  
  }
  .parentDefaulutNode{
    display: inline-flex;
    flex-flow: column;
    align-items: center;
    margin-top: auto;
   justify-content: center; 
     height:100%;
    width :100%;  
    color: white;
  }
  .parentDefaulutNode1{
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: auto;
   justify-content: center; 
    color: white;
  }
  .checkboxAlign{
    display: flexbox;
    flex-flow: column;
    align-content: center;
    justify-content: center;
  }